
// tslint:disable
  

export class SpineData$CREDIT_BUREAU {

     public static get CIBIL() { return new SpineData$CREDIT_BUREAU('CIBIL', 'CIBIL'); } 
public static get EXPERIAN() { return new SpineData$CREDIT_BUREAU('EXPERIAN', 'EXPERIAN'); } 
public static get EQUIFAX() { return new SpineData$CREDIT_BUREAU('EQUIFAX', 'EQUIFAX'); } 

     public static get DEFAULT_VALUE() { return SpineData$CREDIT_BUREAU.CIBIL; }

     public static withIdOption(id: string): SpineData$CREDIT_BUREAU| undefined  {
        try {
            return SpineData$CREDIT_BUREAU.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$CREDIT_BUREAU.withIdOption(id) !== undefined; }
    public static list(): SpineData$CREDIT_BUREAU[]  { return [SpineData$CREDIT_BUREAU.CIBIL, SpineData$CREDIT_BUREAU.EXPERIAN, SpineData$CREDIT_BUREAU.EQUIFAX]; }
    public static idList(): string[]  { return ['CIBIL', 'EXPERIAN', 'EQUIFAX']; }
    public static nameList(): string[]  { return ['CIBIL', 'EXPERIAN', 'EQUIFAX']; }


    public static withId(id: string): SpineData$CREDIT_BUREAU  {
        switch (id) {
            case 'CIBIL': return SpineData$CREDIT_BUREAU.CIBIL; 
case 'EXPERIAN': return SpineData$CREDIT_BUREAU.EXPERIAN; 
case 'EQUIFAX': return SpineData$CREDIT_BUREAU.EQUIFAX; 
            default: throw new Error('Invalid id for SpineData$CREDIT_BUREAU: ' + id);
        }
    }

    public static withName(name: string): SpineData$CREDIT_BUREAU  {
        switch (name) {
            case 'CIBIL': return SpineData$CREDIT_BUREAU.CIBIL; 
case 'EXPERIAN': return SpineData$CREDIT_BUREAU.EXPERIAN; 
case 'EQUIFAX': return SpineData$CREDIT_BUREAU.EQUIFAX; 
            default: throw new Error('Invalid id for SpineData$CREDIT_BUREAU: ' + name);
        }
    }

    public static copyMe(given: SpineData$CREDIT_BUREAU): SpineData$CREDIT_BUREAU  {
            return SpineData$CREDIT_BUREAU.withId(given.id)
    }

    public static fromJson(json: string): SpineData$CREDIT_BUREAU { return SpineData$CREDIT_BUREAU.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$CREDIT_BUREAU.list().indexOf(this); }
}
 

export class SpineData$FICREDITOR_SETTLEMENT_STATUS {

     public static get NOT_SETTLED() { return new SpineData$FICREDITOR_SETTLEMENT_STATUS('NOT_SETTLED', 'NOT_SETTLED'); } 
public static get SETTLEMENT_PLANNED() { return new SpineData$FICREDITOR_SETTLEMENT_STATUS('SETTLEMENT_PLANNED', 'SETTLEMENT_PLANNED'); } 
public static get PARTIALLY_SETTLED() { return new SpineData$FICREDITOR_SETTLEMENT_STATUS('PARTIALLY_SETTLED', 'PARTIALLY_SETTLED'); } 
public static get SETTLED() { return new SpineData$FICREDITOR_SETTLEMENT_STATUS('SETTLED', 'SETTLED'); } 

     public static get DEFAULT_VALUE() { return SpineData$FICREDITOR_SETTLEMENT_STATUS.NOT_SETTLED; }

     public static withIdOption(id: string): SpineData$FICREDITOR_SETTLEMENT_STATUS| undefined  {
        try {
            return SpineData$FICREDITOR_SETTLEMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$FICREDITOR_SETTLEMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$FICREDITOR_SETTLEMENT_STATUS[]  { return [SpineData$FICREDITOR_SETTLEMENT_STATUS.NOT_SETTLED, SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLEMENT_PLANNED, SpineData$FICREDITOR_SETTLEMENT_STATUS.PARTIALLY_SETTLED, SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLED]; }
    public static idList(): string[]  { return ['NOT_SETTLED', 'SETTLEMENT_PLANNED', 'PARTIALLY_SETTLED', 'SETTLED']; }
    public static nameList(): string[]  { return ['NOT_SETTLED', 'SETTLEMENT_PLANNED', 'PARTIALLY_SETTLED', 'SETTLED']; }


    public static withId(id: string): SpineData$FICREDITOR_SETTLEMENT_STATUS  {
        switch (id) {
            case 'NOT_SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.NOT_SETTLED; 
case 'SETTLEMENT_PLANNED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLEMENT_PLANNED; 
case 'PARTIALLY_SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.PARTIALLY_SETTLED; 
case 'SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLED; 
            default: throw new Error('Invalid id for SpineData$FICREDITOR_SETTLEMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$FICREDITOR_SETTLEMENT_STATUS  {
        switch (name) {
            case 'NOT_SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.NOT_SETTLED; 
case 'SETTLEMENT_PLANNED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLEMENT_PLANNED; 
case 'PARTIALLY_SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.PARTIALLY_SETTLED; 
case 'SETTLED': return SpineData$FICREDITOR_SETTLEMENT_STATUS.SETTLED; 
            default: throw new Error('Invalid id for SpineData$FICREDITOR_SETTLEMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$FICREDITOR_SETTLEMENT_STATUS): SpineData$FICREDITOR_SETTLEMENT_STATUS  {
            return SpineData$FICREDITOR_SETTLEMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$FICREDITOR_SETTLEMENT_STATUS { return SpineData$FICREDITOR_SETTLEMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$FICREDITOR_SETTLEMENT_STATUS.list().indexOf(this); }
}
 

export class SpineData$PAYMENT_MODE {

     public static get NEFT() { return new SpineData$PAYMENT_MODE('NEFT', 'NEFT'); } 
public static get PG() { return new SpineData$PAYMENT_MODE('PG', 'PG'); } 
public static get DEBIT_CARD() { return new SpineData$PAYMENT_MODE('DEBIT_CARD', 'DEBIT_CARD'); } 
public static get CREDIT_CARD() { return new SpineData$PAYMENT_MODE('CREDIT_CARD', 'CREDIT_CARD'); } 
public static get IMPS() { return new SpineData$PAYMENT_MODE('IMPS', 'IMPS'); } 
public static get NET_BANKING() { return new SpineData$PAYMENT_MODE('NET_BANKING', 'NET_BANKING'); } 
public static get ENACH() { return new SpineData$PAYMENT_MODE('ENACH', 'ENACH'); } 
public static get TPA() { return new SpineData$PAYMENT_MODE('TPA', 'TPA'); } 
public static get UPI() { return new SpineData$PAYMENT_MODE('UPI', 'UPI'); } 
public static get RTGS() { return new SpineData$PAYMENT_MODE('RTGS', 'RTGS'); } 

     public static get DEFAULT_VALUE() { return SpineData$PAYMENT_MODE.ENACH; }

     public static withIdOption(id: string): SpineData$PAYMENT_MODE| undefined  {
        try {
            return SpineData$PAYMENT_MODE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PAYMENT_MODE.withIdOption(id) !== undefined; }
    public static list(): SpineData$PAYMENT_MODE[]  { return [SpineData$PAYMENT_MODE.UPI, SpineData$PAYMENT_MODE.ENACH, SpineData$PAYMENT_MODE.TPA, SpineData$PAYMENT_MODE.NEFT, SpineData$PAYMENT_MODE.NET_BANKING, SpineData$PAYMENT_MODE.PG, SpineData$PAYMENT_MODE.RTGS, SpineData$PAYMENT_MODE.IMPS, SpineData$PAYMENT_MODE.CREDIT_CARD, SpineData$PAYMENT_MODE.DEBIT_CARD]; }
    public static idList(): string[]  { return ['RTGS', 'TPA', 'DEBIT_CARD', 'UPI', 'NEFT', 'PG', 'IMPS', 'ENACH', 'NET_BANKING', 'CREDIT_CARD']; }
    public static nameList(): string[]  { return ['RTGS', 'TPA', 'DEBIT_CARD', 'UPI', 'NEFT', 'PG', 'IMPS', 'ENACH', 'NET_BANKING', 'CREDIT_CARD']; }


    public static withId(id: string): SpineData$PAYMENT_MODE  {
        switch (id) {
            case 'NET_BANKING': return SpineData$PAYMENT_MODE.NET_BANKING; 
case 'PG': return SpineData$PAYMENT_MODE.PG; 
case 'TPA': return SpineData$PAYMENT_MODE.TPA; 
case 'NEFT': return SpineData$PAYMENT_MODE.NEFT; 
case 'CREDIT_CARD': return SpineData$PAYMENT_MODE.CREDIT_CARD; 
case 'IMPS': return SpineData$PAYMENT_MODE.IMPS; 
case 'ENACH': return SpineData$PAYMENT_MODE.ENACH; 
case 'RTGS': return SpineData$PAYMENT_MODE.RTGS; 
case 'UPI': return SpineData$PAYMENT_MODE.UPI; 
case 'DEBIT_CARD': return SpineData$PAYMENT_MODE.DEBIT_CARD; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_MODE: ' + id);
        }
    }

    public static withName(name: string): SpineData$PAYMENT_MODE  {
        switch (name) {
            case 'NET_BANKING': return SpineData$PAYMENT_MODE.NET_BANKING; 
case 'PG': return SpineData$PAYMENT_MODE.PG; 
case 'TPA': return SpineData$PAYMENT_MODE.TPA; 
case 'NEFT': return SpineData$PAYMENT_MODE.NEFT; 
case 'CREDIT_CARD': return SpineData$PAYMENT_MODE.CREDIT_CARD; 
case 'IMPS': return SpineData$PAYMENT_MODE.IMPS; 
case 'ENACH': return SpineData$PAYMENT_MODE.ENACH; 
case 'RTGS': return SpineData$PAYMENT_MODE.RTGS; 
case 'UPI': return SpineData$PAYMENT_MODE.UPI; 
case 'DEBIT_CARD': return SpineData$PAYMENT_MODE.DEBIT_CARD; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_MODE: ' + name);
        }
    }

    public static copyMe(given: SpineData$PAYMENT_MODE): SpineData$PAYMENT_MODE  {
            return SpineData$PAYMENT_MODE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PAYMENT_MODE { return SpineData$PAYMENT_MODE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PAYMENT_MODE.list().indexOf(this); }
}
 

export class SpineData$PS_ENTRY_STATUS {

     public static get PRESENTED() { return new SpineData$PS_ENTRY_STATUS('PRESENTED', 'PRESENTED'); } 
public static get SKIPPED() { return new SpineData$PS_ENTRY_STATUS('SKIPPED', 'SKIPPED'); } 
public static get FAILED() { return new SpineData$PS_ENTRY_STATUS('FAILED', 'FAILED'); } 
public static get ARCHIVED() { return new SpineData$PS_ENTRY_STATUS('ARCHIVED', 'ARCHIVED'); } 
public static get PAID() { return new SpineData$PS_ENTRY_STATUS('PAID', 'PAID'); } 
public static get NSF_RESCHEDULED() { return new SpineData$PS_ENTRY_STATUS('NSF_RESCHEDULED', 'NSF_RESCHEDULED'); } 
public static get NSF() { return new SpineData$PS_ENTRY_STATUS('NSF', 'NSF'); } 
public static get SCHEDULED() { return new SpineData$PS_ENTRY_STATUS('SCHEDULED', 'SCHEDULED'); } 

     public static get DEFAULT_VALUE() { return SpineData$PS_ENTRY_STATUS.SCHEDULED; }

     public static withIdOption(id: string): SpineData$PS_ENTRY_STATUS| undefined  {
        try {
            return SpineData$PS_ENTRY_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PS_ENTRY_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$PS_ENTRY_STATUS[]  { return [SpineData$PS_ENTRY_STATUS.NSF_RESCHEDULED, SpineData$PS_ENTRY_STATUS.NSF, SpineData$PS_ENTRY_STATUS.SCHEDULED, SpineData$PS_ENTRY_STATUS.SKIPPED, SpineData$PS_ENTRY_STATUS.ARCHIVED, SpineData$PS_ENTRY_STATUS.PAID, SpineData$PS_ENTRY_STATUS.FAILED, SpineData$PS_ENTRY_STATUS.PRESENTED]; }
    public static idList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }
    public static nameList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }


    public static withId(id: string): SpineData$PS_ENTRY_STATUS  {
        switch (id) {
            case 'PAID': return SpineData$PS_ENTRY_STATUS.PAID; 
case 'SCHEDULED': return SpineData$PS_ENTRY_STATUS.SCHEDULED; 
case 'NSF_RESCHEDULED': return SpineData$PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'FAILED': return SpineData$PS_ENTRY_STATUS.FAILED; 
case 'NSF': return SpineData$PS_ENTRY_STATUS.NSF; 
case 'ARCHIVED': return SpineData$PS_ENTRY_STATUS.ARCHIVED; 
case 'SKIPPED': return SpineData$PS_ENTRY_STATUS.SKIPPED; 
case 'PRESENTED': return SpineData$PS_ENTRY_STATUS.PRESENTED; 
            default: throw new Error('Invalid id for SpineData$PS_ENTRY_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$PS_ENTRY_STATUS  {
        switch (name) {
            case 'PAID': return SpineData$PS_ENTRY_STATUS.PAID; 
case 'SCHEDULED': return SpineData$PS_ENTRY_STATUS.SCHEDULED; 
case 'NSF_RESCHEDULED': return SpineData$PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'FAILED': return SpineData$PS_ENTRY_STATUS.FAILED; 
case 'NSF': return SpineData$PS_ENTRY_STATUS.NSF; 
case 'ARCHIVED': return SpineData$PS_ENTRY_STATUS.ARCHIVED; 
case 'SKIPPED': return SpineData$PS_ENTRY_STATUS.SKIPPED; 
case 'PRESENTED': return SpineData$PS_ENTRY_STATUS.PRESENTED; 
            default: throw new Error('Invalid id for SpineData$PS_ENTRY_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$PS_ENTRY_STATUS): SpineData$PS_ENTRY_STATUS  {
            return SpineData$PS_ENTRY_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PS_ENTRY_STATUS { return SpineData$PS_ENTRY_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PS_ENTRY_STATUS.list().indexOf(this); }
}
 

export class SpineData$PAYMENT_TYPE {

     public static get COLLECTION() { return new SpineData$PAYMENT_TYPE('COLLECTION', 'COLLECTION'); } 
public static get SETTLEMENT() { return new SpineData$PAYMENT_TYPE('SETTLEMENT', 'SETTLEMENT'); } 
public static get REFUND() { return new SpineData$PAYMENT_TYPE('REFUND', 'REFUND'); } 

     public static get DEFAULT_VALUE() { return SpineData$PAYMENT_TYPE.COLLECTION; }

     public static withIdOption(id: string): SpineData$PAYMENT_TYPE| undefined  {
        try {
            return SpineData$PAYMENT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PAYMENT_TYPE.withIdOption(id) !== undefined; }
    public static list(): SpineData$PAYMENT_TYPE[]  { return [SpineData$PAYMENT_TYPE.COLLECTION, SpineData$PAYMENT_TYPE.SETTLEMENT, SpineData$PAYMENT_TYPE.REFUND]; }
    public static idList(): string[]  { return ['COLLECTION', 'SETTLEMENT', 'REFUND']; }
    public static nameList(): string[]  { return ['COLLECTION', 'SETTLEMENT', 'REFUND']; }


    public static withId(id: string): SpineData$PAYMENT_TYPE  {
        switch (id) {
            case 'COLLECTION': return SpineData$PAYMENT_TYPE.COLLECTION; 
case 'SETTLEMENT': return SpineData$PAYMENT_TYPE.SETTLEMENT; 
case 'REFUND': return SpineData$PAYMENT_TYPE.REFUND; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_TYPE: ' + id);
        }
    }

    public static withName(name: string): SpineData$PAYMENT_TYPE  {
        switch (name) {
            case 'COLLECTION': return SpineData$PAYMENT_TYPE.COLLECTION; 
case 'SETTLEMENT': return SpineData$PAYMENT_TYPE.SETTLEMENT; 
case 'REFUND': return SpineData$PAYMENT_TYPE.REFUND; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_TYPE: ' + name);
        }
    }

    public static copyMe(given: SpineData$PAYMENT_TYPE): SpineData$PAYMENT_TYPE  {
            return SpineData$PAYMENT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PAYMENT_TYPE { return SpineData$PAYMENT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PAYMENT_TYPE.list().indexOf(this); }
}
 

export class InsightData$CF_OM_FILTER {

     public static get ONBOARDED() { return new InsightData$CF_OM_FILTER('ONBOARDED', 'ONBOARDED'); } 
public static get MANDATE() { return new InsightData$CF_OM_FILTER('MANDATE', 'MANDATE'); } 

     public static get DEFAULT_VALUE() { return InsightData$CF_OM_FILTER.ONBOARDED; }

     public static withIdOption(id: string): InsightData$CF_OM_FILTER| undefined  {
        try {
            return InsightData$CF_OM_FILTER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return InsightData$CF_OM_FILTER.withIdOption(id) !== undefined; }
    public static list(): InsightData$CF_OM_FILTER[]  { return [InsightData$CF_OM_FILTER.ONBOARDED, InsightData$CF_OM_FILTER.MANDATE]; }
    public static idList(): string[]  { return ['ONBOARDED', 'MANDATE']; }
    public static nameList(): string[]  { return ['ONBOARDED', 'MANDATE']; }


    public static withId(id: string): InsightData$CF_OM_FILTER  {
        switch (id) {
            case 'ONBOARDED': return InsightData$CF_OM_FILTER.ONBOARDED; 
case 'MANDATE': return InsightData$CF_OM_FILTER.MANDATE; 
            default: throw new Error('Invalid id for InsightData$CF_OM_FILTER: ' + id);
        }
    }

    public static withName(name: string): InsightData$CF_OM_FILTER  {
        switch (name) {
            case 'ONBOARDED': return InsightData$CF_OM_FILTER.ONBOARDED; 
case 'MANDATE': return InsightData$CF_OM_FILTER.MANDATE; 
            default: throw new Error('Invalid id for InsightData$CF_OM_FILTER: ' + name);
        }
    }

    public static copyMe(given: InsightData$CF_OM_FILTER): InsightData$CF_OM_FILTER  {
            return InsightData$CF_OM_FILTER.withId(given.id)
    }

    public static fromJson(json: string): InsightData$CF_OM_FILTER { return InsightData$CF_OM_FILTER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return InsightData$CF_OM_FILTER.list().indexOf(this); }
}
 

export class ValeyData$TaskState {

     public static get STALLED() { return new ValeyData$TaskState('STALLED', 'STALLED'); } 
public static get COMPLETED() { return new ValeyData$TaskState('COMPLETED', 'COMPLETED'); } 
public static get EXCEPTION_Q() { return new ValeyData$TaskState('EXCEPTION_Q', 'EXCEPTION_Q'); } 
public static get CANCELLED() { return new ValeyData$TaskState('CANCELLED', 'CANCELLED'); } 
public static get FORCE_COMPLETED() { return new ValeyData$TaskState('FORCE_COMPLETED', 'FORCE_COMPLETED'); } 
public static get RESET() { return new ValeyData$TaskState('RESET', 'RESET'); } 
public static get STARTED() { return new ValeyData$TaskState('STARTED', 'STARTED'); } 
public static get TO_BE_PULLED() { return new ValeyData$TaskState('TO_BE_PULLED', 'TO_BE_PULLED'); } 
public static get RESOURCE_UNAVAILABLE() { return new ValeyData$TaskState('RESOURCE_UNAVAILABLE', 'RESOURCE_UNAVAILABLE'); } 
public static get CREATED() { return new ValeyData$TaskState('CREATED', 'CREATED'); } 
public static get EXIT_Q() { return new ValeyData$TaskState('EXIT_Q', 'EXIT_Q'); } 
public static get PARTIALLY_COMPLETED() { return new ValeyData$TaskState('PARTIALLY_COMPLETED', 'PARTIALLY_COMPLETED'); } 
public static get TO_BE_ALLOCATED() { return new ValeyData$TaskState('TO_BE_ALLOCATED', 'TO_BE_ALLOCATED'); } 
public static get PENDING_SCHEDULED() { return new ValeyData$TaskState('PENDING_SCHEDULED', 'PENDING_SCHEDULED'); } 
public static get ALLOCATED() { return new ValeyData$TaskState('ALLOCATED', 'ALLOCATED'); } 
public static get PENDING_CANCELLED() { return new ValeyData$TaskState('PENDING_CANCELLED', 'PENDING_CANCELLED'); } 

     public static get DEFAULT_VALUE() { return ValeyData$TaskState.STALLED; }

     public static withIdOption(id: string): ValeyData$TaskState| undefined  {
        try {
            return ValeyData$TaskState.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return ValeyData$TaskState.withIdOption(id) !== undefined; }
    public static list(): ValeyData$TaskState[]  { return [ValeyData$TaskState.STARTED, ValeyData$TaskState.TO_BE_ALLOCATED, ValeyData$TaskState.PENDING_SCHEDULED, ValeyData$TaskState.STALLED, ValeyData$TaskState.RESOURCE_UNAVAILABLE, ValeyData$TaskState.CANCELLED, ValeyData$TaskState.FORCE_COMPLETED, ValeyData$TaskState.RESET, ValeyData$TaskState.EXCEPTION_Q, ValeyData$TaskState.ALLOCATED, ValeyData$TaskState.TO_BE_PULLED, ValeyData$TaskState.PARTIALLY_COMPLETED, ValeyData$TaskState.CREATED, ValeyData$TaskState.PENDING_CANCELLED, ValeyData$TaskState.EXIT_Q, ValeyData$TaskState.COMPLETED]; }
    public static idList(): string[]  { return ['RESET', 'CREATED', 'EXCEPTION_Q', 'PENDING_SCHEDULED', 'COMPLETED', 'CANCELLED', 'STARTED', 'PARTIALLY_COMPLETED', 'EXIT_Q', 'STALLED', 'RESOURCE_UNAVAILABLE', 'TO_BE_ALLOCATED', 'ALLOCATED', 'PENDING_CANCELLED', 'FORCE_COMPLETED', 'TO_BE_PULLED']; }
    public static nameList(): string[]  { return ['RESET', 'CREATED', 'EXCEPTION_Q', 'PENDING_SCHEDULED', 'COMPLETED', 'CANCELLED', 'STARTED', 'PARTIALLY_COMPLETED', 'EXIT_Q', 'STALLED', 'RESOURCE_UNAVAILABLE', 'TO_BE_ALLOCATED', 'ALLOCATED', 'PENDING_CANCELLED', 'FORCE_COMPLETED', 'TO_BE_PULLED']; }


    public static withId(id: string): ValeyData$TaskState  {
        switch (id) {
            case 'CREATED': return ValeyData$TaskState.CREATED; 
case 'RESET': return ValeyData$TaskState.RESET; 
case 'TO_BE_PULLED': return ValeyData$TaskState.TO_BE_PULLED; 
case 'FORCE_COMPLETED': return ValeyData$TaskState.FORCE_COMPLETED; 
case 'CANCELLED': return ValeyData$TaskState.CANCELLED; 
case 'EXIT_Q': return ValeyData$TaskState.EXIT_Q; 
case 'RESOURCE_UNAVAILABLE': return ValeyData$TaskState.RESOURCE_UNAVAILABLE; 
case 'EXCEPTION_Q': return ValeyData$TaskState.EXCEPTION_Q; 
case 'PARTIALLY_COMPLETED': return ValeyData$TaskState.PARTIALLY_COMPLETED; 
case 'TO_BE_ALLOCATED': return ValeyData$TaskState.TO_BE_ALLOCATED; 
case 'COMPLETED': return ValeyData$TaskState.COMPLETED; 
case 'PENDING_SCHEDULED': return ValeyData$TaskState.PENDING_SCHEDULED; 
case 'STALLED': return ValeyData$TaskState.STALLED; 
case 'ALLOCATED': return ValeyData$TaskState.ALLOCATED; 
case 'PENDING_CANCELLED': return ValeyData$TaskState.PENDING_CANCELLED; 
case 'STARTED': return ValeyData$TaskState.STARTED; 
            default: throw new Error('Invalid id for ValeyData$TaskState: ' + id);
        }
    }

    public static withName(name: string): ValeyData$TaskState  {
        switch (name) {
            case 'CREATED': return ValeyData$TaskState.CREATED; 
case 'RESET': return ValeyData$TaskState.RESET; 
case 'TO_BE_PULLED': return ValeyData$TaskState.TO_BE_PULLED; 
case 'FORCE_COMPLETED': return ValeyData$TaskState.FORCE_COMPLETED; 
case 'CANCELLED': return ValeyData$TaskState.CANCELLED; 
case 'EXIT_Q': return ValeyData$TaskState.EXIT_Q; 
case 'RESOURCE_UNAVAILABLE': return ValeyData$TaskState.RESOURCE_UNAVAILABLE; 
case 'EXCEPTION_Q': return ValeyData$TaskState.EXCEPTION_Q; 
case 'PARTIALLY_COMPLETED': return ValeyData$TaskState.PARTIALLY_COMPLETED; 
case 'TO_BE_ALLOCATED': return ValeyData$TaskState.TO_BE_ALLOCATED; 
case 'COMPLETED': return ValeyData$TaskState.COMPLETED; 
case 'PENDING_SCHEDULED': return ValeyData$TaskState.PENDING_SCHEDULED; 
case 'STALLED': return ValeyData$TaskState.STALLED; 
case 'ALLOCATED': return ValeyData$TaskState.ALLOCATED; 
case 'PENDING_CANCELLED': return ValeyData$TaskState.PENDING_CANCELLED; 
case 'STARTED': return ValeyData$TaskState.STARTED; 
            default: throw new Error('Invalid id for ValeyData$TaskState: ' + name);
        }
    }

    public static copyMe(given: ValeyData$TaskState): ValeyData$TaskState  {
            return ValeyData$TaskState.withId(given.id)
    }

    public static fromJson(json: string): ValeyData$TaskState { return ValeyData$TaskState.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return ValeyData$TaskState.list().indexOf(this); }
}
 

export class PartnerData$PARTNER_TYPE {

     public static get AGENT() { return new PartnerData$PARTNER_TYPE('AGENT', 'AGENT'); } 
public static get INSTITUTIONAL() { return new PartnerData$PARTNER_TYPE('INSTITUTIONAL', 'INSTITUTIONAL'); } 

     public static get DEFAULT_VALUE() { return PartnerData$PARTNER_TYPE.AGENT; }

     public static withIdOption(id: string): PartnerData$PARTNER_TYPE| undefined  {
        try {
            return PartnerData$PARTNER_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$PARTNER_TYPE.withIdOption(id) !== undefined; }
    public static list(): PartnerData$PARTNER_TYPE[]  { return [PartnerData$PARTNER_TYPE.AGENT, PartnerData$PARTNER_TYPE.INSTITUTIONAL]; }
    public static idList(): string[]  { return ['AGENT', 'INSTITUTIONAL']; }
    public static nameList(): string[]  { return ['AGENT', 'INSTITUTIONAL']; }


    public static withId(id: string): PartnerData$PARTNER_TYPE  {
        switch (id) {
            case 'AGENT': return PartnerData$PARTNER_TYPE.AGENT; 
case 'INSTITUTIONAL': return PartnerData$PARTNER_TYPE.INSTITUTIONAL; 
            default: throw new Error('Invalid id for PartnerData$PARTNER_TYPE: ' + id);
        }
    }

    public static withName(name: string): PartnerData$PARTNER_TYPE  {
        switch (name) {
            case 'AGENT': return PartnerData$PARTNER_TYPE.AGENT; 
case 'INSTITUTIONAL': return PartnerData$PARTNER_TYPE.INSTITUTIONAL; 
            default: throw new Error('Invalid id for PartnerData$PARTNER_TYPE: ' + name);
        }
    }

    public static copyMe(given: PartnerData$PARTNER_TYPE): PartnerData$PARTNER_TYPE  {
            return PartnerData$PARTNER_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$PARTNER_TYPE { return PartnerData$PARTNER_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$PARTNER_TYPE.list().indexOf(this); }
}
 

export class SpineData$SCHEDULED_ENTRY_STATUS {

     public static get ARCHIVED() { return new SpineData$SCHEDULED_ENTRY_STATUS('ARCHIVED', 'ARCHIVED'); } 
public static get NSF() { return new SpineData$SCHEDULED_ENTRY_STATUS('NSF', 'NSF'); } 
public static get FAILED() { return new SpineData$SCHEDULED_ENTRY_STATUS('FAILED', 'FAILED'); } 
public static get PRESENTED() { return new SpineData$SCHEDULED_ENTRY_STATUS('PRESENTED', 'PRESENTED'); } 
public static get SCHEDULED() { return new SpineData$SCHEDULED_ENTRY_STATUS('SCHEDULED', 'SCHEDULED'); } 
public static get SKIPPED() { return new SpineData$SCHEDULED_ENTRY_STATUS('SKIPPED', 'SKIPPED'); } 
public static get NSF_RESCHEDULED() { return new SpineData$SCHEDULED_ENTRY_STATUS('NSF_RESCHEDULED', 'NSF_RESCHEDULED'); } 
public static get PAID() { return new SpineData$SCHEDULED_ENTRY_STATUS('PAID', 'PAID'); } 
public static get REJECTED() { return new SpineData$SCHEDULED_ENTRY_STATUS('REJECTED', 'REJECTED'); } 

     public static get DEFAULT_VALUE() { return SpineData$SCHEDULED_ENTRY_STATUS.SCHEDULED; }

     public static withIdOption(id: string): SpineData$SCHEDULED_ENTRY_STATUS| undefined  {
        try {
            return SpineData$SCHEDULED_ENTRY_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$SCHEDULED_ENTRY_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$SCHEDULED_ENTRY_STATUS[]  { return [SpineData$SCHEDULED_ENTRY_STATUS.REJECTED, SpineData$SCHEDULED_ENTRY_STATUS.NSF_RESCHEDULED, SpineData$SCHEDULED_ENTRY_STATUS.SKIPPED, SpineData$SCHEDULED_ENTRY_STATUS.PAID, SpineData$SCHEDULED_ENTRY_STATUS.PRESENTED, SpineData$SCHEDULED_ENTRY_STATUS.NSF, SpineData$SCHEDULED_ENTRY_STATUS.SCHEDULED, SpineData$SCHEDULED_ENTRY_STATUS.ARCHIVED, SpineData$SCHEDULED_ENTRY_STATUS.FAILED]; }
    public static idList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'REJECTED', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }
    public static nameList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'REJECTED', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }


    public static withId(id: string): SpineData$SCHEDULED_ENTRY_STATUS  {
        switch (id) {
            case 'NSF_RESCHEDULED': return SpineData$SCHEDULED_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'REJECTED': return SpineData$SCHEDULED_ENTRY_STATUS.REJECTED; 
case 'PRESENTED': return SpineData$SCHEDULED_ENTRY_STATUS.PRESENTED; 
case 'FAILED': return SpineData$SCHEDULED_ENTRY_STATUS.FAILED; 
case 'SCHEDULED': return SpineData$SCHEDULED_ENTRY_STATUS.SCHEDULED; 
case 'NSF': return SpineData$SCHEDULED_ENTRY_STATUS.NSF; 
case 'ARCHIVED': return SpineData$SCHEDULED_ENTRY_STATUS.ARCHIVED; 
case 'SKIPPED': return SpineData$SCHEDULED_ENTRY_STATUS.SKIPPED; 
case 'PAID': return SpineData$SCHEDULED_ENTRY_STATUS.PAID; 
            default: throw new Error('Invalid id for SpineData$SCHEDULED_ENTRY_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$SCHEDULED_ENTRY_STATUS  {
        switch (name) {
            case 'NSF_RESCHEDULED': return SpineData$SCHEDULED_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'REJECTED': return SpineData$SCHEDULED_ENTRY_STATUS.REJECTED; 
case 'PRESENTED': return SpineData$SCHEDULED_ENTRY_STATUS.PRESENTED; 
case 'FAILED': return SpineData$SCHEDULED_ENTRY_STATUS.FAILED; 
case 'SCHEDULED': return SpineData$SCHEDULED_ENTRY_STATUS.SCHEDULED; 
case 'NSF': return SpineData$SCHEDULED_ENTRY_STATUS.NSF; 
case 'ARCHIVED': return SpineData$SCHEDULED_ENTRY_STATUS.ARCHIVED; 
case 'SKIPPED': return SpineData$SCHEDULED_ENTRY_STATUS.SKIPPED; 
case 'PAID': return SpineData$SCHEDULED_ENTRY_STATUS.PAID; 
            default: throw new Error('Invalid id for SpineData$SCHEDULED_ENTRY_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$SCHEDULED_ENTRY_STATUS): SpineData$SCHEDULED_ENTRY_STATUS  {
            return SpineData$SCHEDULED_ENTRY_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$SCHEDULED_ENTRY_STATUS { return SpineData$SCHEDULED_ENTRY_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$SCHEDULED_ENTRY_STATUS.list().indexOf(this); }
}
 

export class SpineData$PROGRAM_CODE {

     public static get DRP() { return new SpineData$PROGRAM_CODE('DRP', 'DRP'); } 
public static get DCP() { return new SpineData$PROGRAM_CODE('DCP', 'DCP'); } 

     public static get DEFAULT_VALUE() { return SpineData$PROGRAM_CODE.DRP; }

     public static withIdOption(id: string): SpineData$PROGRAM_CODE| undefined  {
        try {
            return SpineData$PROGRAM_CODE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PROGRAM_CODE.withIdOption(id) !== undefined; }
    public static list(): SpineData$PROGRAM_CODE[]  { return [SpineData$PROGRAM_CODE.DRP, SpineData$PROGRAM_CODE.DCP]; }
    public static idList(): string[]  { return ['DRP', 'DCP']; }
    public static nameList(): string[]  { return ['DRP', 'DCP']; }


    public static withId(id: string): SpineData$PROGRAM_CODE  {
        switch (id) {
            case 'DRP': return SpineData$PROGRAM_CODE.DRP; 
case 'DCP': return SpineData$PROGRAM_CODE.DCP; 
            default: throw new Error('Invalid id for SpineData$PROGRAM_CODE: ' + id);
        }
    }

    public static withName(name: string): SpineData$PROGRAM_CODE  {
        switch (name) {
            case 'DRP': return SpineData$PROGRAM_CODE.DRP; 
case 'DCP': return SpineData$PROGRAM_CODE.DCP; 
            default: throw new Error('Invalid id for SpineData$PROGRAM_CODE: ' + name);
        }
    }

    public static copyMe(given: SpineData$PROGRAM_CODE): SpineData$PROGRAM_CODE  {
            return SpineData$PROGRAM_CODE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PROGRAM_CODE { return SpineData$PROGRAM_CODE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PROGRAM_CODE.list().indexOf(this); }
}
 

export class PartnerData$PARTNER_STATUS {

     public static get INACTIVE() { return new PartnerData$PARTNER_STATUS('INACTIVE', 'INACTIVE'); } 
public static get REJECTED() { return new PartnerData$PARTNER_STATUS('REJECTED', 'REJECTED'); } 
public static get ONBOARDING_REQUESTED() { return new PartnerData$PARTNER_STATUS('ONBOARDING_REQUESTED', 'ONBOARDING_REQUESTED'); } 
public static get ACTIVE() { return new PartnerData$PARTNER_STATUS('ACTIVE', 'ACTIVE'); } 
public static get SIGNED_UP() { return new PartnerData$PARTNER_STATUS('SIGNED_UP', 'SIGNED_UP'); } 

     public static get DEFAULT_VALUE() { return PartnerData$PARTNER_STATUS.SIGNED_UP; }

     public static withIdOption(id: string): PartnerData$PARTNER_STATUS| undefined  {
        try {
            return PartnerData$PARTNER_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$PARTNER_STATUS.withIdOption(id) !== undefined; }
    public static list(): PartnerData$PARTNER_STATUS[]  { return [PartnerData$PARTNER_STATUS.ACTIVE, PartnerData$PARTNER_STATUS.SIGNED_UP, PartnerData$PARTNER_STATUS.REJECTED, PartnerData$PARTNER_STATUS.INACTIVE, PartnerData$PARTNER_STATUS.ONBOARDING_REQUESTED]; }
    public static idList(): string[]  { return ['REJECTED', 'ONBOARDING_REQUESTED', 'ACTIVE', 'SIGNED_UP', 'INACTIVE']; }
    public static nameList(): string[]  { return ['REJECTED', 'ONBOARDING_REQUESTED', 'ACTIVE', 'SIGNED_UP', 'INACTIVE']; }


    public static withId(id: string): PartnerData$PARTNER_STATUS  {
        switch (id) {
            case 'INACTIVE': return PartnerData$PARTNER_STATUS.INACTIVE; 
case 'REJECTED': return PartnerData$PARTNER_STATUS.REJECTED; 
case 'ACTIVE': return PartnerData$PARTNER_STATUS.ACTIVE; 
case 'ONBOARDING_REQUESTED': return PartnerData$PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'SIGNED_UP': return PartnerData$PARTNER_STATUS.SIGNED_UP; 
            default: throw new Error('Invalid id for PartnerData$PARTNER_STATUS: ' + id);
        }
    }

    public static withName(name: string): PartnerData$PARTNER_STATUS  {
        switch (name) {
            case 'INACTIVE': return PartnerData$PARTNER_STATUS.INACTIVE; 
case 'REJECTED': return PartnerData$PARTNER_STATUS.REJECTED; 
case 'ACTIVE': return PartnerData$PARTNER_STATUS.ACTIVE; 
case 'ONBOARDING_REQUESTED': return PartnerData$PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'SIGNED_UP': return PartnerData$PARTNER_STATUS.SIGNED_UP; 
            default: throw new Error('Invalid id for PartnerData$PARTNER_STATUS: ' + name);
        }
    }

    public static copyMe(given: PartnerData$PARTNER_STATUS): PartnerData$PARTNER_STATUS  {
            return PartnerData$PARTNER_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$PARTNER_STATUS { return PartnerData$PARTNER_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$PARTNER_STATUS.list().indexOf(this); }
}
 

export class SpineData$TXN_PURPOSE {

     public static get SETTLEMENT() { return new SpineData$TXN_PURPOSE('SETTLEMENT', 'SETTLEMENT'); } 
public static get SLEEPING_CHARGES() { return new SpineData$TXN_PURPOSE('SLEEPING_CHARGES', 'SLEEPING_CHARGES'); } 
public static get SERVICE_FEE() { return new SpineData$TXN_PURPOSE('SERVICE_FEE', 'SERVICE_FEE'); } 
public static get MSF() { return new SpineData$TXN_PURPOSE('MSF', 'MSF'); } 
public static get ONBOARDING_FEE() { return new SpineData$TXN_PURPOSE('ONBOARDING_FEE', 'ONBOARDING_FEE'); } 
public static get SPA() { return new SpineData$TXN_PURPOSE('SPA', 'SPA'); } 
public static get OTHER_FEE() { return new SpineData$TXN_PURPOSE('OTHER_FEE', 'OTHER_FEE'); } 

     public static get DEFAULT_VALUE() { return SpineData$TXN_PURPOSE.SPA; }

     public static withIdOption(id: string): SpineData$TXN_PURPOSE| undefined  {
        try {
            return SpineData$TXN_PURPOSE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$TXN_PURPOSE.withIdOption(id) !== undefined; }
    public static list(): SpineData$TXN_PURPOSE[]  { return [SpineData$TXN_PURPOSE.SERVICE_FEE, SpineData$TXN_PURPOSE.SLEEPING_CHARGES, SpineData$TXN_PURPOSE.MSF, SpineData$TXN_PURPOSE.OTHER_FEE, SpineData$TXN_PURPOSE.SPA, SpineData$TXN_PURPOSE.ONBOARDING_FEE, SpineData$TXN_PURPOSE.SETTLEMENT]; }
    public static idList(): string[]  { return ['SPA', 'MSF', 'SLEEPING_CHARGES', 'OTHER_FEE', 'ONBOARDING_FEE', 'SERVICE_FEE', 'SETTLEMENT']; }
    public static nameList(): string[]  { return ['SPA', 'MSF', 'SLEEPING_CHARGES', 'OTHER_FEE', 'ONBOARDING_FEE', 'SERVICE_FEE', 'SETTLEMENT']; }


    public static withId(id: string): SpineData$TXN_PURPOSE  {
        switch (id) {
            case 'ONBOARDING_FEE': return SpineData$TXN_PURPOSE.ONBOARDING_FEE; 
case 'SPA': return SpineData$TXN_PURPOSE.SPA; 
case 'SERVICE_FEE': return SpineData$TXN_PURPOSE.SERVICE_FEE; 
case 'OTHER_FEE': return SpineData$TXN_PURPOSE.OTHER_FEE; 
case 'MSF': return SpineData$TXN_PURPOSE.MSF; 
case 'SETTLEMENT': return SpineData$TXN_PURPOSE.SETTLEMENT; 
case 'SLEEPING_CHARGES': return SpineData$TXN_PURPOSE.SLEEPING_CHARGES; 
            default: throw new Error('Invalid id for SpineData$TXN_PURPOSE: ' + id);
        }
    }

    public static withName(name: string): SpineData$TXN_PURPOSE  {
        switch (name) {
            case 'ONBOARDING_FEE': return SpineData$TXN_PURPOSE.ONBOARDING_FEE; 
case 'SPA': return SpineData$TXN_PURPOSE.SPA; 
case 'SERVICE_FEE': return SpineData$TXN_PURPOSE.SERVICE_FEE; 
case 'OTHER_FEE': return SpineData$TXN_PURPOSE.OTHER_FEE; 
case 'MSF': return SpineData$TXN_PURPOSE.MSF; 
case 'SETTLEMENT': return SpineData$TXN_PURPOSE.SETTLEMENT; 
case 'SLEEPING_CHARGES': return SpineData$TXN_PURPOSE.SLEEPING_CHARGES; 
            default: throw new Error('Invalid id for SpineData$TXN_PURPOSE: ' + name);
        }
    }

    public static copyMe(given: SpineData$TXN_PURPOSE): SpineData$TXN_PURPOSE  {
            return SpineData$TXN_PURPOSE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$TXN_PURPOSE { return SpineData$TXN_PURPOSE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$TXN_PURPOSE.list().indexOf(this); }
}
 

export class SpineData$MSF_ENTRY_STATUS {

     public static get PRESENTED() { return new SpineData$MSF_ENTRY_STATUS('PRESENTED', 'PRESENTED'); } 
public static get NSF() { return new SpineData$MSF_ENTRY_STATUS('NSF', 'NSF'); } 
public static get FAILED() { return new SpineData$MSF_ENTRY_STATUS('FAILED', 'FAILED'); } 
public static get PAID() { return new SpineData$MSF_ENTRY_STATUS('PAID', 'PAID'); } 
public static get SKIPPED() { return new SpineData$MSF_ENTRY_STATUS('SKIPPED', 'SKIPPED'); } 
public static get NSF_RESCHEDULED() { return new SpineData$MSF_ENTRY_STATUS('NSF_RESCHEDULED', 'NSF_RESCHEDULED'); } 
public static get SCHEDULED() { return new SpineData$MSF_ENTRY_STATUS('SCHEDULED', 'SCHEDULED'); } 
public static get ARCHIVED() { return new SpineData$MSF_ENTRY_STATUS('ARCHIVED', 'ARCHIVED'); } 

     public static get DEFAULT_VALUE() { return SpineData$MSF_ENTRY_STATUS.SCHEDULED; }

     public static withIdOption(id: string): SpineData$MSF_ENTRY_STATUS| undefined  {
        try {
            return SpineData$MSF_ENTRY_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$MSF_ENTRY_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$MSF_ENTRY_STATUS[]  { return [SpineData$MSF_ENTRY_STATUS.NSF, SpineData$MSF_ENTRY_STATUS.SKIPPED, SpineData$MSF_ENTRY_STATUS.FAILED, SpineData$MSF_ENTRY_STATUS.SCHEDULED, SpineData$MSF_ENTRY_STATUS.PAID, SpineData$MSF_ENTRY_STATUS.ARCHIVED, SpineData$MSF_ENTRY_STATUS.NSF_RESCHEDULED, SpineData$MSF_ENTRY_STATUS.PRESENTED]; }
    public static idList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }
    public static nameList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'SKIPPED', 'FAILED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }


    public static withId(id: string): SpineData$MSF_ENTRY_STATUS  {
        switch (id) {
            case 'ARCHIVED': return SpineData$MSF_ENTRY_STATUS.ARCHIVED; 
case 'FAILED': return SpineData$MSF_ENTRY_STATUS.FAILED; 
case 'SCHEDULED': return SpineData$MSF_ENTRY_STATUS.SCHEDULED; 
case 'NSF_RESCHEDULED': return SpineData$MSF_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'PAID': return SpineData$MSF_ENTRY_STATUS.PAID; 
case 'NSF': return SpineData$MSF_ENTRY_STATUS.NSF; 
case 'PRESENTED': return SpineData$MSF_ENTRY_STATUS.PRESENTED; 
case 'SKIPPED': return SpineData$MSF_ENTRY_STATUS.SKIPPED; 
            default: throw new Error('Invalid id for SpineData$MSF_ENTRY_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$MSF_ENTRY_STATUS  {
        switch (name) {
            case 'ARCHIVED': return SpineData$MSF_ENTRY_STATUS.ARCHIVED; 
case 'FAILED': return SpineData$MSF_ENTRY_STATUS.FAILED; 
case 'SCHEDULED': return SpineData$MSF_ENTRY_STATUS.SCHEDULED; 
case 'NSF_RESCHEDULED': return SpineData$MSF_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'PAID': return SpineData$MSF_ENTRY_STATUS.PAID; 
case 'NSF': return SpineData$MSF_ENTRY_STATUS.NSF; 
case 'PRESENTED': return SpineData$MSF_ENTRY_STATUS.PRESENTED; 
case 'SKIPPED': return SpineData$MSF_ENTRY_STATUS.SKIPPED; 
            default: throw new Error('Invalid id for SpineData$MSF_ENTRY_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$MSF_ENTRY_STATUS): SpineData$MSF_ENTRY_STATUS  {
            return SpineData$MSF_ENTRY_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$MSF_ENTRY_STATUS { return SpineData$MSF_ENTRY_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$MSF_ENTRY_STATUS.list().indexOf(this); }
}
 

export class SpineData$SETTLED_TO {

     public static get CREDITOR() { return new SpineData$SETTLED_TO('CREDITOR', 'CREDITOR'); } 
public static get CL_PERSONAL() { return new SpineData$SETTLED_TO('CL_PERSONAL', 'CL_PERSONAL'); } 
public static get KFS() { return new SpineData$SETTLED_TO('KFS', 'KFS'); } 

     public static get DEFAULT_VALUE() { return SpineData$SETTLED_TO.CREDITOR; }

     public static withIdOption(id: string): SpineData$SETTLED_TO| undefined  {
        try {
            return SpineData$SETTLED_TO.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$SETTLED_TO.withIdOption(id) !== undefined; }
    public static list(): SpineData$SETTLED_TO[]  { return [SpineData$SETTLED_TO.CREDITOR, SpineData$SETTLED_TO.CL_PERSONAL, SpineData$SETTLED_TO.KFS]; }
    public static idList(): string[]  { return ['CREDITOR', 'CL_PERSONAL', 'KFS']; }
    public static nameList(): string[]  { return ['CREDITOR', 'CL_PERSONAL', 'KFS']; }


    public static withId(id: string): SpineData$SETTLED_TO  {
        switch (id) {
            case 'CREDITOR': return SpineData$SETTLED_TO.CREDITOR; 
case 'CL_PERSONAL': return SpineData$SETTLED_TO.CL_PERSONAL; 
case 'KFS': return SpineData$SETTLED_TO.KFS; 
            default: throw new Error('Invalid id for SpineData$SETTLED_TO: ' + id);
        }
    }

    public static withName(name: string): SpineData$SETTLED_TO  {
        switch (name) {
            case 'CREDITOR': return SpineData$SETTLED_TO.CREDITOR; 
case 'CL_PERSONAL': return SpineData$SETTLED_TO.CL_PERSONAL; 
case 'KFS': return SpineData$SETTLED_TO.KFS; 
            default: throw new Error('Invalid id for SpineData$SETTLED_TO: ' + name);
        }
    }

    public static copyMe(given: SpineData$SETTLED_TO): SpineData$SETTLED_TO  {
            return SpineData$SETTLED_TO.withId(given.id)
    }

    public static fromJson(json: string): SpineData$SETTLED_TO { return SpineData$SETTLED_TO.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$SETTLED_TO.list().indexOf(this); }
}
 

export class SpineData$QRCODE_STATUS {

     public static get CREATED() { return new SpineData$QRCODE_STATUS('CREATED', 'Created'); } 
public static get EXPIRED() { return new SpineData$QRCODE_STATUS('EXPIRED', 'Expired'); } 
public static get PAID() { return new SpineData$QRCODE_STATUS('PAID', 'Paid'); } 
public static get FAILED() { return new SpineData$QRCODE_STATUS('FAILED', 'Failed'); } 

     public static get DEFAULT_VALUE() { return SpineData$QRCODE_STATUS.CREATED; }

     public static withIdOption(id: string): SpineData$QRCODE_STATUS| undefined  {
        try {
            return SpineData$QRCODE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$QRCODE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$QRCODE_STATUS[]  { return [SpineData$QRCODE_STATUS.CREATED, SpineData$QRCODE_STATUS.EXPIRED, SpineData$QRCODE_STATUS.PAID, SpineData$QRCODE_STATUS.FAILED]; }
    public static idList(): string[]  { return ['CREATED', 'EXPIRED', 'PAID', 'FAILED']; }
    public static nameList(): string[]  { return ['Created', 'Expired', 'Paid', 'Failed']; }


    public static withId(id: string): SpineData$QRCODE_STATUS  {
        switch (id) {
            case 'CREATED': return SpineData$QRCODE_STATUS.CREATED; 
case 'EXPIRED': return SpineData$QRCODE_STATUS.EXPIRED; 
case 'PAID': return SpineData$QRCODE_STATUS.PAID; 
case 'FAILED': return SpineData$QRCODE_STATUS.FAILED; 
            default: throw new Error('Invalid id for SpineData$QRCODE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$QRCODE_STATUS  {
        switch (name) {
            case 'Created': return SpineData$QRCODE_STATUS.CREATED; 
case 'Expired': return SpineData$QRCODE_STATUS.EXPIRED; 
case 'Paid': return SpineData$QRCODE_STATUS.PAID; 
case 'Failed': return SpineData$QRCODE_STATUS.FAILED; 
            default: throw new Error('Invalid id for SpineData$QRCODE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$QRCODE_STATUS): SpineData$QRCODE_STATUS  {
            return SpineData$QRCODE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$QRCODE_STATUS { return SpineData$QRCODE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$QRCODE_STATUS.list().indexOf(this); }
}
 

export class PartnerData$CREDIT_TYPE {

     public static get SECURED() { return new PartnerData$CREDIT_TYPE('SECURED', 'Secured'); } 
public static get UNSECURED() { return new PartnerData$CREDIT_TYPE('UNSECURED', 'UnSecured'); } 

     public static get DEFAULT_VALUE() { return PartnerData$CREDIT_TYPE.SECURED; }

     public static withIdOption(id: string): PartnerData$CREDIT_TYPE| undefined  {
        try {
            return PartnerData$CREDIT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$CREDIT_TYPE.withIdOption(id) !== undefined; }
    public static list(): PartnerData$CREDIT_TYPE[]  { return [PartnerData$CREDIT_TYPE.SECURED, PartnerData$CREDIT_TYPE.UNSECURED]; }
    public static idList(): string[]  { return ['SECURED', 'UNSECURED']; }
    public static nameList(): string[]  { return ['Secured', 'UnSecured']; }


    public static withId(id: string): PartnerData$CREDIT_TYPE  {
        switch (id) {
            case 'SECURED': return PartnerData$CREDIT_TYPE.SECURED; 
case 'UNSECURED': return PartnerData$CREDIT_TYPE.UNSECURED; 
            default: throw new Error('Invalid id for PartnerData$CREDIT_TYPE: ' + id);
        }
    }

    public static withName(name: string): PartnerData$CREDIT_TYPE  {
        switch (name) {
            case 'Secured': return PartnerData$CREDIT_TYPE.SECURED; 
case 'UnSecured': return PartnerData$CREDIT_TYPE.UNSECURED; 
            default: throw new Error('Invalid id for PartnerData$CREDIT_TYPE: ' + name);
        }
    }

    public static copyMe(given: PartnerData$CREDIT_TYPE): PartnerData$CREDIT_TYPE  {
            return PartnerData$CREDIT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$CREDIT_TYPE { return PartnerData$CREDIT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$CREDIT_TYPE.list().indexOf(this); }
}
 

export class SpineData$EXCEPTION_TAKEN {

     public static get DELINQUENCY() { return new SpineData$EXCEPTION_TAKEN('DELINQUENCY', 'DELINQUENCY'); } 
public static get DRAFT() { return new SpineData$EXCEPTION_TAKEN('DRAFT', 'DRAFT'); } 
public static get BALLOON_PAYMENT() { return new SpineData$EXCEPTION_TAKEN('BALLOON_PAYMENT', 'BALLOON_PAYMENT'); } 
public static get MSF() { return new SpineData$EXCEPTION_TAKEN('MSF', 'MSF'); } 
public static get TENURE() { return new SpineData$EXCEPTION_TAKEN('TENURE', 'TENURE'); } 
public static get HARDSHIP() { return new SpineData$EXCEPTION_TAKEN('HARDSHIP', 'HARDSHIP'); } 
public static get NO() { return new SpineData$EXCEPTION_TAKEN('NO', 'NO'); } 
public static get DEBT_LOAD() { return new SpineData$EXCEPTION_TAKEN('DEBT_LOAD', 'DEBT_LOAD'); } 

     public static get DEFAULT_VALUE() { return SpineData$EXCEPTION_TAKEN.NO; }

     public static withIdOption(id: string): SpineData$EXCEPTION_TAKEN| undefined  {
        try {
            return SpineData$EXCEPTION_TAKEN.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$EXCEPTION_TAKEN.withIdOption(id) !== undefined; }
    public static list(): SpineData$EXCEPTION_TAKEN[]  { return [SpineData$EXCEPTION_TAKEN.NO, SpineData$EXCEPTION_TAKEN.DRAFT, SpineData$EXCEPTION_TAKEN.DELINQUENCY, SpineData$EXCEPTION_TAKEN.DEBT_LOAD, SpineData$EXCEPTION_TAKEN.HARDSHIP, SpineData$EXCEPTION_TAKEN.MSF, SpineData$EXCEPTION_TAKEN.BALLOON_PAYMENT, SpineData$EXCEPTION_TAKEN.TENURE]; }
    public static idList(): string[]  { return ['DEBT_LOAD', 'DELINQUENCY', 'MSF', 'TENURE', 'BALLOON_PAYMENT', 'DRAFT', 'NO', 'HARDSHIP']; }
    public static nameList(): string[]  { return ['DEBT_LOAD', 'DELINQUENCY', 'MSF', 'TENURE', 'BALLOON_PAYMENT', 'DRAFT', 'NO', 'HARDSHIP']; }


    public static withId(id: string): SpineData$EXCEPTION_TAKEN  {
        switch (id) {
            case 'TENURE': return SpineData$EXCEPTION_TAKEN.TENURE; 
case 'DEBT_LOAD': return SpineData$EXCEPTION_TAKEN.DEBT_LOAD; 
case 'DELINQUENCY': return SpineData$EXCEPTION_TAKEN.DELINQUENCY; 
case 'DRAFT': return SpineData$EXCEPTION_TAKEN.DRAFT; 
case 'MSF': return SpineData$EXCEPTION_TAKEN.MSF; 
case 'BALLOON_PAYMENT': return SpineData$EXCEPTION_TAKEN.BALLOON_PAYMENT; 
case 'HARDSHIP': return SpineData$EXCEPTION_TAKEN.HARDSHIP; 
case 'NO': return SpineData$EXCEPTION_TAKEN.NO; 
            default: throw new Error('Invalid id for SpineData$EXCEPTION_TAKEN: ' + id);
        }
    }

    public static withName(name: string): SpineData$EXCEPTION_TAKEN  {
        switch (name) {
            case 'TENURE': return SpineData$EXCEPTION_TAKEN.TENURE; 
case 'DEBT_LOAD': return SpineData$EXCEPTION_TAKEN.DEBT_LOAD; 
case 'DELINQUENCY': return SpineData$EXCEPTION_TAKEN.DELINQUENCY; 
case 'DRAFT': return SpineData$EXCEPTION_TAKEN.DRAFT; 
case 'MSF': return SpineData$EXCEPTION_TAKEN.MSF; 
case 'BALLOON_PAYMENT': return SpineData$EXCEPTION_TAKEN.BALLOON_PAYMENT; 
case 'HARDSHIP': return SpineData$EXCEPTION_TAKEN.HARDSHIP; 
case 'NO': return SpineData$EXCEPTION_TAKEN.NO; 
            default: throw new Error('Invalid id for SpineData$EXCEPTION_TAKEN: ' + name);
        }
    }

    public static copyMe(given: SpineData$EXCEPTION_TAKEN): SpineData$EXCEPTION_TAKEN  {
            return SpineData$EXCEPTION_TAKEN.withId(given.id)
    }

    public static fromJson(json: string): SpineData$EXCEPTION_TAKEN { return SpineData$EXCEPTION_TAKEN.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$EXCEPTION_TAKEN.list().indexOf(this); }
}
 

export class PrismData$CommentLevel {

     public static get Flow() { return new PrismData$CommentLevel('Flow', 'Flow'); } 
public static get Task() { return new PrismData$CommentLevel('Task', 'Task'); } 

     public static get DEFAULT_VALUE() { return PrismData$CommentLevel.Flow; }

     public static withIdOption(id: string): PrismData$CommentLevel| undefined  {
        try {
            return PrismData$CommentLevel.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PrismData$CommentLevel.withIdOption(id) !== undefined; }
    public static list(): PrismData$CommentLevel[]  { return [PrismData$CommentLevel.Flow, PrismData$CommentLevel.Task]; }
    public static idList(): string[]  { return ['Flow', 'Task']; }
    public static nameList(): string[]  { return ['Flow', 'Task']; }


    public static withId(id: string): PrismData$CommentLevel  {
        switch (id) {
            case 'Flow': return PrismData$CommentLevel.Flow; 
case 'Task': return PrismData$CommentLevel.Task; 
            default: throw new Error('Invalid id for PrismData$CommentLevel: ' + id);
        }
    }

    public static withName(name: string): PrismData$CommentLevel  {
        switch (name) {
            case 'Flow': return PrismData$CommentLevel.Flow; 
case 'Task': return PrismData$CommentLevel.Task; 
            default: throw new Error('Invalid id for PrismData$CommentLevel: ' + name);
        }
    }

    public static copyMe(given: PrismData$CommentLevel): PrismData$CommentLevel  {
            return PrismData$CommentLevel.withId(given.id)
    }

    public static fromJson(json: string): PrismData$CommentLevel { return PrismData$CommentLevel.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PrismData$CommentLevel.list().indexOf(this); }
}
 

export class SpineData$PAYMENT_AGGREGATOR {

     public static get EP() { return new SpineData$PAYMENT_AGGREGATOR('EP', 'EP'); } 
public static get NPCH() { return new SpineData$PAYMENT_AGGREGATOR('NPCH', 'NPCH'); } 

     public static get DEFAULT_VALUE() { return SpineData$PAYMENT_AGGREGATOR.EP; }

     public static withIdOption(id: string): SpineData$PAYMENT_AGGREGATOR| undefined  {
        try {
            return SpineData$PAYMENT_AGGREGATOR.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PAYMENT_AGGREGATOR.withIdOption(id) !== undefined; }
    public static list(): SpineData$PAYMENT_AGGREGATOR[]  { return [SpineData$PAYMENT_AGGREGATOR.EP, SpineData$PAYMENT_AGGREGATOR.NPCH]; }
    public static idList(): string[]  { return ['EP', 'NPCH']; }
    public static nameList(): string[]  { return ['EP', 'NPCH']; }


    public static withId(id: string): SpineData$PAYMENT_AGGREGATOR  {
        switch (id) {
            case 'EP': return SpineData$PAYMENT_AGGREGATOR.EP; 
case 'NPCH': return SpineData$PAYMENT_AGGREGATOR.NPCH; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_AGGREGATOR: ' + id);
        }
    }

    public static withName(name: string): SpineData$PAYMENT_AGGREGATOR  {
        switch (name) {
            case 'EP': return SpineData$PAYMENT_AGGREGATOR.EP; 
case 'NPCH': return SpineData$PAYMENT_AGGREGATOR.NPCH; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_AGGREGATOR: ' + name);
        }
    }

    public static copyMe(given: SpineData$PAYMENT_AGGREGATOR): SpineData$PAYMENT_AGGREGATOR  {
            return SpineData$PAYMENT_AGGREGATOR.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PAYMENT_AGGREGATOR { return SpineData$PAYMENT_AGGREGATOR.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PAYMENT_AGGREGATOR.list().indexOf(this); }
}
 

export class SpineData$AGREEMENT_STATUS {

     public static get ACITVE() { return new SpineData$AGREEMENT_STATUS('ACITVE', 'ACITVE'); } 
public static get REJECTED() { return new SpineData$AGREEMENT_STATUS('REJECTED', 'REJECTED'); } 
public static get SIGNED() { return new SpineData$AGREEMENT_STATUS('SIGNED', 'SIGNED'); } 
public static get CANCELLED() { return new SpineData$AGREEMENT_STATUS('CANCELLED', 'CANCELLED'); } 

     public static get DEFAULT_VALUE() { return SpineData$AGREEMENT_STATUS.ACITVE; }

     public static withIdOption(id: string): SpineData$AGREEMENT_STATUS| undefined  {
        try {
            return SpineData$AGREEMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$AGREEMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$AGREEMENT_STATUS[]  { return [SpineData$AGREEMENT_STATUS.ACITVE, SpineData$AGREEMENT_STATUS.REJECTED, SpineData$AGREEMENT_STATUS.SIGNED, SpineData$AGREEMENT_STATUS.CANCELLED]; }
    public static idList(): string[]  { return ['ACITVE', 'REJECTED', 'SIGNED', 'CANCELLED']; }
    public static nameList(): string[]  { return ['ACITVE', 'REJECTED', 'SIGNED', 'CANCELLED']; }


    public static withId(id: string): SpineData$AGREEMENT_STATUS  {
        switch (id) {
            case 'ACITVE': return SpineData$AGREEMENT_STATUS.ACITVE; 
case 'REJECTED': return SpineData$AGREEMENT_STATUS.REJECTED; 
case 'SIGNED': return SpineData$AGREEMENT_STATUS.SIGNED; 
case 'CANCELLED': return SpineData$AGREEMENT_STATUS.CANCELLED; 
            default: throw new Error('Invalid id for SpineData$AGREEMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$AGREEMENT_STATUS  {
        switch (name) {
            case 'ACITVE': return SpineData$AGREEMENT_STATUS.ACITVE; 
case 'REJECTED': return SpineData$AGREEMENT_STATUS.REJECTED; 
case 'SIGNED': return SpineData$AGREEMENT_STATUS.SIGNED; 
case 'CANCELLED': return SpineData$AGREEMENT_STATUS.CANCELLED; 
            default: throw new Error('Invalid id for SpineData$AGREEMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$AGREEMENT_STATUS): SpineData$AGREEMENT_STATUS  {
            return SpineData$AGREEMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$AGREEMENT_STATUS { return SpineData$AGREEMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$AGREEMENT_STATUS.list().indexOf(this); }
}
 

export class PartnerData$AGE_RANGE {

     public static get THIRTY_SIX_TO_FOURTY() { return new PartnerData$AGE_RANGE('THIRTY_SIX_TO_FOURTY', '36-40'); } 
public static get FOURTY_ONE_AND_ABOVE() { return new PartnerData$AGE_RANGE('FOURTY_ONE_AND_ABOVE', '41+'); } 
public static get TWENTY_SIX_TO_THIRTY() { return new PartnerData$AGE_RANGE('TWENTY_SIX_TO_THIRTY', '26-30'); } 
public static get THIRTY_ONE_TO_THIRTY_FIVE() { return new PartnerData$AGE_RANGE('THIRTY_ONE_TO_THIRTY_FIVE', '31-35'); } 
public static get EIGHTEEN_TO_TWENTY_FIVE() { return new PartnerData$AGE_RANGE('EIGHTEEN_TO_TWENTY_FIVE', '18-25'); } 

     public static get DEFAULT_VALUE() { return PartnerData$AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; }

     public static withIdOption(id: string): PartnerData$AGE_RANGE| undefined  {
        try {
            return PartnerData$AGE_RANGE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$AGE_RANGE.withIdOption(id) !== undefined; }
    public static list(): PartnerData$AGE_RANGE[]  { return [PartnerData$AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE, PartnerData$AGE_RANGE.THIRTY_SIX_TO_FOURTY, PartnerData$AGE_RANGE.TWENTY_SIX_TO_THIRTY, PartnerData$AGE_RANGE.FOURTY_ONE_AND_ABOVE, PartnerData$AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE]; }
    public static idList(): string[]  { return ['EIGHTEEN_TO_TWENTY_FIVE', 'TWENTY_SIX_TO_THIRTY', 'THIRTY_ONE_TO_THIRTY_FIVE', 'THIRTY_SIX_TO_FOURTY', 'FOURTY_ONE_AND_ABOVE']; }
    public static nameList(): string[]  { return ['18-25', '26-30', '31-35', '41+', '36-40']; }


    public static withId(id: string): PartnerData$AGE_RANGE  {
        switch (id) {
            case 'FOURTY_ONE_AND_ABOVE': return PartnerData$AGE_RANGE.FOURTY_ONE_AND_ABOVE; 
case 'TWENTY_SIX_TO_THIRTY': return PartnerData$AGE_RANGE.TWENTY_SIX_TO_THIRTY; 
case 'THIRTY_SIX_TO_FOURTY': return PartnerData$AGE_RANGE.THIRTY_SIX_TO_FOURTY; 
case 'THIRTY_ONE_TO_THIRTY_FIVE': return PartnerData$AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE; 
case 'EIGHTEEN_TO_TWENTY_FIVE': return PartnerData$AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; 
            default: throw new Error('Invalid id for PartnerData$AGE_RANGE: ' + id);
        }
    }

    public static withName(name: string): PartnerData$AGE_RANGE  {
        switch (name) {
            case '18-25': return PartnerData$AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; 
case '41+': return PartnerData$AGE_RANGE.FOURTY_ONE_AND_ABOVE; 
case '26-30': return PartnerData$AGE_RANGE.TWENTY_SIX_TO_THIRTY; 
case '31-35': return PartnerData$AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE; 
case '36-40': return PartnerData$AGE_RANGE.THIRTY_SIX_TO_FOURTY; 
            default: throw new Error('Invalid id for PartnerData$AGE_RANGE: ' + name);
        }
    }

    public static copyMe(given: PartnerData$AGE_RANGE): PartnerData$AGE_RANGE  {
            return PartnerData$AGE_RANGE.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$AGE_RANGE { return PartnerData$AGE_RANGE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$AGE_RANGE.list().indexOf(this); }
}
 

export class PartnerData$DEBT_TYPE {

     public static get PERSONAL_LOAN() { return new PartnerData$DEBT_TYPE('PERSONAL_LOAN', 'Personal Loan'); } 
public static get CREDIT_CARD() { return new PartnerData$DEBT_TYPE('CREDIT_CARD', 'Credit Card'); } 
public static get OTHERS() { return new PartnerData$DEBT_TYPE('OTHERS', 'Others'); } 

     public static get DEFAULT_VALUE() { return PartnerData$DEBT_TYPE.PERSONAL_LOAN; }

     public static withIdOption(id: string): PartnerData$DEBT_TYPE| undefined  {
        try {
            return PartnerData$DEBT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$DEBT_TYPE.withIdOption(id) !== undefined; }
    public static list(): PartnerData$DEBT_TYPE[]  { return [PartnerData$DEBT_TYPE.PERSONAL_LOAN, PartnerData$DEBT_TYPE.CREDIT_CARD, PartnerData$DEBT_TYPE.OTHERS]; }
    public static idList(): string[]  { return ['PERSONAL_LOAN', 'CREDIT_CARD', 'OTHERS']; }
    public static nameList(): string[]  { return ['Personal Loan', 'Credit Card', 'Others']; }


    public static withId(id: string): PartnerData$DEBT_TYPE  {
        switch (id) {
            case 'PERSONAL_LOAN': return PartnerData$DEBT_TYPE.PERSONAL_LOAN; 
case 'CREDIT_CARD': return PartnerData$DEBT_TYPE.CREDIT_CARD; 
case 'OTHERS': return PartnerData$DEBT_TYPE.OTHERS; 
            default: throw new Error('Invalid id for PartnerData$DEBT_TYPE: ' + id);
        }
    }

    public static withName(name: string): PartnerData$DEBT_TYPE  {
        switch (name) {
            case 'Personal Loan': return PartnerData$DEBT_TYPE.PERSONAL_LOAN; 
case 'Credit Card': return PartnerData$DEBT_TYPE.CREDIT_CARD; 
case 'Others': return PartnerData$DEBT_TYPE.OTHERS; 
            default: throw new Error('Invalid id for PartnerData$DEBT_TYPE: ' + name);
        }
    }

    public static copyMe(given: PartnerData$DEBT_TYPE): PartnerData$DEBT_TYPE  {
            return PartnerData$DEBT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$DEBT_TYPE { return PartnerData$DEBT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$DEBT_TYPE.list().indexOf(this); }
}
 

export class SpineData$EMANDATE_PROVIDER {

     public static get NUPAY() { return new SpineData$EMANDATE_PROVIDER('NUPAY', 'NUPAY'); } 
public static get ESCROWPAY() { return new SpineData$EMANDATE_PROVIDER('ESCROWPAY', 'ESCROWPAY'); } 

     public static get DEFAULT_VALUE() { return SpineData$EMANDATE_PROVIDER.NUPAY; }

     public static withIdOption(id: string): SpineData$EMANDATE_PROVIDER| undefined  {
        try {
            return SpineData$EMANDATE_PROVIDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$EMANDATE_PROVIDER.withIdOption(id) !== undefined; }
    public static list(): SpineData$EMANDATE_PROVIDER[]  { return [SpineData$EMANDATE_PROVIDER.NUPAY, SpineData$EMANDATE_PROVIDER.ESCROWPAY]; }
    public static idList(): string[]  { return ['NUPAY', 'ESCROWPAY']; }
    public static nameList(): string[]  { return ['NUPAY', 'ESCROWPAY']; }


    public static withId(id: string): SpineData$EMANDATE_PROVIDER  {
        switch (id) {
            case 'NUPAY': return SpineData$EMANDATE_PROVIDER.NUPAY; 
case 'ESCROWPAY': return SpineData$EMANDATE_PROVIDER.ESCROWPAY; 
            default: throw new Error('Invalid id for SpineData$EMANDATE_PROVIDER: ' + id);
        }
    }

    public static withName(name: string): SpineData$EMANDATE_PROVIDER  {
        switch (name) {
            case 'NUPAY': return SpineData$EMANDATE_PROVIDER.NUPAY; 
case 'ESCROWPAY': return SpineData$EMANDATE_PROVIDER.ESCROWPAY; 
            default: throw new Error('Invalid id for SpineData$EMANDATE_PROVIDER: ' + name);
        }
    }

    public static copyMe(given: SpineData$EMANDATE_PROVIDER): SpineData$EMANDATE_PROVIDER  {
            return SpineData$EMANDATE_PROVIDER.withId(given.id)
    }

    public static fromJson(json: string): SpineData$EMANDATE_PROVIDER { return SpineData$EMANDATE_PROVIDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$EMANDATE_PROVIDER.list().indexOf(this); }
}
 

export class SpineData$PAYMENT_STATUS {

     public static get FUND_SPLIT_REJECTED() { return new SpineData$PAYMENT_STATUS('FUND_SPLIT_REJECTED', 'FUND_SPLIT_REJECTED'); } 
public static get FUND_SPLIT_REQUESTED() { return new SpineData$PAYMENT_STATUS('FUND_SPLIT_REQUESTED', 'FUND_SPLIT_REQUESTED'); } 
public static get SETTLED() { return new SpineData$PAYMENT_STATUS('SETTLED', 'SETTLED'); } 
public static get CANCELLED() { return new SpineData$PAYMENT_STATUS('CANCELLED', 'CANCELLED'); } 
public static get RECEIVED() { return new SpineData$PAYMENT_STATUS('RECEIVED', 'RECEIVED'); } 
public static get DRAFT() { return new SpineData$PAYMENT_STATUS('DRAFT', 'DRAFT'); } 
public static get REJECTED() { return new SpineData$PAYMENT_STATUS('REJECTED', 'REJECTED'); } 
public static get FAILED() { return new SpineData$PAYMENT_STATUS('FAILED', 'FAILED'); } 
public static get NOT_REACHABLE() { return new SpineData$PAYMENT_STATUS('NOT_REACHABLE', 'NOT_REACHABLE'); } 
public static get PRESENTED() { return new SpineData$PAYMENT_STATUS('PRESENTED', 'PRESENTED'); } 

     public static get DEFAULT_VALUE() { return SpineData$PAYMENT_STATUS.DRAFT; }

     public static withIdOption(id: string): SpineData$PAYMENT_STATUS| undefined  {
        try {
            return SpineData$PAYMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PAYMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$PAYMENT_STATUS[]  { return [SpineData$PAYMENT_STATUS.NOT_REACHABLE, SpineData$PAYMENT_STATUS.FUND_SPLIT_REJECTED, SpineData$PAYMENT_STATUS.FAILED, SpineData$PAYMENT_STATUS.PRESENTED, SpineData$PAYMENT_STATUS.FUND_SPLIT_REQUESTED, SpineData$PAYMENT_STATUS.SETTLED, SpineData$PAYMENT_STATUS.DRAFT, SpineData$PAYMENT_STATUS.CANCELLED, SpineData$PAYMENT_STATUS.REJECTED, SpineData$PAYMENT_STATUS.RECEIVED]; }
    public static idList(): string[]  { return ['REJECTED', 'FUND_SPLIT_REQUESTED', 'CANCELLED', 'FAILED', 'FUND_SPLIT_REJECTED', 'SETTLED', 'DRAFT', 'PRESENTED', 'RECEIVED', 'NOT_REACHABLE']; }
    public static nameList(): string[]  { return ['REJECTED', 'FUND_SPLIT_REQUESTED', 'CANCELLED', 'FAILED', 'FUND_SPLIT_REJECTED', 'SETTLED', 'DRAFT', 'PRESENTED', 'RECEIVED', 'NOT_REACHABLE']; }


    public static withId(id: string): SpineData$PAYMENT_STATUS  {
        switch (id) {
            case 'PRESENTED': return SpineData$PAYMENT_STATUS.PRESENTED; 
case 'FAILED': return SpineData$PAYMENT_STATUS.FAILED; 
case 'FUND_SPLIT_REQUESTED': return SpineData$PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
case 'REJECTED': return SpineData$PAYMENT_STATUS.REJECTED; 
case 'SETTLED': return SpineData$PAYMENT_STATUS.SETTLED; 
case 'CANCELLED': return SpineData$PAYMENT_STATUS.CANCELLED; 
case 'DRAFT': return SpineData$PAYMENT_STATUS.DRAFT; 
case 'RECEIVED': return SpineData$PAYMENT_STATUS.RECEIVED; 
case 'NOT_REACHABLE': return SpineData$PAYMENT_STATUS.NOT_REACHABLE; 
case 'FUND_SPLIT_REJECTED': return SpineData$PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$PAYMENT_STATUS  {
        switch (name) {
            case 'PRESENTED': return SpineData$PAYMENT_STATUS.PRESENTED; 
case 'FAILED': return SpineData$PAYMENT_STATUS.FAILED; 
case 'FUND_SPLIT_REQUESTED': return SpineData$PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
case 'REJECTED': return SpineData$PAYMENT_STATUS.REJECTED; 
case 'SETTLED': return SpineData$PAYMENT_STATUS.SETTLED; 
case 'CANCELLED': return SpineData$PAYMENT_STATUS.CANCELLED; 
case 'DRAFT': return SpineData$PAYMENT_STATUS.DRAFT; 
case 'RECEIVED': return SpineData$PAYMENT_STATUS.RECEIVED; 
case 'NOT_REACHABLE': return SpineData$PAYMENT_STATUS.NOT_REACHABLE; 
case 'FUND_SPLIT_REJECTED': return SpineData$PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$PAYMENT_STATUS): SpineData$PAYMENT_STATUS  {
            return SpineData$PAYMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PAYMENT_STATUS { return SpineData$PAYMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PAYMENT_STATUS.list().indexOf(this); }
}
 

export class ValeyData$TaskType {

     public static get MANUAL() { return new ValeyData$TaskType('MANUAL', 'MANUAL'); } 
public static get MI() { return new ValeyData$TaskType('MI', 'MI'); } 
public static get FLOW() { return new ValeyData$TaskType('FLOW', 'FLOW'); } 
public static get PROCESSOR() { return new ValeyData$TaskType('PROCESSOR', 'PROCESSOR'); } 
public static get NOP() { return new ValeyData$TaskType('NOP', 'NOP'); } 
public static get SELF() { return new ValeyData$TaskType('SELF', 'SELF'); } 

     public static get DEFAULT_VALUE() { return ValeyData$TaskType.SELF; }

     public static withIdOption(id: string): ValeyData$TaskType| undefined  {
        try {
            return ValeyData$TaskType.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return ValeyData$TaskType.withIdOption(id) !== undefined; }
    public static list(): ValeyData$TaskType[]  { return [ValeyData$TaskType.PROCESSOR, ValeyData$TaskType.MI, ValeyData$TaskType.SELF, ValeyData$TaskType.MANUAL, ValeyData$TaskType.FLOW, ValeyData$TaskType.NOP]; }
    public static idList(): string[]  { return ['MI', 'SELF', 'FLOW', 'NOP', 'PROCESSOR', 'MANUAL']; }
    public static nameList(): string[]  { return ['MI', 'SELF', 'FLOW', 'NOP', 'PROCESSOR', 'MANUAL']; }


    public static withId(id: string): ValeyData$TaskType  {
        switch (id) {
            case 'FLOW': return ValeyData$TaskType.FLOW; 
case 'MANUAL': return ValeyData$TaskType.MANUAL; 
case 'MI': return ValeyData$TaskType.MI; 
case 'NOP': return ValeyData$TaskType.NOP; 
case 'PROCESSOR': return ValeyData$TaskType.PROCESSOR; 
case 'SELF': return ValeyData$TaskType.SELF; 
            default: throw new Error('Invalid id for ValeyData$TaskType: ' + id);
        }
    }

    public static withName(name: string): ValeyData$TaskType  {
        switch (name) {
            case 'FLOW': return ValeyData$TaskType.FLOW; 
case 'MANUAL': return ValeyData$TaskType.MANUAL; 
case 'MI': return ValeyData$TaskType.MI; 
case 'NOP': return ValeyData$TaskType.NOP; 
case 'PROCESSOR': return ValeyData$TaskType.PROCESSOR; 
case 'SELF': return ValeyData$TaskType.SELF; 
            default: throw new Error('Invalid id for ValeyData$TaskType: ' + name);
        }
    }

    public static copyMe(given: ValeyData$TaskType): ValeyData$TaskType  {
            return ValeyData$TaskType.withId(given.id)
    }

    public static fromJson(json: string): ValeyData$TaskType { return ValeyData$TaskType.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return ValeyData$TaskType.list().indexOf(this); }
}
 

export class SpineData$PAYMENT_PROVIDER {

     public static get NUPAY() { return new SpineData$PAYMENT_PROVIDER('NUPAY', 'NUPAY'); } 
public static get ESCROWPAY() { return new SpineData$PAYMENT_PROVIDER('ESCROWPAY', 'ESCROWPAY'); } 
public static get CASHFREE() { return new SpineData$PAYMENT_PROVIDER('CASHFREE', 'CASHFREE'); } 
public static get OTHERS() { return new SpineData$PAYMENT_PROVIDER('OTHERS', 'OTHERS'); } 

     public static get DEFAULT_VALUE() { return SpineData$PAYMENT_PROVIDER.NUPAY; }

     public static withIdOption(id: string): SpineData$PAYMENT_PROVIDER| undefined  {
        try {
            return SpineData$PAYMENT_PROVIDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PAYMENT_PROVIDER.withIdOption(id) !== undefined; }
    public static list(): SpineData$PAYMENT_PROVIDER[]  { return [SpineData$PAYMENT_PROVIDER.NUPAY, SpineData$PAYMENT_PROVIDER.ESCROWPAY, SpineData$PAYMENT_PROVIDER.CASHFREE, SpineData$PAYMENT_PROVIDER.OTHERS]; }
    public static idList(): string[]  { return ['NUPAY', 'ESCROWPAY', 'CASHFREE', 'OTHERS']; }
    public static nameList(): string[]  { return ['NUPAY', 'ESCROWPAY', 'CASHFREE', 'OTHERS']; }


    public static withId(id: string): SpineData$PAYMENT_PROVIDER  {
        switch (id) {
            case 'NUPAY': return SpineData$PAYMENT_PROVIDER.NUPAY; 
case 'ESCROWPAY': return SpineData$PAYMENT_PROVIDER.ESCROWPAY; 
case 'CASHFREE': return SpineData$PAYMENT_PROVIDER.CASHFREE; 
case 'OTHERS': return SpineData$PAYMENT_PROVIDER.OTHERS; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_PROVIDER: ' + id);
        }
    }

    public static withName(name: string): SpineData$PAYMENT_PROVIDER  {
        switch (name) {
            case 'NUPAY': return SpineData$PAYMENT_PROVIDER.NUPAY; 
case 'ESCROWPAY': return SpineData$PAYMENT_PROVIDER.ESCROWPAY; 
case 'CASHFREE': return SpineData$PAYMENT_PROVIDER.CASHFREE; 
case 'OTHERS': return SpineData$PAYMENT_PROVIDER.OTHERS; 
            default: throw new Error('Invalid id for SpineData$PAYMENT_PROVIDER: ' + name);
        }
    }

    public static copyMe(given: SpineData$PAYMENT_PROVIDER): SpineData$PAYMENT_PROVIDER  {
            return SpineData$PAYMENT_PROVIDER.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PAYMENT_PROVIDER { return SpineData$PAYMENT_PROVIDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PAYMENT_PROVIDER.list().indexOf(this); }
}
 

export class SpineData$ACCOUNT_TYPE {

     public static get SAVINGS() { return new SpineData$ACCOUNT_TYPE('SAVINGS', 'Savings'); } 
public static get CURRENT() { return new SpineData$ACCOUNT_TYPE('CURRENT', 'Current'); } 

     public static get DEFAULT_VALUE() { return SpineData$ACCOUNT_TYPE.SAVINGS; }

     public static withIdOption(id: string): SpineData$ACCOUNT_TYPE| undefined  {
        try {
            return SpineData$ACCOUNT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$ACCOUNT_TYPE.withIdOption(id) !== undefined; }
    public static list(): SpineData$ACCOUNT_TYPE[]  { return [SpineData$ACCOUNT_TYPE.SAVINGS, SpineData$ACCOUNT_TYPE.CURRENT]; }
    public static idList(): string[]  { return ['SAVINGS', 'CURRENT']; }
    public static nameList(): string[]  { return ['Savings', 'Current']; }


    public static withId(id: string): SpineData$ACCOUNT_TYPE  {
        switch (id) {
            case 'SAVINGS': return SpineData$ACCOUNT_TYPE.SAVINGS; 
case 'CURRENT': return SpineData$ACCOUNT_TYPE.CURRENT; 
            default: throw new Error('Invalid id for SpineData$ACCOUNT_TYPE: ' + id);
        }
    }

    public static withName(name: string): SpineData$ACCOUNT_TYPE  {
        switch (name) {
            case 'Savings': return SpineData$ACCOUNT_TYPE.SAVINGS; 
case 'Current': return SpineData$ACCOUNT_TYPE.CURRENT; 
            default: throw new Error('Invalid id for SpineData$ACCOUNT_TYPE: ' + name);
        }
    }

    public static copyMe(given: SpineData$ACCOUNT_TYPE): SpineData$ACCOUNT_TYPE  {
            return SpineData$ACCOUNT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$ACCOUNT_TYPE { return SpineData$ACCOUNT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$ACCOUNT_TYPE.list().indexOf(this); }
}
 

export class SpineData$EMANDATE_STATUS {

     public static get EXPIRED() { return new SpineData$EMANDATE_STATUS('EXPIRED', 'EXPIRED'); } 
public static get DRAFTED() { return new SpineData$EMANDATE_STATUS('DRAFTED', 'DRAFTED'); } 
public static get PENDING() { return new SpineData$EMANDATE_STATUS('PENDING', 'PENDING'); } 
public static get CANCELLED() { return new SpineData$EMANDATE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get ACTIVE() { return new SpineData$EMANDATE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get REJECTED() { return new SpineData$EMANDATE_STATUS('REJECTED', 'REJECTED'); } 

     public static get DEFAULT_VALUE() { return SpineData$EMANDATE_STATUS.DRAFTED; }

     public static withIdOption(id: string): SpineData$EMANDATE_STATUS| undefined  {
        try {
            return SpineData$EMANDATE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$EMANDATE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$EMANDATE_STATUS[]  { return [SpineData$EMANDATE_STATUS.REJECTED, SpineData$EMANDATE_STATUS.PENDING, SpineData$EMANDATE_STATUS.CANCELLED, SpineData$EMANDATE_STATUS.DRAFTED, SpineData$EMANDATE_STATUS.EXPIRED, SpineData$EMANDATE_STATUS.ACTIVE]; }
    public static idList(): string[]  { return ['DRAFTED', 'REJECTED', 'CANCELLED', 'ACTIVE', 'PENDING', 'EXPIRED']; }
    public static nameList(): string[]  { return ['DRAFTED', 'REJECTED', 'CANCELLED', 'ACTIVE', 'PENDING', 'EXPIRED']; }


    public static withId(id: string): SpineData$EMANDATE_STATUS  {
        switch (id) {
            case 'EXPIRED': return SpineData$EMANDATE_STATUS.EXPIRED; 
case 'CANCELLED': return SpineData$EMANDATE_STATUS.CANCELLED; 
case 'ACTIVE': return SpineData$EMANDATE_STATUS.ACTIVE; 
case 'DRAFTED': return SpineData$EMANDATE_STATUS.DRAFTED; 
case 'REJECTED': return SpineData$EMANDATE_STATUS.REJECTED; 
case 'PENDING': return SpineData$EMANDATE_STATUS.PENDING; 
            default: throw new Error('Invalid id for SpineData$EMANDATE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$EMANDATE_STATUS  {
        switch (name) {
            case 'EXPIRED': return SpineData$EMANDATE_STATUS.EXPIRED; 
case 'CANCELLED': return SpineData$EMANDATE_STATUS.CANCELLED; 
case 'ACTIVE': return SpineData$EMANDATE_STATUS.ACTIVE; 
case 'DRAFTED': return SpineData$EMANDATE_STATUS.DRAFTED; 
case 'REJECTED': return SpineData$EMANDATE_STATUS.REJECTED; 
case 'PENDING': return SpineData$EMANDATE_STATUS.PENDING; 
            default: throw new Error('Invalid id for SpineData$EMANDATE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$EMANDATE_STATUS): SpineData$EMANDATE_STATUS  {
            return SpineData$EMANDATE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$EMANDATE_STATUS { return SpineData$EMANDATE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$EMANDATE_STATUS.list().indexOf(this); }
}
 

export class PartnerData$DOCUMENT_TYPE {

     public static get CERTIFICATE() { return new PartnerData$DOCUMENT_TYPE('CERTIFICATE', 'CERTIFICATE'); } 
public static get IDENTIFICATION() { return new PartnerData$DOCUMENT_TYPE('IDENTIFICATION', 'IDENTIFICATION'); } 

     public static get DEFAULT_VALUE() { return PartnerData$DOCUMENT_TYPE.CERTIFICATE; }

     public static withIdOption(id: string): PartnerData$DOCUMENT_TYPE| undefined  {
        try {
            return PartnerData$DOCUMENT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PartnerData$DOCUMENT_TYPE.withIdOption(id) !== undefined; }
    public static list(): PartnerData$DOCUMENT_TYPE[]  { return [PartnerData$DOCUMENT_TYPE.CERTIFICATE, PartnerData$DOCUMENT_TYPE.IDENTIFICATION]; }
    public static idList(): string[]  { return ['CERTIFICATE', 'IDENTIFICATION']; }
    public static nameList(): string[]  { return ['CERTIFICATE', 'IDENTIFICATION']; }


    public static withId(id: string): PartnerData$DOCUMENT_TYPE  {
        switch (id) {
            case 'CERTIFICATE': return PartnerData$DOCUMENT_TYPE.CERTIFICATE; 
case 'IDENTIFICATION': return PartnerData$DOCUMENT_TYPE.IDENTIFICATION; 
            default: throw new Error('Invalid id for PartnerData$DOCUMENT_TYPE: ' + id);
        }
    }

    public static withName(name: string): PartnerData$DOCUMENT_TYPE  {
        switch (name) {
            case 'CERTIFICATE': return PartnerData$DOCUMENT_TYPE.CERTIFICATE; 
case 'IDENTIFICATION': return PartnerData$DOCUMENT_TYPE.IDENTIFICATION; 
            default: throw new Error('Invalid id for PartnerData$DOCUMENT_TYPE: ' + name);
        }
    }

    public static copyMe(given: PartnerData$DOCUMENT_TYPE): PartnerData$DOCUMENT_TYPE  {
            return PartnerData$DOCUMENT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PartnerData$DOCUMENT_TYPE { return PartnerData$DOCUMENT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PartnerData$DOCUMENT_TYPE.list().indexOf(this); }
}
 

export class SpineData$CLIENT_FILE_STAGE {

     public static get CREDIT_REPORT() { return new SpineData$CLIENT_FILE_STAGE('CREDIT_REPORT', 'CREDIT_REPORT'); } 
public static get BUDGET_DONE() { return new SpineData$CLIENT_FILE_STAGE('BUDGET_DONE', 'BUDGET_DONE'); } 
public static get FEE_PAID() { return new SpineData$CLIENT_FILE_STAGE('FEE_PAID', 'FEE_PAID'); } 
public static get EMANDATE_DONE() { return new SpineData$CLIENT_FILE_STAGE('EMANDATE_DONE', 'EMANDATE_DONE'); } 
public static get PROGRAM_GENERATED() { return new SpineData$CLIENT_FILE_STAGE('PROGRAM_GENERATED', 'PROGRAM_GENERATED'); } 
public static get AGREEMENT_SIGNED() { return new SpineData$CLIENT_FILE_STAGE('AGREEMENT_SIGNED', 'AGREEMENT_SIGNED'); } 
public static get SIGN_UP() { return new SpineData$CLIENT_FILE_STAGE('SIGN_UP', 'SIGN_UP'); } 
public static get BANK_DETAILS_ENTERED() { return new SpineData$CLIENT_FILE_STAGE('BANK_DETAILS_ENTERED', 'BANK_DETAILS_ENTERED'); } 

     public static get DEFAULT_VALUE() { return SpineData$CLIENT_FILE_STAGE.SIGN_UP; }

     public static withIdOption(id: string): SpineData$CLIENT_FILE_STAGE| undefined  {
        try {
            return SpineData$CLIENT_FILE_STAGE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$CLIENT_FILE_STAGE.withIdOption(id) !== undefined; }
    public static list(): SpineData$CLIENT_FILE_STAGE[]  { return [SpineData$CLIENT_FILE_STAGE.SIGN_UP, SpineData$CLIENT_FILE_STAGE.AGREEMENT_SIGNED, SpineData$CLIENT_FILE_STAGE.CREDIT_REPORT, SpineData$CLIENT_FILE_STAGE.BANK_DETAILS_ENTERED, SpineData$CLIENT_FILE_STAGE.BUDGET_DONE, SpineData$CLIENT_FILE_STAGE.FEE_PAID, SpineData$CLIENT_FILE_STAGE.PROGRAM_GENERATED, SpineData$CLIENT_FILE_STAGE.EMANDATE_DONE]; }
    public static idList(): string[]  { return ['BUDGET_DONE', 'PROGRAM_GENERATED', 'FEE_PAID', 'AGREEMENT_SIGNED', 'EMANDATE_DONE', 'BANK_DETAILS_ENTERED', 'SIGN_UP', 'CREDIT_REPORT']; }
    public static nameList(): string[]  { return ['BUDGET_DONE', 'PROGRAM_GENERATED', 'FEE_PAID', 'AGREEMENT_SIGNED', 'EMANDATE_DONE', 'BANK_DETAILS_ENTERED', 'SIGN_UP', 'CREDIT_REPORT']; }


    public static withId(id: string): SpineData$CLIENT_FILE_STAGE  {
        switch (id) {
            case 'BANK_DETAILS_ENTERED': return SpineData$CLIENT_FILE_STAGE.BANK_DETAILS_ENTERED; 
case 'AGREEMENT_SIGNED': return SpineData$CLIENT_FILE_STAGE.AGREEMENT_SIGNED; 
case 'PROGRAM_GENERATED': return SpineData$CLIENT_FILE_STAGE.PROGRAM_GENERATED; 
case 'EMANDATE_DONE': return SpineData$CLIENT_FILE_STAGE.EMANDATE_DONE; 
case 'BUDGET_DONE': return SpineData$CLIENT_FILE_STAGE.BUDGET_DONE; 
case 'FEE_PAID': return SpineData$CLIENT_FILE_STAGE.FEE_PAID; 
case 'CREDIT_REPORT': return SpineData$CLIENT_FILE_STAGE.CREDIT_REPORT; 
case 'SIGN_UP': return SpineData$CLIENT_FILE_STAGE.SIGN_UP; 
            default: throw new Error('Invalid id for SpineData$CLIENT_FILE_STAGE: ' + id);
        }
    }

    public static withName(name: string): SpineData$CLIENT_FILE_STAGE  {
        switch (name) {
            case 'BANK_DETAILS_ENTERED': return SpineData$CLIENT_FILE_STAGE.BANK_DETAILS_ENTERED; 
case 'AGREEMENT_SIGNED': return SpineData$CLIENT_FILE_STAGE.AGREEMENT_SIGNED; 
case 'PROGRAM_GENERATED': return SpineData$CLIENT_FILE_STAGE.PROGRAM_GENERATED; 
case 'EMANDATE_DONE': return SpineData$CLIENT_FILE_STAGE.EMANDATE_DONE; 
case 'BUDGET_DONE': return SpineData$CLIENT_FILE_STAGE.BUDGET_DONE; 
case 'FEE_PAID': return SpineData$CLIENT_FILE_STAGE.FEE_PAID; 
case 'CREDIT_REPORT': return SpineData$CLIENT_FILE_STAGE.CREDIT_REPORT; 
case 'SIGN_UP': return SpineData$CLIENT_FILE_STAGE.SIGN_UP; 
            default: throw new Error('Invalid id for SpineData$CLIENT_FILE_STAGE: ' + name);
        }
    }

    public static copyMe(given: SpineData$CLIENT_FILE_STAGE): SpineData$CLIENT_FILE_STAGE  {
            return SpineData$CLIENT_FILE_STAGE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$CLIENT_FILE_STAGE { return SpineData$CLIENT_FILE_STAGE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$CLIENT_FILE_STAGE.list().indexOf(this); }
}
 

export class SpineData$CLIENT_FILE_STATUS {

     public static get CANCELLED() { return new SpineData$CLIENT_FILE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get PARTIALLY_GRADUATED() { return new SpineData$CLIENT_FILE_STATUS('PARTIALLY_GRADUATED', 'PARTIALLY_GRADUATED'); } 
public static get REJECTED() { return new SpineData$CLIENT_FILE_STATUS('REJECTED', 'REJECTED'); } 
public static get HOLD() { return new SpineData$CLIENT_FILE_STATUS('HOLD', 'HOLD'); } 
public static get LEAD() { return new SpineData$CLIENT_FILE_STATUS('LEAD', 'LEAD'); } 
public static get GRADUATED() { return new SpineData$CLIENT_FILE_STATUS('GRADUATED', 'GRADUATED'); } 
public static get ACTIVE() { return new SpineData$CLIENT_FILE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get PENDING_CANCELLED() { return new SpineData$CLIENT_FILE_STATUS('PENDING_CANCELLED', 'PENDING_CANCELLED'); } 
public static get AGREEMENT_SIGNED() { return new SpineData$CLIENT_FILE_STATUS('AGREEMENT_SIGNED', 'AGREEMENT_SIGNED'); } 
public static get AGREEMENT_SIGNED_REVOKED() { return new SpineData$CLIENT_FILE_STATUS('AGREEMENT_SIGNED_REVOKED', 'AGREEMENT_SIGNED_REVOKED'); } 

     public static get DEFAULT_VALUE() { return SpineData$CLIENT_FILE_STATUS.LEAD; }

     public static withIdOption(id: string): SpineData$CLIENT_FILE_STATUS| undefined  {
        try {
            return SpineData$CLIENT_FILE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$CLIENT_FILE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$CLIENT_FILE_STATUS[]  { return [SpineData$CLIENT_FILE_STATUS.PENDING_CANCELLED, SpineData$CLIENT_FILE_STATUS.CANCELLED, SpineData$CLIENT_FILE_STATUS.ACTIVE, SpineData$CLIENT_FILE_STATUS.REJECTED, SpineData$CLIENT_FILE_STATUS.LEAD, SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED, SpineData$CLIENT_FILE_STATUS.HOLD, SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED, SpineData$CLIENT_FILE_STATUS.GRADUATED, SpineData$CLIENT_FILE_STATUS.PARTIALLY_GRADUATED]; }
    public static idList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED']; }
    public static nameList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED']; }


    public static withId(id: string): SpineData$CLIENT_FILE_STATUS  {
        switch (id) {
            case 'HOLD': return SpineData$CLIENT_FILE_STATUS.HOLD; 
case 'AGREEMENT_SIGNED_REVOKED': return SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'GRADUATED': return SpineData$CLIENT_FILE_STATUS.GRADUATED; 
case 'AGREEMENT_SIGNED': return SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'LEAD': return SpineData$CLIENT_FILE_STATUS.LEAD; 
case 'CANCELLED': return SpineData$CLIENT_FILE_STATUS.CANCELLED; 
case 'ACTIVE': return SpineData$CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return SpineData$CLIENT_FILE_STATUS.REJECTED; 
case 'PENDING_CANCELLED': return SpineData$CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'PARTIALLY_GRADUATED': return SpineData$CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
            default: throw new Error('Invalid id for SpineData$CLIENT_FILE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$CLIENT_FILE_STATUS  {
        switch (name) {
            case 'HOLD': return SpineData$CLIENT_FILE_STATUS.HOLD; 
case 'AGREEMENT_SIGNED_REVOKED': return SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'GRADUATED': return SpineData$CLIENT_FILE_STATUS.GRADUATED; 
case 'AGREEMENT_SIGNED': return SpineData$CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'LEAD': return SpineData$CLIENT_FILE_STATUS.LEAD; 
case 'CANCELLED': return SpineData$CLIENT_FILE_STATUS.CANCELLED; 
case 'ACTIVE': return SpineData$CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return SpineData$CLIENT_FILE_STATUS.REJECTED; 
case 'PENDING_CANCELLED': return SpineData$CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'PARTIALLY_GRADUATED': return SpineData$CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
            default: throw new Error('Invalid id for SpineData$CLIENT_FILE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$CLIENT_FILE_STATUS): SpineData$CLIENT_FILE_STATUS  {
            return SpineData$CLIENT_FILE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$CLIENT_FILE_STATUS { return SpineData$CLIENT_FILE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$CLIENT_FILE_STATUS.list().indexOf(this); }
}
 

export class SpineData$NACH_AUTH_MODE {

     public static get N() { return new SpineData$NACH_AUTH_MODE('N', 'Net Banking'); } 
public static get D() { return new SpineData$NACH_AUTH_MODE('D', 'Debit Card'); } 

     public static get DEFAULT_VALUE() { return SpineData$NACH_AUTH_MODE.N; }

     public static withIdOption(id: string): SpineData$NACH_AUTH_MODE| undefined  {
        try {
            return SpineData$NACH_AUTH_MODE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$NACH_AUTH_MODE.withIdOption(id) !== undefined; }
    public static list(): SpineData$NACH_AUTH_MODE[]  { return [SpineData$NACH_AUTH_MODE.N, SpineData$NACH_AUTH_MODE.D]; }
    public static idList(): string[]  { return ['N', 'D']; }
    public static nameList(): string[]  { return ['Net Banking', 'Debit Card']; }


    public static withId(id: string): SpineData$NACH_AUTH_MODE  {
        switch (id) {
            case 'N': return SpineData$NACH_AUTH_MODE.N; 
case 'D': return SpineData$NACH_AUTH_MODE.D; 
            default: throw new Error('Invalid id for SpineData$NACH_AUTH_MODE: ' + id);
        }
    }

    public static withName(name: string): SpineData$NACH_AUTH_MODE  {
        switch (name) {
            case 'Net Banking': return SpineData$NACH_AUTH_MODE.N; 
case 'Debit Card': return SpineData$NACH_AUTH_MODE.D; 
            default: throw new Error('Invalid id for SpineData$NACH_AUTH_MODE: ' + name);
        }
    }

    public static copyMe(given: SpineData$NACH_AUTH_MODE): SpineData$NACH_AUTH_MODE  {
            return SpineData$NACH_AUTH_MODE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$NACH_AUTH_MODE { return SpineData$NACH_AUTH_MODE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$NACH_AUTH_MODE.list().indexOf(this); }
}
 

export class SpineData$GENDER {

     public static get MALE() { return new SpineData$GENDER('MALE', 'MALE'); } 
public static get FEMALE() { return new SpineData$GENDER('FEMALE', 'FEMALE'); } 
public static get OTHER() { return new SpineData$GENDER('OTHER', 'OTHER'); } 

     public static get DEFAULT_VALUE() { return SpineData$GENDER.MALE; }

     public static withIdOption(id: string): SpineData$GENDER| undefined  {
        try {
            return SpineData$GENDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$GENDER.withIdOption(id) !== undefined; }
    public static list(): SpineData$GENDER[]  { return [SpineData$GENDER.MALE, SpineData$GENDER.FEMALE, SpineData$GENDER.OTHER]; }
    public static idList(): string[]  { return ['MALE', 'FEMALE', 'OTHER']; }
    public static nameList(): string[]  { return ['MALE', 'FEMALE', 'OTHER']; }


    public static withId(id: string): SpineData$GENDER  {
        switch (id) {
            case 'MALE': return SpineData$GENDER.MALE; 
case 'FEMALE': return SpineData$GENDER.FEMALE; 
case 'OTHER': return SpineData$GENDER.OTHER; 
            default: throw new Error('Invalid id for SpineData$GENDER: ' + id);
        }
    }

    public static withName(name: string): SpineData$GENDER  {
        switch (name) {
            case 'MALE': return SpineData$GENDER.MALE; 
case 'FEMALE': return SpineData$GENDER.FEMALE; 
case 'OTHER': return SpineData$GENDER.OTHER; 
            default: throw new Error('Invalid id for SpineData$GENDER: ' + name);
        }
    }

    public static copyMe(given: SpineData$GENDER): SpineData$GENDER  {
            return SpineData$GENDER.withId(given.id)
    }

    public static fromJson(json: string): SpineData$GENDER { return SpineData$GENDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$GENDER.list().indexOf(this); }
}
 

export class SpineData$PS_PLAN_STATUS {

     public static get DRAFT() { return new SpineData$PS_PLAN_STATUS('DRAFT', 'DRAFT'); } 
public static get ACTIVE() { return new SpineData$PS_PLAN_STATUS('ACTIVE', 'ACTIVE'); } 
public static get HOLD() { return new SpineData$PS_PLAN_STATUS('HOLD', 'HOLD'); } 
public static get ARCHIVED() { return new SpineData$PS_PLAN_STATUS('ARCHIVED', 'ARCHIVED'); } 

     public static get DEFAULT_VALUE() { return SpineData$PS_PLAN_STATUS.DRAFT; }

     public static withIdOption(id: string): SpineData$PS_PLAN_STATUS| undefined  {
        try {
            return SpineData$PS_PLAN_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$PS_PLAN_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$PS_PLAN_STATUS[]  { return [SpineData$PS_PLAN_STATUS.DRAFT, SpineData$PS_PLAN_STATUS.ACTIVE, SpineData$PS_PLAN_STATUS.HOLD, SpineData$PS_PLAN_STATUS.ARCHIVED]; }
    public static idList(): string[]  { return ['DRAFT', 'ACTIVE', 'HOLD', 'ARCHIVED']; }
    public static nameList(): string[]  { return ['DRAFT', 'ACTIVE', 'HOLD', 'ARCHIVED']; }


    public static withId(id: string): SpineData$PS_PLAN_STATUS  {
        switch (id) {
            case 'DRAFT': return SpineData$PS_PLAN_STATUS.DRAFT; 
case 'ACTIVE': return SpineData$PS_PLAN_STATUS.ACTIVE; 
case 'HOLD': return SpineData$PS_PLAN_STATUS.HOLD; 
case 'ARCHIVED': return SpineData$PS_PLAN_STATUS.ARCHIVED; 
            default: throw new Error('Invalid id for SpineData$PS_PLAN_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$PS_PLAN_STATUS  {
        switch (name) {
            case 'DRAFT': return SpineData$PS_PLAN_STATUS.DRAFT; 
case 'ACTIVE': return SpineData$PS_PLAN_STATUS.ACTIVE; 
case 'HOLD': return SpineData$PS_PLAN_STATUS.HOLD; 
case 'ARCHIVED': return SpineData$PS_PLAN_STATUS.ARCHIVED; 
            default: throw new Error('Invalid id for SpineData$PS_PLAN_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$PS_PLAN_STATUS): SpineData$PS_PLAN_STATUS  {
            return SpineData$PS_PLAN_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$PS_PLAN_STATUS { return SpineData$PS_PLAN_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$PS_PLAN_STATUS.list().indexOf(this); }
}
 

export class SpineData$SERVICE_STATUS {

     public static get NO_SERVICE() { return new SpineData$SERVICE_STATUS('NO_SERVICE', 'NO_SERVICE'); } 
public static get IN_SERVICE() { return new SpineData$SERVICE_STATUS('IN_SERVICE', 'IN_SERVICE'); } 

     public static get DEFAULT_VALUE() { return SpineData$SERVICE_STATUS.NO_SERVICE; }

     public static withIdOption(id: string): SpineData$SERVICE_STATUS| undefined  {
        try {
            return SpineData$SERVICE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$SERVICE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SpineData$SERVICE_STATUS[]  { return [SpineData$SERVICE_STATUS.NO_SERVICE, SpineData$SERVICE_STATUS.IN_SERVICE]; }
    public static idList(): string[]  { return ['NO_SERVICE', 'IN_SERVICE']; }
    public static nameList(): string[]  { return ['NO_SERVICE', 'IN_SERVICE']; }


    public static withId(id: string): SpineData$SERVICE_STATUS  {
        switch (id) {
            case 'NO_SERVICE': return SpineData$SERVICE_STATUS.NO_SERVICE; 
case 'IN_SERVICE': return SpineData$SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SpineData$SERVICE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SpineData$SERVICE_STATUS  {
        switch (name) {
            case 'NO_SERVICE': return SpineData$SERVICE_STATUS.NO_SERVICE; 
case 'IN_SERVICE': return SpineData$SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SpineData$SERVICE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SpineData$SERVICE_STATUS): SpineData$SERVICE_STATUS  {
            return SpineData$SERVICE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SpineData$SERVICE_STATUS { return SpineData$SERVICE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$SERVICE_STATUS.list().indexOf(this); }
}
 

export class SpineData$DOCUMENT_REF_TYPE {

     public static get CLIENT_FILE() { return new SpineData$DOCUMENT_REF_TYPE('CLIENT_FILE', 'CLIENT_FILE'); } 
public static get TICKET() { return new SpineData$DOCUMENT_REF_TYPE('TICKET', 'TICKET'); } 

     public static get DEFAULT_VALUE() { return SpineData$DOCUMENT_REF_TYPE.CLIENT_FILE; }

     public static withIdOption(id: string): SpineData$DOCUMENT_REF_TYPE| undefined  {
        try {
            return SpineData$DOCUMENT_REF_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SpineData$DOCUMENT_REF_TYPE.withIdOption(id) !== undefined; }
    public static list(): SpineData$DOCUMENT_REF_TYPE[]  { return [SpineData$DOCUMENT_REF_TYPE.CLIENT_FILE, SpineData$DOCUMENT_REF_TYPE.TICKET]; }
    public static idList(): string[]  { return ['CLIENT_FILE', 'TICKET']; }
    public static nameList(): string[]  { return ['CLIENT_FILE', 'TICKET']; }


    public static withId(id: string): SpineData$DOCUMENT_REF_TYPE  {
        switch (id) {
            case 'CLIENT_FILE': return SpineData$DOCUMENT_REF_TYPE.CLIENT_FILE; 
case 'TICKET': return SpineData$DOCUMENT_REF_TYPE.TICKET; 
            default: throw new Error('Invalid id for SpineData$DOCUMENT_REF_TYPE: ' + id);
        }
    }

    public static withName(name: string): SpineData$DOCUMENT_REF_TYPE  {
        switch (name) {
            case 'CLIENT_FILE': return SpineData$DOCUMENT_REF_TYPE.CLIENT_FILE; 
case 'TICKET': return SpineData$DOCUMENT_REF_TYPE.TICKET; 
            default: throw new Error('Invalid id for SpineData$DOCUMENT_REF_TYPE: ' + name);
        }
    }

    public static copyMe(given: SpineData$DOCUMENT_REF_TYPE): SpineData$DOCUMENT_REF_TYPE  {
            return SpineData$DOCUMENT_REF_TYPE.withId(given.id)
    }

    public static fromJson(json: string): SpineData$DOCUMENT_REF_TYPE { return SpineData$DOCUMENT_REF_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SpineData$DOCUMENT_REF_TYPE.list().indexOf(this); }
}
 


  


export class SpineData$ActiveTaskAllocated {

   
    public static fromJsonString(jsonString: string): SpineData$ActiveTaskAllocated {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ActiveTaskAllocated.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ActiveTaskAllocated {
        const json: any = o ;
        return new SpineData$ActiveTaskAllocated(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? ValeyData$TaskType.fromJson(json.taskType) : ValeyData$TaskType.DEFAULT_VALUE,
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? ValeyData$TaskState.fromJson(json.taskState) : ValeyData$TaskState.DEFAULT_VALUE,
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? new Date(json.readyTime) : new Date(),
(json.allocatedTime != null) ? new Date(json.allocatedTime) : undefined,
(json.startedTime != null) ? new Date(json.startedTime) : undefined,
(json.endTime != null) ? new Date(json.endTime) : undefined,
(json.lastUserActivityTime != null) ? new Date(json.lastUserActivityTime) : undefined,
(json.taskInput != null) ? json.taskInput : {},
(json.taskOutput != null) ? json.taskOutput : {}
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: ValeyData$TaskType = ValeyData$TaskType.DEFAULT_VALUE,
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: ValeyData$TaskState = ValeyData$TaskState.DEFAULT_VALUE,
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: Date = new Date(),
public allocatedTime: Date|undefined = undefined,
public startedTime: Date|undefined = undefined,
public endTime: Date|undefined = undefined,
public lastUserActivityTime: Date|undefined = undefined,
public taskInput: object = {},
public taskOutput: object = {}
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ?  ValeyData$TaskType.copyMe(this.taskType).toJson() : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ?  ValeyData$TaskState.copyMe(this.taskState).toJson() : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime.toISOString() : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime.toISOString() : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
endTime: (this.endTime != null) ? this.endTime.toISOString() : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class BenchData$CompletedTask {

   
    public static fromJsonString(jsonString: string): BenchData$CompletedTask {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchData$CompletedTask.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchData$CompletedTask {
        const json: any = o ;
        return new BenchData$CompletedTask(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.startedTime != null) ? new Date(json.startedTime) : new Date(),
(json.completedTime != null) ? new Date(json.completedTime) : new Date(),
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutput != null) ? json.taskOutput : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public startedTime: Date = new Date(),
public completedTime: Date = new Date(),
public taskInput: string = '',
public taskOutput: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
completedTime: (this.completedTime != null) ? this.completedTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class SpineData$FiSSASummary {

   
    public static fromJsonString(jsonString: string): SpineData$FiSSASummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiSSASummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiSSASummary {
        const json: any = o ;
        return new SpineData$FiSSASummary(
         (json.clientFile != null) ? SpineData$ClientFileBasicInfo.fromJson(json.clientFile) : new SpineData$ClientFileBasicInfo(),
(json.ssaToken != null) ? json.ssaToken : '',
(json.ipAddr != null) ? json.ipAddr : undefined,
(json.signed != null) ? json.signed : false,
(json.signedOn != null) ? new Date(json.signedOn) : undefined,
(json.generatedOn != null) ? new Date(json.generatedOn) : new Date(),
(json.agreementUrl != null) ? json.agreementUrl : '',
(json.status != null) ? SpineData$AGREEMENT_STATUS.fromJson(json.status) : SpineData$AGREEMENT_STATUS.DEFAULT_VALUE,
(json.previewUrl != null) ? json.previewUrl : ''
        );
    }



  


   
    constructor(
        public clientFile: SpineData$ClientFileBasicInfo = new SpineData$ClientFileBasicInfo(),
public ssaToken: string = '',
public ipAddr: string|undefined = undefined,
public signed: boolean = false,
public signedOn: Date|undefined = undefined,
public generatedOn: Date = new Date(),
public agreementUrl: string = '',
public status: SpineData$AGREEMENT_STATUS = SpineData$AGREEMENT_STATUS.DEFAULT_VALUE,
public previewUrl: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFile: (this.clientFile != null) ? this.clientFile.toJson() : undefined,
ssaToken: (this.ssaToken != null) ? this.ssaToken : undefined,
ipAddr: (this.ipAddr != null) ? this.ipAddr : undefined,
signed: (this.signed != null) ? this.signed : undefined,
signedOn: (this.signedOn != null) ? this.signedOn.toISOString() : undefined,
generatedOn: (this.generatedOn != null) ? this.generatedOn.toISOString() : undefined,
agreementUrl: (this.agreementUrl != null) ? this.agreementUrl : undefined,
status: (this.status != null) ?  SpineData$AGREEMENT_STATUS.copyMe(this.status).toJson() : undefined,
previewUrl: (this.previewUrl != null) ? this.previewUrl : undefined
        };
    }
    



}



export class SpineData$QrCodeStatus {

   
    public static fromJsonString(jsonString: string): SpineData$QrCodeStatus {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$QrCodeStatus.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$QrCodeStatus {
        const json: any = o ;
        return new SpineData$QrCodeStatus(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.amount != null) ? json.amount : 0,
(json.s3Url != null) ? json.s3Url : '',
(json.createdDate != null) ? new Date(json.createdDate) : new Date(),
(json.status != null) ? SpineData$QRCODE_STATUS.fromJson(json.status) : SpineData$QRCODE_STATUS.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public amount: number = 0,
public s3Url: string = '',
public createdDate: Date = new Date(),
public status: SpineData$QRCODE_STATUS = SpineData$QRCODE_STATUS.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
amount: (this.amount != null) ? this.amount : undefined,
s3Url: (this.s3Url != null) ? this.s3Url : undefined,
createdDate: (this.createdDate != null) ? this.createdDate.toISOString() : undefined,
status: (this.status != null) ?  SpineData$QRCODE_STATUS.copyMe(this.status).toJson() : undefined
        };
    }
    



}



export class SpineData$NupayBankMaster {

   
    public static fromJsonString(jsonString: string): SpineData$NupayBankMaster {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$NupayBankMaster.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$NupayBankMaster {
        const json: any = o ;
        return new SpineData$NupayBankMaster(
         (json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.nupayBankId != null) ? json.nupayBankId : 0,
(json.nupayBnkName != null) ? json.nupayBnkName : '',
(json.nupayBankCode != null) ? json.nupayBankCode : undefined
        );
    }



  


   
    constructor(
        public nupayBankMasterId: string = '',
public nupayBankId: number = 0,
public nupayBnkName: string = '',
public nupayBankCode: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
nupayBnkName: (this.nupayBnkName != null) ? this.nupayBnkName : undefined,
nupayBankCode: (this.nupayBankCode != null) ? this.nupayBankCode : undefined
        };
    }
    



}



export class SpineData$GompaUserUserGroupMap {

   
    public static fromJsonString(jsonString: string): SpineData$GompaUserUserGroupMap {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$GompaUserUserGroupMap.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$GompaUserUserGroupMap {
        const json: any = o ;
        return new SpineData$GompaUserUserGroupMap(
         (json.userGroupId != null) ? json.userGroupId : '',
(json.userGroupName != null) ? json.userGroupName : ''
        );
    }



  


   
    constructor(
        public userGroupId: string = '',
public userGroupName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userGroupId: (this.userGroupId != null) ? this.userGroupId : undefined,
userGroupName: (this.userGroupName != null) ? this.userGroupName : undefined
        };
    }
    



}



export class PartnerData$ClientDetails {

   
    public static fromJsonString(jsonString: string): PartnerData$ClientDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$ClientDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$ClientDetails {
        const json: any = o ;
        return new PartnerData$ClientDetails(
         (json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.dateOfBirth != null) ? json.dateOfBirth : undefined,
(json.pan != null) ? json.pan : undefined,
(json.gender != null) ? json.gender : undefined,
(json.alternateContactList != null) ? json.alternateContactList : [],
(json.alternateEmailIdList != null) ? json.alternateEmailIdList : []
        );
    }



  


   
    constructor(
        public maritalStatus: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public dateOfBirth: string|undefined = undefined,
public pan: string|undefined = undefined,
public gender: string|undefined = undefined,
public alternateContactList: string[] = [],
public alternateEmailIdList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
dateOfBirth: (this.dateOfBirth != null) ? this.dateOfBirth : undefined,
pan: (this.pan != null) ? this.pan : undefined,
gender: (this.gender != null) ? this.gender : undefined,
alternateContactList: (this.alternateContactList != null) ? this.alternateContactList : [],
alternateEmailIdList: (this.alternateEmailIdList != null) ? this.alternateEmailIdList : []
        };
    }
    



}



export class SpineData$FiEMandateSummary {

   
    public static fromJsonString(jsonString: string): SpineData$FiEMandateSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiEMandateSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiEMandateSummary {
        const json: any = o ;
        return new SpineData$FiEMandateSummary(
         (json.clientFileBasicInfo != null) ? SpineData$ClientFileBasicInfo.fromJson(json.clientFileBasicInfo) : new SpineData$ClientFileBasicInfo(),
(json.eMandateId != null) ? json.eMandateId : '',
(json.umrn != null) ? json.umrn : undefined,
(json.status != null) ? SpineData$EMANDATE_STATUS.fromJson(json.status) : SpineData$EMANDATE_STATUS.DEFAULT_VALUE,
(json.bankName != null) ? json.bankName : '',
(json.nupayBankId != null) ? json.nupayBankId : 0,
(json.registrationId != null) ? json.registrationId : undefined,
(json.psPlanId != null) ? json.psPlanId : '',
(json.reasonDesc != null) ? json.reasonDesc : undefined,
(json.reasonCode != null) ? json.reasonCode : undefined,
(json.rejectBy != null) ? json.rejectBy : undefined,
(json.npciRefNumber != null) ? json.npciRefNumber : undefined,
(json.eMandateLink != null) ? json.eMandateLink : undefined,
(json.selfEnrolEMandateLink != null) ? json.selfEnrolEMandateLink : undefined,
(json.remoteEMandateId != null) ? json.remoteEMandateId : undefined,
(json.provider != null) ? SpineData$EMANDATE_PROVIDER.fromJson(json.provider) : SpineData$EMANDATE_PROVIDER.DEFAULT_VALUE,
(json.accountNumber != null) ? json.accountNumber : '',
(json.amount != null) ? json.amount : 0,
(json.label != null) ? json.label : '',
(json.isDefault != null) ? json.isDefault : false,
(json.createdOn != null) ? new Date(json.createdOn) : new Date(),
(json.activatedOn != null) ? new Date(json.activatedOn) : undefined,
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.isFirstEMandate != null) ? json.isFirstEMandate : false,
(json.authMode != null) ? SpineData$NACH_AUTH_MODE.fromJson(json.authMode) : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined,
(json.isUPIMandate != null) ? json.isUPIMandate : false,
(json.remoteEMandateDetails != null) ? json.remoteEMandateDetails : undefined
        );
    }



  


   
    constructor(
        public clientFileBasicInfo: SpineData$ClientFileBasicInfo = new SpineData$ClientFileBasicInfo(),
public eMandateId: string = '',
public umrn: string|undefined = undefined,
public status: SpineData$EMANDATE_STATUS = SpineData$EMANDATE_STATUS.DEFAULT_VALUE,
public bankName: string = '',
public nupayBankId: number = 0,
public registrationId: string|undefined = undefined,
public psPlanId: string = '',
public reasonDesc: string|undefined = undefined,
public reasonCode: string|undefined = undefined,
public rejectBy: string|undefined = undefined,
public npciRefNumber: string|undefined = undefined,
public eMandateLink: string|undefined = undefined,
public selfEnrolEMandateLink: string|undefined = undefined,
public remoteEMandateId: string|undefined = undefined,
public provider: SpineData$EMANDATE_PROVIDER = SpineData$EMANDATE_PROVIDER.DEFAULT_VALUE,
public accountNumber: string = '',
public amount: number = 0,
public label: string = '',
public isDefault: boolean = false,
public createdOn: Date = new Date(),
public activatedOn: Date|undefined = undefined,
public ifscCode: string = '',
public accountHolderName: string = '',
public isFirstEMandate: boolean = false,
public authMode: SpineData$NACH_AUTH_MODE|undefined = undefined,
public whoDidIt: string|undefined = undefined,
public isUPIMandate: boolean = false,
public remoteEMandateDetails: object|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileBasicInfo: (this.clientFileBasicInfo != null) ? this.clientFileBasicInfo.toJson() : undefined,
eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
umrn: (this.umrn != null) ? this.umrn : undefined,
status: (this.status != null) ?  SpineData$EMANDATE_STATUS.copyMe(this.status).toJson() : undefined,
bankName: (this.bankName != null) ? this.bankName : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
registrationId: (this.registrationId != null) ? this.registrationId : undefined,
psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
reasonDesc: (this.reasonDesc != null) ? this.reasonDesc : undefined,
reasonCode: (this.reasonCode != null) ? this.reasonCode : undefined,
rejectBy: (this.rejectBy != null) ? this.rejectBy : undefined,
npciRefNumber: (this.npciRefNumber != null) ? this.npciRefNumber : undefined,
eMandateLink: (this.eMandateLink != null) ? this.eMandateLink : undefined,
selfEnrolEMandateLink: (this.selfEnrolEMandateLink != null) ? this.selfEnrolEMandateLink : undefined,
remoteEMandateId: (this.remoteEMandateId != null) ? this.remoteEMandateId : undefined,
provider: (this.provider != null) ?  SpineData$EMANDATE_PROVIDER.copyMe(this.provider).toJson() : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
amount: (this.amount != null) ? this.amount : undefined,
label: (this.label != null) ? this.label : undefined,
isDefault: (this.isDefault != null) ? this.isDefault : undefined,
createdOn: (this.createdOn != null) ? this.createdOn.toISOString() : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn.toISOString() : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
isFirstEMandate: (this.isFirstEMandate != null) ? this.isFirstEMandate : undefined,
authMode: (this.authMode != null) ? SpineData$NACH_AUTH_MODE.copyMe(this.authMode).toJson() : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined,
isUPIMandate: (this.isUPIMandate != null) ? this.isUPIMandate : undefined,
remoteEMandateDetails: (this.remoteEMandateDetails != null) ? this.remoteEMandateDetails : undefined
        };
    }
    



}



export class SpineData$VirtualAccountDetails {

   
    public static fromJsonString(jsonString: string): SpineData$VirtualAccountDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$VirtualAccountDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$VirtualAccountDetails {
        const json: any = o ;
        return new SpineData$VirtualAccountDetails(
         (json.virtualAccountId != null) ? json.virtualAccountId : '',
(json.virtualAccountHolderName != null) ? json.virtualAccountHolderName : '',
(json.virtualAccountRemoteId != null) ? json.virtualAccountRemoteId : undefined,
(json.virtualAccountNumber != null) ? json.virtualAccountNumber : undefined,
(json.virtualAccountBankName != null) ? json.virtualAccountBankName : undefined,
(json.virtualAccountIFSC != null) ? json.virtualAccountIFSC : undefined,
(json.isActive != null) ? json.isActive : false,
(json.failureReason != null) ? json.failureReason : undefined
        );
    }



  


   
    constructor(
        public virtualAccountId: string = '',
public virtualAccountHolderName: string = '',
public virtualAccountRemoteId: string|undefined = undefined,
public virtualAccountNumber: string|undefined = undefined,
public virtualAccountBankName: string|undefined = undefined,
public virtualAccountIFSC: string|undefined = undefined,
public isActive: boolean = false,
public failureReason: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          virtualAccountId: (this.virtualAccountId != null) ? this.virtualAccountId : undefined,
virtualAccountHolderName: (this.virtualAccountHolderName != null) ? this.virtualAccountHolderName : undefined,
virtualAccountRemoteId: (this.virtualAccountRemoteId != null) ? this.virtualAccountRemoteId : undefined,
virtualAccountNumber: (this.virtualAccountNumber != null) ? this.virtualAccountNumber : undefined,
virtualAccountBankName: (this.virtualAccountBankName != null) ? this.virtualAccountBankName : undefined,
virtualAccountIFSC: (this.virtualAccountIFSC != null) ? this.virtualAccountIFSC : undefined,
isActive: (this.isActive != null) ? this.isActive : undefined,
failureReason: (this.failureReason != null) ? this.failureReason : undefined
        };
    }
    



}



export class SpineData$FiBudgetMiscellaneousExpensesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetMiscellaneousExpensesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetMiscellaneousExpensesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetMiscellaneousExpensesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetMiscellaneousExpensesDetails(
         (json.miscellaneousExpense != null) ? json.miscellaneousExpense : undefined
        );
    }



  


   
    constructor(
        public miscellaneousExpense: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          miscellaneousExpense: (this.miscellaneousExpense != null) ? this.miscellaneousExpense : undefined
        };
    }
    



}



export class InsightData$FindParticularPartnerClientOutput {

   
    public static fromJsonString(jsonString: string): InsightData$FindParticularPartnerClientOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$FindParticularPartnerClientOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$FindParticularPartnerClientOutput {
        const json: any = o ;
        return new InsightData$FindParticularPartnerClientOutput(
         (json.mayBePartnerClient != null) ? InsightData$PartnerClientData.fromJson(json.mayBePartnerClient) : undefined,
(json.isPartnerClientDataExist != null) ? json.isPartnerClientDataExist : false
        );
    }



  


   
    constructor(
        public mayBePartnerClient: InsightData$PartnerClientData|undefined = undefined,
public isPartnerClientDataExist: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mayBePartnerClient: (this.mayBePartnerClient != null) ? this.mayBePartnerClient.toJson() : undefined,
isPartnerClientDataExist: (this.isPartnerClientDataExist != null) ? this.isPartnerClientDataExist : undefined
        };
    }
    



}



export class SpineData$FiPaymentPlan {

   
    public static fromJsonString(jsonString: string): SpineData$FiPaymentPlan {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiPaymentPlan.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiPaymentPlan {
        const json: any = o ;
        return new SpineData$FiPaymentPlan(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.psPlanStatus != null) ? SpineData$PS_PLAN_STATUS.fromJson(json.psPlanStatus) : SpineData$PS_PLAN_STATUS.DEFAULT_VALUE,
(json.ppCalculator != null) ? SpineData$FiPaymentCalculator.fromJson(json.ppCalculator) : new SpineData$FiPaymentCalculator(),
(json.psEntryTotalAmount != null) ? json.psEntryTotalAmount : 0,
(json.psEntryTotalPresentedAmount != null) ? json.psEntryTotalPresentedAmount : 0,
(json.psEntryTotalPaidAmount != null) ? json.psEntryTotalPaidAmount : 0,
(json.psEntryTotalSpaAmount != null) ? json.psEntryTotalSpaAmount : 0,
(json.psEntryTotalFeeAmount != null) ? json.psEntryTotalFeeAmount : 0,
(json.paymentScheduleList != null) ? (json.paymentScheduleList as object[]).map((x) => SpineData$FiPSEntry.fromJson(x)) : [],
(json.subscriptionFeeScheduleList != null) ? (json.subscriptionFeeScheduleList as object[]).map((x) => SpineData$FiFeeEntry.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public psPlanId: string = '',
public psPlanStatus: SpineData$PS_PLAN_STATUS = SpineData$PS_PLAN_STATUS.DEFAULT_VALUE,
public ppCalculator: SpineData$FiPaymentCalculator = new SpineData$FiPaymentCalculator(),
public psEntryTotalAmount: number = 0,
public psEntryTotalPresentedAmount: number = 0,
public psEntryTotalPaidAmount: number = 0,
public psEntryTotalSpaAmount: number = 0,
public psEntryTotalFeeAmount: number = 0,
public paymentScheduleList: SpineData$FiPSEntry[] = [],
public subscriptionFeeScheduleList: SpineData$FiFeeEntry[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
psPlanStatus: (this.psPlanStatus != null) ?  SpineData$PS_PLAN_STATUS.copyMe(this.psPlanStatus).toJson() : undefined,
ppCalculator: (this.ppCalculator != null) ? this.ppCalculator.toJson() : undefined,
psEntryTotalAmount: (this.psEntryTotalAmount != null) ? this.psEntryTotalAmount : undefined,
psEntryTotalPresentedAmount: (this.psEntryTotalPresentedAmount != null) ? this.psEntryTotalPresentedAmount : undefined,
psEntryTotalPaidAmount: (this.psEntryTotalPaidAmount != null) ? this.psEntryTotalPaidAmount : undefined,
psEntryTotalSpaAmount: (this.psEntryTotalSpaAmount != null) ? this.psEntryTotalSpaAmount : undefined,
psEntryTotalFeeAmount: (this.psEntryTotalFeeAmount != null) ? this.psEntryTotalFeeAmount : undefined,
paymentScheduleList: (this.paymentScheduleList != null) ? this.paymentScheduleList.map((x) => x.toJson()) : [],
subscriptionFeeScheduleList: (this.subscriptionFeeScheduleList != null) ? this.subscriptionFeeScheduleList.map((x) => x.toJson()) : []
        };
    }
    



}



export class InsightData$PartnerClientConversionFunnelForFpp {

   
    public static fromJsonString(jsonString: string): InsightData$PartnerClientConversionFunnelForFpp {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$PartnerClientConversionFunnelForFpp.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$PartnerClientConversionFunnelForFpp {
        const json: any = o ;
        return new InsightData$PartnerClientConversionFunnelForFpp(
         (json.leadCount != null) ? json.leadCount : 0,
(json.creditReportCount != null) ? json.creditReportCount : 0,
(json.onBoardingFeePaidCount != null) ? json.onBoardingFeePaidCount : 0,
(json.onBoardingFeePaidCountConversionPercentage != null) ? json.onBoardingFeePaidCountConversionPercentage : 0,
(json.eMandateDoneCount != null) ? json.eMandateDoneCount : 0,
(json.eMandateDoneCountConversionPercentage != null) ? json.eMandateDoneCountConversionPercentage : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public creditReportCount: number = 0,
public onBoardingFeePaidCount: number = 0,
public onBoardingFeePaidCountConversionPercentage: number = 0,
public eMandateDoneCount: number = 0,
public eMandateDoneCountConversionPercentage: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
creditReportCount: (this.creditReportCount != null) ? this.creditReportCount : undefined,
onBoardingFeePaidCount: (this.onBoardingFeePaidCount != null) ? this.onBoardingFeePaidCount : undefined,
onBoardingFeePaidCountConversionPercentage: (this.onBoardingFeePaidCountConversionPercentage != null) ? this.onBoardingFeePaidCountConversionPercentage : undefined,
eMandateDoneCount: (this.eMandateDoneCount != null) ? this.eMandateDoneCount : undefined,
eMandateDoneCountConversionPercentage: (this.eMandateDoneCountConversionPercentage != null) ? this.eMandateDoneCountConversionPercentage : undefined
        };
    }
    



}



export class PartnerData$PartnerDocument {

   
    public static fromJsonString(jsonString: string): PartnerData$PartnerDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$PartnerDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$PartnerDocument {
        const json: any = o ;
        return new PartnerData$PartnerDocument(
         (json.documentType != null) ? PartnerData$DOCUMENT_TYPE.fromJson(json.documentType) : PartnerData$DOCUMENT_TYPE.DEFAULT_VALUE,
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.uploadedOn != null) ? new Date(json.uploadedOn) : new Date()
        );
    }



  


   
    constructor(
        public documentType: PartnerData$DOCUMENT_TYPE = PartnerData$DOCUMENT_TYPE.DEFAULT_VALUE,
public documentPath: string = '',
public documentDetails: string|undefined = undefined,
public uploadedOn: Date = new Date()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentType: (this.documentType != null) ?  PartnerData$DOCUMENT_TYPE.copyMe(this.documentType).toJson() : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn.toISOString() : undefined
        };
    }
    



}



export class InsightData$CollectionData {

   
    public static fromJsonString(jsonString: string): InsightData$CollectionData {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$CollectionData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$CollectionData {
        const json: any = o ;
        return new InsightData$CollectionData(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.fppClientRemoteId != null) ? json.fppClientRemoteId : '',
(json.msf != null) ? json.msf : 0,
(json.valueDate != null) ? json.valueDate : '',
(json.txnPurpose != null) ? SpineData$TXN_PURPOSE.fromJson(json.txnPurpose) : SpineData$TXN_PURPOSE.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileNumber: string = '',
public fppClientRemoteId: string = '',
public msf: number = 0,
public valueDate: string = '',
public txnPurpose: SpineData$TXN_PURPOSE = SpineData$TXN_PURPOSE.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
fppClientRemoteId: (this.fppClientRemoteId != null) ? this.fppClientRemoteId : undefined,
msf: (this.msf != null) ? this.msf : undefined,
valueDate: (this.valueDate != null) ? this.valueDate : undefined,
txnPurpose: (this.txnPurpose != null) ?  SpineData$TXN_PURPOSE.copyMe(this.txnPurpose).toJson() : undefined
        };
    }
    



}



export class BenchData$Comment {

   
    public static fromJsonString(jsonString: string): BenchData$Comment {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchData$Comment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchData$Comment {
        const json: any = o ;
        return new BenchData$Comment(
         (json.commentedBy != null) ? json.commentedBy : '',
(json.createdTime != null) ? new Date(json.createdTime) : new Date(),
(json.commentLevel != null) ? PrismData$CommentLevel.fromJson(json.commentLevel) : PrismData$CommentLevel.DEFAULT_VALUE,
(json.comment != null) ? json.comment : '',
(json.taskName != null) ? json.taskName : undefined
        );
    }



  


   
    constructor(
        public commentedBy: string = '',
public createdTime: Date = new Date(),
public commentLevel: PrismData$CommentLevel = PrismData$CommentLevel.DEFAULT_VALUE,
public comment: string = '',
public taskName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          commentedBy: (this.commentedBy != null) ? this.commentedBy : undefined,
createdTime: (this.createdTime != null) ? this.createdTime.toISOString() : undefined,
commentLevel: (this.commentLevel != null) ?  PrismData$CommentLevel.copyMe(this.commentLevel).toJson() : undefined,
comment: (this.comment != null) ? this.comment : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined
        };
    }
    



}



export class PartnerData$ClientFileSummary {

   
    public static fromJsonString(jsonString: string): PartnerData$ClientFileSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$ClientFileSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$ClientFileSummary {
        const json: any = o ;
        return new PartnerData$ClientFileSummary(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFullName != null) ? json.clientFullName : '',
(json.status != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.status) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.unsecuredLoanAmount != null) ? json.unsecuredLoanAmount : 0,
(json.creditScore != null) ? json.creditScore : undefined,
(json.wad != null) ? json.wad : undefined,
(json.city != null) ? json.city : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.createdOn != null) ? new Date(json.createdOn) : new Date()
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFullName: string = '',
public status: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public unsecuredLoanAmount: number = 0,
public creditScore: number|undefined = undefined,
public wad: number|undefined = undefined,
public city: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public createdOn: Date = new Date()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFullName: (this.clientFullName != null) ? this.clientFullName : undefined,
status: (this.status != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.status).toJson() : undefined,
unsecuredLoanAmount: (this.unsecuredLoanAmount != null) ? this.unsecuredLoanAmount : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
wad: (this.wad != null) ? this.wad : undefined,
city: (this.city != null) ? this.city : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
createdOn: (this.createdOn != null) ? this.createdOn.toISOString() : undefined
        };
    }
    



}



export class SpineData$FiBankAddress {

   
    public static fromJsonString(jsonString: string): SpineData$FiBankAddress {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBankAddress.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBankAddress {
        const json: any = o ;
        return new SpineData$FiBankAddress(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class SpineData$ClCreditorCreditReport {

   
    public static fromJsonString(jsonString: string): SpineData$ClCreditorCreditReport {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClCreditorCreditReport.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClCreditorCreditReport {
        const json: any = o ;
        return new SpineData$ClCreditorCreditReport(
         (json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.creditorId != null) ? json.creditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : undefined,
(json.daysDelinquentAsOnOnboarding != null) ? json.daysDelinquentAsOnOnboarding : undefined,
(json.daysPastDue != null) ? json.daysPastDue : undefined,
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.isSettled != null) ? json.isSettled : false,
(json.details != null) ? json.details : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.exceptionTaken != null) ? json.exceptionTaken : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.ineligibleDueToCreditorBalance != null) ? json.ineligibleDueToCreditorBalance : undefined,
(json.ineligibleDueToLastPaymentDate != null) ? json.ineligibleDueToLastPaymentDate : undefined
        );
    }



  


   
    constructor(
        public fiCreditorId: string = '',
public creditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string|undefined = undefined,
public daysDelinquentAsOnOnboarding: number|undefined = undefined,
public daysPastDue: number|undefined = undefined,
public debtType: string = '',
public accountNumber: string = '',
public isSettled: boolean = false,
public details: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public exceptionTaken: boolean|undefined = undefined,
public emiAmount: number|undefined = undefined,
public ineligibleDueToCreditorBalance: boolean|undefined = undefined,
public ineligibleDueToLastPaymentDate: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
creditorId: (this.creditorId != null) ? this.creditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
daysDelinquentAsOnOnboarding: (this.daysDelinquentAsOnOnboarding != null) ? this.daysDelinquentAsOnOnboarding : undefined,
daysPastDue: (this.daysPastDue != null) ? this.daysPastDue : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
isSettled: (this.isSettled != null) ? this.isSettled : undefined,
details: (this.details != null) ? this.details : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
exceptionTaken: (this.exceptionTaken != null) ? this.exceptionTaken : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
ineligibleDueToCreditorBalance: (this.ineligibleDueToCreditorBalance != null) ? this.ineligibleDueToCreditorBalance : undefined,
ineligibleDueToLastPaymentDate: (this.ineligibleDueToLastPaymentDate != null) ? this.ineligibleDueToLastPaymentDate : undefined
        };
    }
    



}



export class SpineData$ExecutiveTaskDetails {

   
    public static fromJsonString(jsonString: string): SpineData$ExecutiveTaskDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ExecutiveTaskDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ExecutiveTaskDetails {
        const json: any = o ;
        return new SpineData$ExecutiveTaskDetails(
         (json.taskName != null) ? json.taskName : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.taskType != null) ? json.taskType : '',
(json.priority != null) ? json.priority : 0,
(json.readyTime != null) ? new Date(json.readyTime) : new Date(),
(json.allocatedTime != null) ? new Date(json.allocatedTime) : undefined,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.startedTime != null) ? new Date(json.startedTime) : undefined,
(json.endTime != null) ? new Date(json.endTime) : undefined,
(json.lastUserActivityTime != null) ? new Date(json.lastUserActivityTime) : undefined,
(json.taskInputSchema != null) ? json.taskInputSchema : '',
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutputSchema != null) ? json.taskOutputSchema : '',
(json.taskOutput != null) ? json.taskOutput : '',
(json.isSuspended != null) ? json.isSuspended : false,
(json.exceptionInfo != null) ? SpineData$TaskExceptionDetails.fromJson(json.exceptionInfo) : undefined
        );
    }



  


   
    constructor(
        public taskName: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public taskType: string = '',
public priority: number = 0,
public readyTime: Date = new Date(),
public allocatedTime: Date|undefined = undefined,
public allocatedTo: string|undefined = undefined,
public startedTime: Date|undefined = undefined,
public endTime: Date|undefined = undefined,
public lastUserActivityTime: Date|undefined = undefined,
public taskInputSchema: string = '',
public taskInput: string = '',
public taskOutputSchema: string = '',
public taskOutput: string = '',
public isSuspended: boolean = false,
public exceptionInfo: SpineData$TaskExceptionDetails|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskName: (this.taskName != null) ? this.taskName : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
priority: (this.priority != null) ? this.priority : undefined,
readyTime: (this.readyTime != null) ? this.readyTime.toISOString() : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime.toISOString() : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
endTime: (this.endTime != null) ? this.endTime.toISOString() : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime.toISOString() : undefined,
taskInputSchema: (this.taskInputSchema != null) ? this.taskInputSchema : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutputSchema: (this.taskOutputSchema != null) ? this.taskOutputSchema : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined,
exceptionInfo: (this.exceptionInfo != null) ? this.exceptionInfo.toJson() : undefined
        };
    }
    



}



export class SpineData$FiBudgetIncidentalExpensesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetIncidentalExpensesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetIncidentalExpensesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetIncidentalExpensesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetIncidentalExpensesDetails(
         (json.healthInsuranceExpense != null) ? json.healthInsuranceExpense : undefined,
(json.vehicleInsuranceExpense != null) ? json.vehicleInsuranceExpense : undefined,
(json.sipExpense != null) ? json.sipExpense : undefined,
(json.stockTradesExpense != null) ? json.stockTradesExpense : undefined,
(json.otherExpense != null) ? json.otherExpense : undefined
        );
    }



  


   
    constructor(
        public healthInsuranceExpense: number|undefined = undefined,
public vehicleInsuranceExpense: number|undefined = undefined,
public sipExpense: number|undefined = undefined,
public stockTradesExpense: number|undefined = undefined,
public otherExpense: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          healthInsuranceExpense: (this.healthInsuranceExpense != null) ? this.healthInsuranceExpense : undefined,
vehicleInsuranceExpense: (this.vehicleInsuranceExpense != null) ? this.vehicleInsuranceExpense : undefined,
sipExpense: (this.sipExpense != null) ? this.sipExpense : undefined,
stockTradesExpense: (this.stockTradesExpense != null) ? this.stockTradesExpense : undefined,
otherExpense: (this.otherExpense != null) ? this.otherExpense : undefined
        };
    }
    



}



export class SpineData$ClAddressDetails {

   
    public static fromJsonString(jsonString: string): SpineData$ClAddressDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClAddressDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClAddressDetails {
        const json: any = o ;
        return new SpineData$ClAddressDetails(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class InsightData$ConversionSummary {

   
    public static fromJsonString(jsonString: string): InsightData$ConversionSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$ConversionSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$ConversionSummary {
        const json: any = o ;
        return new InsightData$ConversionSummary(
         (json.todaysLeadCount != null) ? json.todaysLeadCount : 0,
(json.percentageDifferenceLeads != null) ? json.percentageDifferenceLeads : 0,
(json.todaysOnBoardingDoneConversion != null) ? json.todaysOnBoardingDoneConversion : 0,
(json.todaysEmandateDoneConversion != null) ? json.todaysEmandateDoneConversion : 0,
(json.mtdLeadCount != null) ? json.mtdLeadCount : 0,
(json.mtdOnBoardingDoneConversion != null) ? json.mtdOnBoardingDoneConversion : 0,
(json.mtdEmandateDoneConversion != null) ? json.mtdEmandateDoneConversion : 0,
(json.percentageDifferenceOnBoardingConversion != null) ? json.percentageDifferenceOnBoardingConversion : 0,
(json.percentageDifferenceEmandateConversion != null) ? json.percentageDifferenceEmandateConversion : 0
        );
    }



  


   
    constructor(
        public todaysLeadCount: number = 0,
public percentageDifferenceLeads: number = 0,
public todaysOnBoardingDoneConversion: number = 0,
public todaysEmandateDoneConversion: number = 0,
public mtdLeadCount: number = 0,
public mtdOnBoardingDoneConversion: number = 0,
public mtdEmandateDoneConversion: number = 0,
public percentageDifferenceOnBoardingConversion: number = 0,
public percentageDifferenceEmandateConversion: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          todaysLeadCount: (this.todaysLeadCount != null) ? this.todaysLeadCount : undefined,
percentageDifferenceLeads: (this.percentageDifferenceLeads != null) ? this.percentageDifferenceLeads : undefined,
todaysOnBoardingDoneConversion: (this.todaysOnBoardingDoneConversion != null) ? this.todaysOnBoardingDoneConversion : undefined,
todaysEmandateDoneConversion: (this.todaysEmandateDoneConversion != null) ? this.todaysEmandateDoneConversion : undefined,
mtdLeadCount: (this.mtdLeadCount != null) ? this.mtdLeadCount : undefined,
mtdOnBoardingDoneConversion: (this.mtdOnBoardingDoneConversion != null) ? this.mtdOnBoardingDoneConversion : undefined,
mtdEmandateDoneConversion: (this.mtdEmandateDoneConversion != null) ? this.mtdEmandateDoneConversion : undefined,
percentageDifferenceOnBoardingConversion: (this.percentageDifferenceOnBoardingConversion != null) ? this.percentageDifferenceOnBoardingConversion : undefined,
percentageDifferenceEmandateConversion: (this.percentageDifferenceEmandateConversion != null) ? this.percentageDifferenceEmandateConversion : undefined
        };
    }
    



}



export class SpineData$FiCreditor {

   
    public static fromJsonString(jsonString: string): SpineData$FiCreditor {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiCreditor.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiCreditor {
        const json: any = o ;
        return new SpineData$FiCreditor(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clCreditorId != null) ? json.clCreditorId : '',
(json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.daysDelinquentAsOnOnboarding != null) ? json.daysDelinquentAsOnOnboarding : 0,
(json.daysPastDue != null) ? json.daysPastDue : 0,
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.isSettled != null) ? json.isSettled : false,
(json.details != null) ? json.details : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.estimatedEMIAmount != null) ? json.estimatedEMIAmount : 0,
(json.ineligibleDueToCreditorBalance != null) ? json.ineligibleDueToCreditorBalance : undefined,
(json.ineligibleDueToLastPaymentDate != null) ? json.ineligibleDueToLastPaymentDate : undefined,
(json.settlementAmount != null) ? json.settlementAmount : undefined,
(json.altCreditorName != null) ? json.altCreditorName : undefined,
(json.creditorMasterInfo != null) ? SpineData$CreditorMasterInfo.fromJson(json.creditorMasterInfo) : undefined,
(json.fiCreditorSettlementStatus != null) ? SpineData$FICREDITOR_SETTLEMENT_STATUS.fromJson(json.fiCreditorSettlementStatus) : SpineData$FICREDITOR_SETTLEMENT_STATUS.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clCreditorId: string = '',
public fiCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public daysDelinquentAsOnOnboarding: number = 0,
public daysPastDue: number = 0,
public debtType: string = '',
public accountNumber: string = '',
public isSettled: boolean = false,
public details: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public emiAmount: number|undefined = undefined,
public estimatedEMIAmount: number = 0,
public ineligibleDueToCreditorBalance: boolean|undefined = undefined,
public ineligibleDueToLastPaymentDate: boolean|undefined = undefined,
public settlementAmount: number|undefined = undefined,
public altCreditorName: string|undefined = undefined,
public creditorMasterInfo: SpineData$CreditorMasterInfo|undefined = undefined,
public fiCreditorSettlementStatus: SpineData$FICREDITOR_SETTLEMENT_STATUS = SpineData$FICREDITOR_SETTLEMENT_STATUS.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
daysDelinquentAsOnOnboarding: (this.daysDelinquentAsOnOnboarding != null) ? this.daysDelinquentAsOnOnboarding : undefined,
daysPastDue: (this.daysPastDue != null) ? this.daysPastDue : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
isSettled: (this.isSettled != null) ? this.isSettled : undefined,
details: (this.details != null) ? this.details : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
estimatedEMIAmount: (this.estimatedEMIAmount != null) ? this.estimatedEMIAmount : undefined,
ineligibleDueToCreditorBalance: (this.ineligibleDueToCreditorBalance != null) ? this.ineligibleDueToCreditorBalance : undefined,
ineligibleDueToLastPaymentDate: (this.ineligibleDueToLastPaymentDate != null) ? this.ineligibleDueToLastPaymentDate : undefined,
settlementAmount: (this.settlementAmount != null) ? this.settlementAmount : undefined,
altCreditorName: (this.altCreditorName != null) ? this.altCreditorName : undefined,
creditorMasterInfo: (this.creditorMasterInfo != null) ? this.creditorMasterInfo.toJson() : undefined,
fiCreditorSettlementStatus: (this.fiCreditorSettlementStatus != null) ?  SpineData$FICREDITOR_SETTLEMENT_STATUS.copyMe(this.fiCreditorSettlementStatus).toJson() : undefined
        };
    }
    



}



export class SpineData$FiNote {

   
    public static fromJsonString(jsonString: string): SpineData$FiNote {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiNote.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiNote {
        const json: any = o ;
        return new SpineData$FiNote(
         (json.noteId != null) ? json.noteId : '',
(json.noteMesssage != null) ? json.noteMesssage : '',
(json.tagList != null) ? (json.tagList as object[]).map((x) => SpineData$FiNoteTag.fromJson(x)) : [],
(json.createdOn != null) ? new Date(json.createdOn) : new Date(),
(json.createdBy != null) ? json.createdBy : '',
(json.updatedBy != null) ? json.updatedBy : undefined
        );
    }



  


   
    constructor(
        public noteId: string = '',
public noteMesssage: string = '',
public tagList: SpineData$FiNoteTag[] = [],
public createdOn: Date = new Date(),
public createdBy: string = '',
public updatedBy: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
noteMesssage: (this.noteMesssage != null) ? this.noteMesssage : undefined,
tagList: (this.tagList != null) ? this.tagList.map((x) => x.toJson()) : [],
createdOn: (this.createdOn != null) ? this.createdOn.toISOString() : undefined,
createdBy: (this.createdBy != null) ? this.createdBy : undefined,
updatedBy: (this.updatedBy != null) ? this.updatedBy : undefined
        };
    }
    



}



export class SpineData$EMandateBankInfo {

   
    public static fromJsonString(jsonString: string): SpineData$EMandateBankInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$EMandateBankInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$EMandateBankInfo {
        const json: any = o ;
        return new SpineData$EMandateBankInfo(
         (json.nupayBankId != null) ? json.nupayBankId : 0,
(json.accountNumber != null) ? json.accountNumber : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.accountType != null) ? SpineData$ACCOUNT_TYPE.fromJson(json.accountType) : SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
(json.ifscCode != null) ? json.ifscCode : ''
        );
    }



  


   
    constructor(
        public nupayBankId: number = 0,
public accountNumber: string = '',
public accountHolderName: string = '',
public accountType: SpineData$ACCOUNT_TYPE = SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
public ifscCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
accountType: (this.accountType != null) ?  SpineData$ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined
        };
    }
    



}



export class InsightData$PartnerClientData {

   
    public static fromJsonString(jsonString: string): InsightData$PartnerClientData {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$PartnerClientData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$PartnerClientData {
        const json: any = o ;
        return new InsightData$PartnerClientData(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.createdOn != null) ? json.createdOn : '',
(json.totalMSFPaid != null) ? json.totalMSFPaid : 0,
(json.lastMSFPaidAt != null) ? new Date(json.lastMSFPaidAt) : undefined,
(json.orgCode != null) ? json.orgCode : '',
(json.clientFileStatus != null) ? json.clientFileStatus : '',
(json.fullName != null) ? json.fullName : '',
(json.mobile != null) ? json.mobile : '',
(json.email != null) ? json.email : '',
(json.isAgreementDone != null) ? json.isAgreementDone : false,
(json.firstMSFPaid != null) ? json.firstMSFPaid : false,
(json.isEmandateDone != null) ? json.isEmandateDone : false,
(json.onBoardingFeePaid != null) ? json.onBoardingFeePaid : false,
(json.wad != null) ? json.wad : 0,
(json.creditScore != null) ? json.creditScore : 0,
(json.tenor != null) ? json.tenor : 0,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.onBoardingFeeAmount != null) ? json.onBoardingFeeAmount : 0,
(json.totalCurrentOutstanding != null) ? json.totalCurrentOutstanding : 0,
(json.onBoardingDate != null) ? json.onBoardingDate : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public createdOn: string = '',
public totalMSFPaid: number = 0,
public lastMSFPaidAt: Date|undefined = undefined,
public orgCode: string = '',
public clientFileStatus: string = '',
public fullName: string = '',
public mobile: string = '',
public email: string = '',
public isAgreementDone: boolean = false,
public firstMSFPaid: boolean = false,
public isEmandateDone: boolean = false,
public onBoardingFeePaid: boolean = false,
public wad: number = 0,
public creditScore: number = 0,
public tenor: number = 0,
public msfDraftAmount: number = 0,
public onBoardingFeeAmount: number = 0,
public totalCurrentOutstanding: number = 0,
public onBoardingDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
createdOn: (this.createdOn != null) ? this.createdOn : undefined,
totalMSFPaid: (this.totalMSFPaid != null) ? this.totalMSFPaid : undefined,
lastMSFPaidAt: (this.lastMSFPaidAt != null) ? this.lastMSFPaidAt.toISOString() : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined,
clientFileStatus: (this.clientFileStatus != null) ? this.clientFileStatus : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
email: (this.email != null) ? this.email : undefined,
isAgreementDone: (this.isAgreementDone != null) ? this.isAgreementDone : undefined,
firstMSFPaid: (this.firstMSFPaid != null) ? this.firstMSFPaid : undefined,
isEmandateDone: (this.isEmandateDone != null) ? this.isEmandateDone : undefined,
onBoardingFeePaid: (this.onBoardingFeePaid != null) ? this.onBoardingFeePaid : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
onBoardingFeeAmount: (this.onBoardingFeeAmount != null) ? this.onBoardingFeeAmount : undefined,
totalCurrentOutstanding: (this.totalCurrentOutstanding != null) ? this.totalCurrentOutstanding : undefined,
onBoardingDate: (this.onBoardingDate != null) ? this.onBoardingDate : undefined
        };
    }
    



}



export class SpineData$FiBudgetIneligibleUnsecuredDebts {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetIneligibleUnsecuredDebts {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetIneligibleUnsecuredDebts.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetIneligibleUnsecuredDebts {
        const json: any = o ;
        return new SpineData$FiBudgetIneligibleUnsecuredDebts(
         (json.personalLoanDebt != null) ? json.personalLoanDebt : 0,
(json.creditCardDebt != null) ? json.creditCardDebt : 0
        );
    }



  


   
    constructor(
        public personalLoanDebt: number = 0,
public creditCardDebt: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          personalLoanDebt: (this.personalLoanDebt != null) ? this.personalLoanDebt : undefined,
creditCardDebt: (this.creditCardDebt != null) ? this.creditCardDebt : undefined
        };
    }
    



}



export class InsightData$MonthlyFunnel {

   
    public static fromJsonString(jsonString: string): InsightData$MonthlyFunnel {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$MonthlyFunnel.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$MonthlyFunnel {
        const json: any = o ;
        return new InsightData$MonthlyFunnel(
         (json.leadCount != null) ? json.leadCount : 0,
(json.conversionCount != null) ? json.conversionCount : 0,
(json.mmYY != null) ? json.mmYY : '',
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public conversionCount: number = 0,
public mmYY: string = '',
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
conversionCount: (this.conversionCount != null) ? this.conversionCount : undefined,
mmYY: (this.mmYY != null) ? this.mmYY : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    



}



export class SpineData$FiNoteTag {

   
    public static fromJsonString(jsonString: string): SpineData$FiNoteTag {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiNoteTag.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiNoteTag {
        const json: any = o ;
        return new SpineData$FiNoteTag(
         (json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }



  


   
    constructor(
        public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    



}



export class InsightData$Demography {

   
    public static fromJsonString(jsonString: string): InsightData$Demography {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$Demography.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$Demography {
        const json: any = o ;
        return new InsightData$Demography(
         (json.state != null) ? json.state : '',
(json.ccDebtCount != null) ? json.ccDebtCount : 0,
(json.plDebtCount != null) ? json.plDebtCount : 0,
(json.otherDebtCount != null) ? json.otherDebtCount : 0,
(json.customerCount != null) ? json.customerCount : 0
        );
    }



  


   
    constructor(
        public state: string = '',
public ccDebtCount: number = 0,
public plDebtCount: number = 0,
public otherDebtCount: number = 0,
public customerCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          state: (this.state != null) ? this.state : undefined,
ccDebtCount: (this.ccDebtCount != null) ? this.ccDebtCount : undefined,
plDebtCount: (this.plDebtCount != null) ? this.plDebtCount : undefined,
otherDebtCount: (this.otherDebtCount != null) ? this.otherDebtCount : undefined,
customerCount: (this.customerCount != null) ? this.customerCount : undefined
        };
    }
    



}



export class SpineData$CreditReportPersonalInfo {

   
    public static fromJsonString(jsonString: string): SpineData$CreditReportPersonalInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$CreditReportPersonalInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$CreditReportPersonalInfo {
        const json: any = o ;
        return new SpineData$CreditReportPersonalInfo(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : '',
(json.address != null) ? json.address : '',
(json.pan != null) ? json.pan : '',
(json.gender != null) ? json.gender : '',
(json.mobile != null) ? json.mobile : '',
(json.creditScore != null) ? json.creditScore : '',
(json.bureau != null) ? SpineData$CREDIT_BUREAU.fromJson(json.bureau) : SpineData$CREDIT_BUREAU.DEFAULT_VALUE,
(json.fileName != null) ? json.fileName : undefined,
(json.documentDetails != null) ? json.documentDetails : undefined
        );
    }



  


   
    constructor(
        public firstName: string = '',
public lastName: string = '',
public address: string = '',
public pan: string = '',
public gender: string = '',
public mobile: string = '',
public creditScore: string = '',
public bureau: SpineData$CREDIT_BUREAU = SpineData$CREDIT_BUREAU.DEFAULT_VALUE,
public fileName: string|undefined = undefined,
public documentDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
address: (this.address != null) ? this.address : undefined,
pan: (this.pan != null) ? this.pan : undefined,
gender: (this.gender != null) ? this.gender : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
bureau: (this.bureau != null) ?  SpineData$CREDIT_BUREAU.copyMe(this.bureau).toJson() : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    



}



export class SpineData$FiBudgetLivingExpensesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetLivingExpensesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetLivingExpensesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetLivingExpensesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetLivingExpensesDetails(
         (json.rentMaintenance != null) ? json.rentMaintenance : undefined,
(json.groceryExpense != null) ? json.groceryExpense : undefined,
(json.electricityBill != null) ? json.electricityBill : undefined,
(json.gasBill != null) ? json.gasBill : undefined,
(json.phoneBill != null) ? json.phoneBill : undefined,
(json.otherUtilities != null) ? json.otherUtilities : undefined
        );
    }



  


   
    constructor(
        public rentMaintenance: number|undefined = undefined,
public groceryExpense: number|undefined = undefined,
public electricityBill: number|undefined = undefined,
public gasBill: number|undefined = undefined,
public phoneBill: number|undefined = undefined,
public otherUtilities: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          rentMaintenance: (this.rentMaintenance != null) ? this.rentMaintenance : undefined,
groceryExpense: (this.groceryExpense != null) ? this.groceryExpense : undefined,
electricityBill: (this.electricityBill != null) ? this.electricityBill : undefined,
gasBill: (this.gasBill != null) ? this.gasBill : undefined,
phoneBill: (this.phoneBill != null) ? this.phoneBill : undefined,
otherUtilities: (this.otherUtilities != null) ? this.otherUtilities : undefined
        };
    }
    



}



export class SpineData$FiFeeEntry {

   
    public static fromJsonString(jsonString: string): SpineData$FiFeeEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiFeeEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiFeeEntry {
        const json: any = o ;
        return new SpineData$FiFeeEntry(
         (json.msfEntryId != null) ? json.msfEntryId : '',
(json.status != null) ? SpineData$MSF_ENTRY_STATUS.fromJson(json.status) : SpineData$MSF_ENTRY_STATUS.DEFAULT_VALUE,
(json.paymentId != null) ? json.paymentId : undefined,
(json.paymentStatus != null) ? SpineData$PAYMENT_STATUS.fromJson(json.paymentStatus) : undefined,
(json.draftDate != null) ? json.draftDate : '',
(json.feeCode != null) ? json.feeCode : '',
(json.amount != null) ? json.amount : 0,
(json.statusUpdatedOn != null) ? new Date(json.statusUpdatedOn) : new Date(),
(json.linkedEMandate != null) ? SpineData$FiEMandateSummary.fromJson(json.linkedEMandate) : undefined
        );
    }



  


   
    constructor(
        public msfEntryId: string = '',
public status: SpineData$MSF_ENTRY_STATUS = SpineData$MSF_ENTRY_STATUS.DEFAULT_VALUE,
public paymentId: string|undefined = undefined,
public paymentStatus: SpineData$PAYMENT_STATUS|undefined = undefined,
public draftDate: string = '',
public feeCode: string = '',
public amount: number = 0,
public statusUpdatedOn: Date = new Date(),
public linkedEMandate: SpineData$FiEMandateSummary|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          msfEntryId: (this.msfEntryId != null) ? this.msfEntryId : undefined,
status: (this.status != null) ?  SpineData$MSF_ENTRY_STATUS.copyMe(this.status).toJson() : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined,
paymentStatus: (this.paymentStatus != null) ? SpineData$PAYMENT_STATUS.copyMe(this.paymentStatus).toJson() : undefined,
draftDate: (this.draftDate != null) ? this.draftDate : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
amount: (this.amount != null) ? this.amount : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn.toISOString() : undefined,
linkedEMandate: (this.linkedEMandate != null) ? this.linkedEMandate.toJson() : undefined
        };
    }
    



}



export class SpineData$TaskListByCid {

   
    public static fromJsonString(jsonString: string): SpineData$TaskListByCid {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$TaskListByCid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$TaskListByCid {
        const json: any = o ;
        return new SpineData$TaskListByCid(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? ValeyData$TaskType.fromJson(json.taskType) : ValeyData$TaskType.DEFAULT_VALUE,
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? ValeyData$TaskState.fromJson(json.taskState) : ValeyData$TaskState.DEFAULT_VALUE,
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? new Date(json.readyTime) : new Date(),
(json.createdTime != null) ? new Date(json.createdTime) : undefined,
(json.allocatedTime != null) ? new Date(json.allocatedTime) : undefined,
(json.startedTime != null) ? new Date(json.startedTime) : undefined,
(json.endTime != null) ? new Date(json.endTime) : undefined,
(json.lastUserActivityTime != null) ? new Date(json.lastUserActivityTime) : undefined,
(json.taskInput != null) ? json.taskInput : {},
(json.taskOutput != null) ? json.taskOutput : {},
(json.lastUpdatedTime != null) ? new Date(json.lastUpdatedTime) : new Date(),
(json.clientCFBasicInfo != null) ? SpineData$ClientCFBasicInfo.fromJson(json.clientCFBasicInfo) : undefined,
(json.scheduledTime != null) ? new Date(json.scheduledTime) : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: ValeyData$TaskType = ValeyData$TaskType.DEFAULT_VALUE,
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: ValeyData$TaskState = ValeyData$TaskState.DEFAULT_VALUE,
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: Date = new Date(),
public createdTime: Date|undefined = undefined,
public allocatedTime: Date|undefined = undefined,
public startedTime: Date|undefined = undefined,
public endTime: Date|undefined = undefined,
public lastUserActivityTime: Date|undefined = undefined,
public taskInput: object = {},
public taskOutput: object = {},
public lastUpdatedTime: Date = new Date(),
public clientCFBasicInfo: SpineData$ClientCFBasicInfo|undefined = undefined,
public scheduledTime: Date|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ?  ValeyData$TaskType.copyMe(this.taskType).toJson() : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ?  ValeyData$TaskState.copyMe(this.taskState).toJson() : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime.toISOString() : undefined,
createdTime: (this.createdTime != null) ? this.createdTime.toISOString() : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime.toISOString() : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
endTime: (this.endTime != null) ? this.endTime.toISOString() : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined,
lastUpdatedTime: (this.lastUpdatedTime != null) ? this.lastUpdatedTime.toISOString() : undefined,
clientCFBasicInfo: (this.clientCFBasicInfo != null) ? this.clientCFBasicInfo.toJson() : undefined,
scheduledTime: (this.scheduledTime != null) ? this.scheduledTime.toISOString() : undefined
        };
    }
    



}



export class SpineData$FiCreditorInfo {

   
    public static fromJsonString(jsonString: string): SpineData$FiCreditorInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiCreditorInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiCreditorInfo {
        const json: any = o ;
        return new SpineData$FiCreditorInfo(
         (json.totalDebt != null) ? json.totalDebt : 0,
(json.fiCreditorList != null) ? (json.fiCreditorList as object[]).map((x) => SpineData$FiCreditor.fromJson(x)) : [],
(json.totalUnsecuredDebt != null) ? json.totalUnsecuredDebt : 0,
(json.ineligibleUnsecuredDebt != null) ? SpineData$IneligibleUnsecuredDebt.fromJson(json.ineligibleUnsecuredDebt) : new SpineData$IneligibleUnsecuredDebt()
        );
    }



  


   
    constructor(
        public totalDebt: number = 0,
public fiCreditorList: SpineData$FiCreditor[] = [],
public totalUnsecuredDebt: number = 0,
public ineligibleUnsecuredDebt: SpineData$IneligibleUnsecuredDebt = new SpineData$IneligibleUnsecuredDebt()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined,
fiCreditorList: (this.fiCreditorList != null) ? this.fiCreditorList.map((x) => x.toJson()) : [],
totalUnsecuredDebt: (this.totalUnsecuredDebt != null) ? this.totalUnsecuredDebt : undefined,
ineligibleUnsecuredDebt: (this.ineligibleUnsecuredDebt != null) ? this.ineligibleUnsecuredDebt.toJson() : undefined
        };
    }
    



}



export class BenchData$StartedTask {

   
    public static fromJsonString(jsonString: string): BenchData$StartedTask {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchData$StartedTask.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchData$StartedTask {
        const json: any = o ;
        return new BenchData$StartedTask(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.priority != null) ? json.priority : 0,
(json.taskState != null) ? json.taskState : '',
(json.startedTime != null) ? new Date(json.startedTime) : new Date(),
(json.lastUserActivityTime != null) ? new Date(json.lastUserActivityTime) : new Date(),
(json.taskInput != null) ? json.taskInput : '',
(json.isSuspended != null) ? json.isSuspended : false
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public priority: number = 0,
public taskState: string = '',
public startedTime: Date = new Date(),
public lastUserActivityTime: Date = new Date(),
public taskInput: string = '',
public isSuspended: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
priority: (this.priority != null) ? this.priority : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined
        };
    }
    



}



export class SpineData$MISAccountBalanceEntry {

   
    public static fromJsonString(jsonString: string): SpineData$MISAccountBalanceEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$MISAccountBalanceEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$MISAccountBalanceEntry {
        const json: any = o ;
        return new SpineData$MISAccountBalanceEntry(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientName != null) ? json.clientName : '',
(json.mobile != null) ? json.mobile : '',
(json.numberOfSPATxn != null) ? json.numberOfSPATxn : 0,
(json.totalSPACollected != null) ? json.totalSPACollected : 0,
(json.numberOfMSFTxn != null) ? json.numberOfMSFTxn : 0,
(json.totalMSFCollected != null) ? json.totalMSFCollected : 0,
(json.numberOfSettlmentTxn != null) ? json.numberOfSettlmentTxn : 0,
(json.totalSettlementAmt != null) ? json.totalSettlementAmt : 0,
(json.numberOfRefundTxn != null) ? json.numberOfRefundTxn : 0,
(json.totalRefundAmt != null) ? json.totalRefundAmt : 0,
(json.numberOfKFSFeeTxn != null) ? json.numberOfKFSFeeTxn : 0,
(json.totalKFSFee != null) ? json.totalKFSFee : 0,
(json.totalInTxnSum != null) ? json.totalInTxnSum : 0,
(json.totalOutTxnSum != null) ? json.totalOutTxnSum : 0,
(json.accountBalance != null) ? json.accountBalance : 0
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientName: string = '',
public mobile: string = '',
public numberOfSPATxn: number = 0,
public totalSPACollected: number = 0,
public numberOfMSFTxn: number = 0,
public totalMSFCollected: number = 0,
public numberOfSettlmentTxn: number = 0,
public totalSettlementAmt: number = 0,
public numberOfRefundTxn: number = 0,
public totalRefundAmt: number = 0,
public numberOfKFSFeeTxn: number = 0,
public totalKFSFee: number = 0,
public totalInTxnSum: number = 0,
public totalOutTxnSum: number = 0,
public accountBalance: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientName: (this.clientName != null) ? this.clientName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
numberOfSPATxn: (this.numberOfSPATxn != null) ? this.numberOfSPATxn : undefined,
totalSPACollected: (this.totalSPACollected != null) ? this.totalSPACollected : undefined,
numberOfMSFTxn: (this.numberOfMSFTxn != null) ? this.numberOfMSFTxn : undefined,
totalMSFCollected: (this.totalMSFCollected != null) ? this.totalMSFCollected : undefined,
numberOfSettlmentTxn: (this.numberOfSettlmentTxn != null) ? this.numberOfSettlmentTxn : undefined,
totalSettlementAmt: (this.totalSettlementAmt != null) ? this.totalSettlementAmt : undefined,
numberOfRefundTxn: (this.numberOfRefundTxn != null) ? this.numberOfRefundTxn : undefined,
totalRefundAmt: (this.totalRefundAmt != null) ? this.totalRefundAmt : undefined,
numberOfKFSFeeTxn: (this.numberOfKFSFeeTxn != null) ? this.numberOfKFSFeeTxn : undefined,
totalKFSFee: (this.totalKFSFee != null) ? this.totalKFSFee : undefined,
totalInTxnSum: (this.totalInTxnSum != null) ? this.totalInTxnSum : undefined,
totalOutTxnSum: (this.totalOutTxnSum != null) ? this.totalOutTxnSum : undefined,
accountBalance: (this.accountBalance != null) ? this.accountBalance : undefined
        };
    }
    



}



export class PartnerData$NotEnrolledPartnerClient {

   
    public static fromJsonString(jsonString: string): PartnerData$NotEnrolledPartnerClient {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$NotEnrolledPartnerClient.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$NotEnrolledPartnerClient {
        const json: any = o ;
        return new PartnerData$NotEnrolledPartnerClient(
         (json.name != null) ? json.name : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.registeredOn != null) ? json.registeredOn : '',
(json.fppClientRemoteId != null) ? json.fppClientRemoteId : undefined
        );
    }



  


   
    constructor(
        public name: string = '',
public emailId: string = '',
public mobile: string = '',
public registeredOn: string = '',
public fppClientRemoteId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          name: (this.name != null) ? this.name : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
registeredOn: (this.registeredOn != null) ? this.registeredOn : undefined,
fppClientRemoteId: (this.fppClientRemoteId != null) ? this.fppClientRemoteId : undefined
        };
    }
    



}



export class SpineData$FiPaymentTransaction {

   
    public static fromJsonString(jsonString: string): SpineData$FiPaymentTransaction {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiPaymentTransaction.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiPaymentTransaction {
        const json: any = o ;
        return new SpineData$FiPaymentTransaction(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.accountIdentifier != null) ? json.accountIdentifier : '',
(json.debit != null) ? json.debit : false,
(json.amount != null) ? json.amount : 0,
(json.paymentRefNumber != null) ? json.paymentRefNumber : undefined,
(json.remoteTxnRefNumber != null) ? json.remoteTxnRefNumber : undefined,
(json.txnDate != null) ? json.txnDate : '',
(json.intent != null) ? json.intent : '',
(json.description != null) ? json.description : undefined
        );
    }



  


   
    constructor(
        public clientFileNumber: string = '',
public accountIdentifier: string = '',
public debit: boolean = false,
public amount: number = 0,
public paymentRefNumber: string|undefined = undefined,
public remoteTxnRefNumber: string|undefined = undefined,
public txnDate: string = '',
public intent: string = '',
public description: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
accountIdentifier: (this.accountIdentifier != null) ? this.accountIdentifier : undefined,
debit: (this.debit != null) ? this.debit : undefined,
amount: (this.amount != null) ? this.amount : undefined,
paymentRefNumber: (this.paymentRefNumber != null) ? this.paymentRefNumber : undefined,
remoteTxnRefNumber: (this.remoteTxnRefNumber != null) ? this.remoteTxnRefNumber : undefined,
txnDate: (this.txnDate != null) ? this.txnDate : undefined,
intent: (this.intent != null) ? this.intent : undefined,
description: (this.description != null) ? this.description : undefined
        };
    }
    



}



export class SpineData$ClientFileEnrollmentSummary {

   
    public static fromJsonString(jsonString: string): SpineData$ClientFileEnrollmentSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientFileEnrollmentSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientFileEnrollmentSummary {
        const json: any = o ;
        return new SpineData$ClientFileEnrollmentSummary(
         (json.clientId != null) ? json.clientId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.retainedClientFileNumber != null) ? json.retainedClientFileNumber : undefined,
(json.clientFileStatus != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.enrollmentSummary != null) ? SpineData$EnrollmentSummary.fromJson(json.enrollmentSummary) : new SpineData$EnrollmentSummary(),
(json.fiCreditorInfo != null) ? SpineData$FiCreditorInfo.fromJson(json.fiCreditorInfo) : undefined,
(json.fiBudgetInfo != null) ? SpineData$FiBudgetInfo.fromJson(json.fiBudgetInfo) : undefined,
(json.fiPaymentPlan != null) ? SpineData$FiPaymentPlan.fromJson(json.fiPaymentPlan) : undefined,
(json.ssa != null) ? SpineData$FiSSASummary.fromJson(json.ssa) : undefined,
(json.eMandate != null) ? SpineData$FiEMandateSummary.fromJson(json.eMandate) : undefined,
(json.firstMSFCfPayment != null) ? SpineData$FiPayment.fromJson(json.firstMSFCfPayment) : undefined,
(json.clientBasicInfo != null) ? SpineData$ClientBasicInfo.fromJson(json.clientBasicInfo) : new SpineData$ClientBasicInfo(),
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.programCode != null) ? SpineData$PROGRAM_CODE.fromJson(json.programCode) : undefined,
(json.consentTaken != null) ? json.consentTaken : undefined,
(json.paymentAggregator != null) ? SpineData$PAYMENT_AGGREGATOR.fromJson(json.paymentAggregator) : SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE,
(json.programDetails != null) ? SpineData$ProgramDetails.fromJson(json.programDetails) : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public clientFileId: string = '',
public clientFileNumber: string = '',
public retainedClientFileNumber: string|undefined = undefined,
public clientFileStatus: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public enrollmentSummary: SpineData$EnrollmentSummary = new SpineData$EnrollmentSummary(),
public fiCreditorInfo: SpineData$FiCreditorInfo|undefined = undefined,
public fiBudgetInfo: SpineData$FiBudgetInfo|undefined = undefined,
public fiPaymentPlan: SpineData$FiPaymentPlan|undefined = undefined,
public ssa: SpineData$FiSSASummary|undefined = undefined,
public eMandate: SpineData$FiEMandateSummary|undefined = undefined,
public firstMSFCfPayment: SpineData$FiPayment|undefined = undefined,
public clientBasicInfo: SpineData$ClientBasicInfo = new SpineData$ClientBasicInfo(),
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public programCode: SpineData$PROGRAM_CODE|undefined = undefined,
public consentTaken: boolean|undefined = undefined,
public paymentAggregator: SpineData$PAYMENT_AGGREGATOR = SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE,
public programDetails: SpineData$ProgramDetails|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
retainedClientFileNumber: (this.retainedClientFileNumber != null) ? this.retainedClientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
enrollmentSummary: (this.enrollmentSummary != null) ? this.enrollmentSummary.toJson() : undefined,
fiCreditorInfo: (this.fiCreditorInfo != null) ? this.fiCreditorInfo.toJson() : undefined,
fiBudgetInfo: (this.fiBudgetInfo != null) ? this.fiBudgetInfo.toJson() : undefined,
fiPaymentPlan: (this.fiPaymentPlan != null) ? this.fiPaymentPlan.toJson() : undefined,
ssa: (this.ssa != null) ? this.ssa.toJson() : undefined,
eMandate: (this.eMandate != null) ? this.eMandate.toJson() : undefined,
firstMSFCfPayment: (this.firstMSFCfPayment != null) ? this.firstMSFCfPayment.toJson() : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
programCode: (this.programCode != null) ? SpineData$PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined,
consentTaken: (this.consentTaken != null) ? this.consentTaken : undefined,
paymentAggregator: (this.paymentAggregator != null) ?  SpineData$PAYMENT_AGGREGATOR.copyMe(this.paymentAggregator).toJson() : undefined,
programDetails: (this.programDetails != null) ? this.programDetails.toJson() : undefined
        };
    }
    



}



export class SpineData$FiBudgetMiscellaneousExpenses {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetMiscellaneousExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetMiscellaneousExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetMiscellaneousExpenses {
        const json: any = o ;
        return new SpineData$FiBudgetMiscellaneousExpenses(
         (json.miscellaneousExpense != null) ? json.miscellaneousExpense : 0
        );
    }



  


   
    constructor(
        public miscellaneousExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          miscellaneousExpense: (this.miscellaneousExpense != null) ? this.miscellaneousExpense : undefined
        };
    }
    



}



export class SpineData$FiBudgetLivingExpenses {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetLivingExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetLivingExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetLivingExpenses {
        const json: any = o ;
        return new SpineData$FiBudgetLivingExpenses(
         (json.rentMaintenance != null) ? json.rentMaintenance : 0,
(json.groceryExpense != null) ? json.groceryExpense : 0,
(json.electricityBill != null) ? json.electricityBill : 0,
(json.gasBill != null) ? json.gasBill : 0,
(json.phoneBill != null) ? json.phoneBill : 0,
(json.otherUtilities != null) ? json.otherUtilities : 0
        );
    }



  


   
    constructor(
        public rentMaintenance: number = 0,
public groceryExpense: number = 0,
public electricityBill: number = 0,
public gasBill: number = 0,
public phoneBill: number = 0,
public otherUtilities: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          rentMaintenance: (this.rentMaintenance != null) ? this.rentMaintenance : undefined,
groceryExpense: (this.groceryExpense != null) ? this.groceryExpense : undefined,
electricityBill: (this.electricityBill != null) ? this.electricityBill : undefined,
gasBill: (this.gasBill != null) ? this.gasBill : undefined,
phoneBill: (this.phoneBill != null) ? this.phoneBill : undefined,
otherUtilities: (this.otherUtilities != null) ? this.otherUtilities : undefined
        };
    }
    



}



export class SpineData$FiBudgetDebtRepayments {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetDebtRepayments {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetDebtRepayments.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetDebtRepayments {
        const json: any = o ;
        return new SpineData$FiBudgetDebtRepayments(
         (json.autoLoan != null) ? json.autoLoan : 0,
(json.housingLoan != null) ? json.housingLoan : 0,
(json.loansAgainstSecurity != null) ? json.loansAgainstSecurity : 0,
(json.collateralizedBussinessLoan != null) ? json.collateralizedBussinessLoan : 0
        );
    }



  


   
    constructor(
        public autoLoan: number = 0,
public housingLoan: number = 0,
public loansAgainstSecurity: number = 0,
public collateralizedBussinessLoan: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          autoLoan: (this.autoLoan != null) ? this.autoLoan : undefined,
housingLoan: (this.housingLoan != null) ? this.housingLoan : undefined,
loansAgainstSecurity: (this.loansAgainstSecurity != null) ? this.loansAgainstSecurity : undefined,
collateralizedBussinessLoan: (this.collateralizedBussinessLoan != null) ? this.collateralizedBussinessLoan : undefined
        };
    }
    



}



export class PartnerData$ClientAddress {

   
    public static fromJsonString(jsonString: string): PartnerData$ClientAddress {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$ClientAddress.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$ClientAddress {
        const json: any = o ;
        return new PartnerData$ClientAddress(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class SpineData$ClCreditorCreditReportInfo {

   
    public static fromJsonString(jsonString: string): SpineData$ClCreditorCreditReportInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClCreditorCreditReportInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClCreditorCreditReportInfo {
        const json: any = o ;
        return new SpineData$ClCreditorCreditReportInfo(
         (json.totalDebt != null) ? json.totalDebt : 0,
(json.clCreditorCreditReportList != null) ? (json.clCreditorCreditReportList as object[]).map((x) => SpineData$ClCreditorCreditReport.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalDebt: number = 0,
public clCreditorCreditReportList: SpineData$ClCreditorCreditReport[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined,
clCreditorCreditReportList: (this.clCreditorCreditReportList != null) ? this.clCreditorCreditReportList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SpineData$CreditorMasterInfo {

   
    public static fromJsonString(jsonString: string): SpineData$CreditorMasterInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$CreditorMasterInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$CreditorMasterInfo {
        const json: any = o ;
        return new SpineData$CreditorMasterInfo(
         (json.creditorId != null) ? json.creditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.isServicedByFreed != null) ? json.isServicedByFreed : false
        );
    }



  


   
    constructor(
        public creditorId: string = '',
public creditorName: string = '',
public isServicedByFreed: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          creditorId: (this.creditorId != null) ? this.creditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
isServicedByFreed: (this.isServicedByFreed != null) ? this.isServicedByFreed : undefined
        };
    }
    



}



export class BenchData$AllocatedTask {

   
    public static fromJsonString(jsonString: string): BenchData$AllocatedTask {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchData$AllocatedTask.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchData$AllocatedTask {
        const json: any = o ;
        return new BenchData$AllocatedTask(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTime != null) ? new Date(json.allocatedTime) : new Date(),
(json.taskInput != null) ? json.taskInput : '',
(json.isSuspended != null) ? json.isSuspended : false
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public priority: number = 0,
public allocatedTime: Date = new Date(),
public taskInput: string = '',
public isSuspended: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined
        };
    }
    



}



export class SpineData$ClientFileSummary {

   
    public static fromJsonString(jsonString: string): SpineData$ClientFileSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientFileSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientFileSummary {
        const json: any = o ;
        return new SpineData$ClientFileSummary(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.msfDueDate != null) ? json.msfDueDate : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : 0,
(json.spaDueDate != null) ? json.spaDueDate : undefined,
(json.totalSaving != null) ? json.totalSaving : 0,
(json.spaBalance != null) ? json.spaBalance : 0,
(json.lastPaidDate != null) ? json.lastPaidDate : undefined,
(json.totalOutstanding != null) ? json.totalOutstanding : 0,
(json.totalTenure != null) ? json.totalTenure : 0,
(json.creditorsCount != null) ? json.creditorsCount : 0,
(json.settledCreditorsCount != null) ? json.settledCreditorsCount : 0,
(json.clientFileStatus != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.msfSummary != null) ? SpineData$MSFSummary.fromJson(json.msfSummary) : undefined,
(json.spaSummary != null) ? SpineData$SPASummary.fromJson(json.spaSummary) : undefined,
(json.eMandateActive != null) ? json.eMandateActive : false,
(json.ppCode != null) ? json.ppCode : '',
(json.addedBy != null) ? json.addedBy : '',
(json.dateCreated != null) ? new Date(json.dateCreated) : new Date(),
(json.serviceStatus != null) ? SpineData$SERVICE_STATUS.fromJson(json.serviceStatus) : SpineData$SERVICE_STATUS.DEFAULT_VALUE,
(json.mode != null) ? json.mode : '',
(json.orgCode != null) ? json.orgCode : '',
(json.salesRepAssignedStage != null) ? json.salesRepAssignedStage : undefined,
(json.dropOffReason != null) ? json.dropOffReason : undefined,
(json.paymentAggregator != null) ? SpineData$PAYMENT_AGGREGATOR.fromJson(json.paymentAggregator) : SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE,
(json.isOnBoardingFeePaid != null) ? json.isOnBoardingFeePaid : false,
(json.virtualAccountDetails != null) ? SpineData$VirtualAccountDetails.fromJson(json.virtualAccountDetails) : undefined,
(json.clientFileStage != null) ? SpineData$CLIENT_FILE_STAGE.fromJson(json.clientFileStage) : SpineData$CLIENT_FILE_STAGE.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined,
public msfDraftAmount: number = 0,
public msfDueDate: string|undefined = undefined,
public totalMonthlyObligation: number = 0,
public spaDueDate: string|undefined = undefined,
public totalSaving: number = 0,
public spaBalance: number = 0,
public lastPaidDate: string|undefined = undefined,
public totalOutstanding: number = 0,
public totalTenure: number = 0,
public creditorsCount: number = 0,
public settledCreditorsCount: number = 0,
public clientFileStatus: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public isFirstMSFPaid: boolean = false,
public msfSummary: SpineData$MSFSummary|undefined = undefined,
public spaSummary: SpineData$SPASummary|undefined = undefined,
public eMandateActive: boolean = false,
public ppCode: string = '',
public addedBy: string = '',
public dateCreated: Date = new Date(),
public serviceStatus: SpineData$SERVICE_STATUS = SpineData$SERVICE_STATUS.DEFAULT_VALUE,
public mode: string = '',
public orgCode: string = '',
public salesRepAssignedStage: string|undefined = undefined,
public dropOffReason: string|undefined = undefined,
public paymentAggregator: SpineData$PAYMENT_AGGREGATOR = SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE,
public isOnBoardingFeePaid: boolean = false,
public virtualAccountDetails: SpineData$VirtualAccountDetails|undefined = undefined,
public clientFileStage: SpineData$CLIENT_FILE_STAGE = SpineData$CLIENT_FILE_STAGE.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
msfDueDate: (this.msfDueDate != null) ? this.msfDueDate : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
spaDueDate: (this.spaDueDate != null) ? this.spaDueDate : undefined,
totalSaving: (this.totalSaving != null) ? this.totalSaving : undefined,
spaBalance: (this.spaBalance != null) ? this.spaBalance : undefined,
lastPaidDate: (this.lastPaidDate != null) ? this.lastPaidDate : undefined,
totalOutstanding: (this.totalOutstanding != null) ? this.totalOutstanding : undefined,
totalTenure: (this.totalTenure != null) ? this.totalTenure : undefined,
creditorsCount: (this.creditorsCount != null) ? this.creditorsCount : undefined,
settledCreditorsCount: (this.settledCreditorsCount != null) ? this.settledCreditorsCount : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
msfSummary: (this.msfSummary != null) ? this.msfSummary.toJson() : undefined,
spaSummary: (this.spaSummary != null) ? this.spaSummary.toJson() : undefined,
eMandateActive: (this.eMandateActive != null) ? this.eMandateActive : undefined,
ppCode: (this.ppCode != null) ? this.ppCode : undefined,
addedBy: (this.addedBy != null) ? this.addedBy : undefined,
dateCreated: (this.dateCreated != null) ? this.dateCreated.toISOString() : undefined,
serviceStatus: (this.serviceStatus != null) ?  SpineData$SERVICE_STATUS.copyMe(this.serviceStatus).toJson() : undefined,
mode: (this.mode != null) ? this.mode : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined,
salesRepAssignedStage: (this.salesRepAssignedStage != null) ? this.salesRepAssignedStage : undefined,
dropOffReason: (this.dropOffReason != null) ? this.dropOffReason : undefined,
paymentAggregator: (this.paymentAggregator != null) ?  SpineData$PAYMENT_AGGREGATOR.copyMe(this.paymentAggregator).toJson() : undefined,
isOnBoardingFeePaid: (this.isOnBoardingFeePaid != null) ? this.isOnBoardingFeePaid : undefined,
virtualAccountDetails: (this.virtualAccountDetails != null) ? this.virtualAccountDetails.toJson() : undefined,
clientFileStage: (this.clientFileStage != null) ?  SpineData$CLIENT_FILE_STAGE.copyMe(this.clientFileStage).toJson() : undefined
        };
    }
    



}



export class SpineData$ClientFileBasicInfo {

   
    public static fromJsonString(jsonString: string): SpineData$ClientFileBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientFileBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientFileBasicInfo {
        const json: any = o ;
        return new SpineData$ClientFileBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.clientBasicInfo != null) ? SpineData$ClientBasicInfo.fromJson(json.clientBasicInfo) : new SpineData$ClientBasicInfo(),
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.wad != null) ? json.wad : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.isSpineUser != null) ? json.isSpineUser : false,
(json.programCode != null) ? SpineData$PROGRAM_CODE.fromJson(json.programCode) : undefined,
(json.paymentAggregator != null) ? SpineData$PAYMENT_AGGREGATOR.fromJson(json.paymentAggregator) : SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public isFirstMSFPaid: boolean = false,
public clientBasicInfo: SpineData$ClientBasicInfo = new SpineData$ClientBasicInfo(),
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public wad: number|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public isSpineUser: boolean = false,
public programCode: SpineData$PROGRAM_CODE|undefined = undefined,
public paymentAggregator: SpineData$PAYMENT_AGGREGATOR = SpineData$PAYMENT_AGGREGATOR.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
isSpineUser: (this.isSpineUser != null) ? this.isSpineUser : undefined,
programCode: (this.programCode != null) ? SpineData$PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined,
paymentAggregator: (this.paymentAggregator != null) ?  SpineData$PAYMENT_AGGREGATOR.copyMe(this.paymentAggregator).toJson() : undefined
        };
    }
    



}



export class SpineData$ClientBasicInfo {

   
    public static fromJsonString(jsonString: string): SpineData$ClientBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientBasicInfo {
        const json: any = o ;
        return new SpineData$ClientBasicInfo(
         (json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.pan != null) ? json.pan : undefined,
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined,
(json.clientFileBasicInfoList != null) ? (json.clientFileBasicInfoList as object[]).map((x) => SpineData$ClientCFBasicInfo.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientId: string = '',
public fullName: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public pan: string|undefined = undefined,
public city: string|undefined = undefined,
public state: string|undefined = undefined,
public clientFileBasicInfoList: SpineData$ClientCFBasicInfo[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
pan: (this.pan != null) ? this.pan : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
clientFileBasicInfoList: (this.clientFileBasicInfoList != null) ? this.clientFileBasicInfoList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SpineData$FiBudgetDependentExpenses {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetDependentExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetDependentExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetDependentExpenses {
        const json: any = o ;
        return new SpineData$FiBudgetDependentExpenses(
         (json.medicalExpense != null) ? json.medicalExpense : 0,
(json.essentialCareExpense != null) ? json.essentialCareExpense : 0,
(json.schoolFeeExpense != null) ? json.schoolFeeExpense : 0,
(json.schoolBusExpense != null) ? json.schoolBusExpense : 0,
(json.booksAndProjectsExpense != null) ? json.booksAndProjectsExpense : 0,
(json.nursingAttendExpense != null) ? json.nursingAttendExpense : 0,
(json.petCareExpense != null) ? json.petCareExpense : 0
        );
    }



  


   
    constructor(
        public medicalExpense: number = 0,
public essentialCareExpense: number = 0,
public schoolFeeExpense: number = 0,
public schoolBusExpense: number = 0,
public booksAndProjectsExpense: number = 0,
public nursingAttendExpense: number = 0,
public petCareExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          medicalExpense: (this.medicalExpense != null) ? this.medicalExpense : undefined,
essentialCareExpense: (this.essentialCareExpense != null) ? this.essentialCareExpense : undefined,
schoolFeeExpense: (this.schoolFeeExpense != null) ? this.schoolFeeExpense : undefined,
schoolBusExpense: (this.schoolBusExpense != null) ? this.schoolBusExpense : undefined,
booksAndProjectsExpense: (this.booksAndProjectsExpense != null) ? this.booksAndProjectsExpense : undefined,
nursingAttendExpense: (this.nursingAttendExpense != null) ? this.nursingAttendExpense : undefined,
petCareExpense: (this.petCareExpense != null) ? this.petCareExpense : undefined
        };
    }
    



}



export class SpineData$FiBudgetIncidentalExpenses {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetIncidentalExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetIncidentalExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetIncidentalExpenses {
        const json: any = o ;
        return new SpineData$FiBudgetIncidentalExpenses(
         (json.healthInsuranceExpense != null) ? json.healthInsuranceExpense : 0,
(json.vehicleInsuranceExpense != null) ? json.vehicleInsuranceExpense : 0,
(json.sipExpense != null) ? json.sipExpense : 0,
(json.stockTradesExpense != null) ? json.stockTradesExpense : 0,
(json.otherExpense != null) ? json.otherExpense : 0
        );
    }



  


   
    constructor(
        public healthInsuranceExpense: number = 0,
public vehicleInsuranceExpense: number = 0,
public sipExpense: number = 0,
public stockTradesExpense: number = 0,
public otherExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          healthInsuranceExpense: (this.healthInsuranceExpense != null) ? this.healthInsuranceExpense : undefined,
vehicleInsuranceExpense: (this.vehicleInsuranceExpense != null) ? this.vehicleInsuranceExpense : undefined,
sipExpense: (this.sipExpense != null) ? this.sipExpense : undefined,
stockTradesExpense: (this.stockTradesExpense != null) ? this.stockTradesExpense : undefined,
otherExpense: (this.otherExpense != null) ? this.otherExpense : undefined
        };
    }
    



}



export class SpineData$ClPersonalInfo {

   
    public static fromJsonString(jsonString: string): SpineData$ClPersonalInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClPersonalInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClPersonalInfo {
        const json: any = o ;
        return new SpineData$ClPersonalInfo(
         (json.secondaryPhone != null) ? json.secondaryPhone : undefined,
(json.motherMaidenName != null) ? json.motherMaidenName : undefined,
(json.fatherName != null) ? json.fatherName : undefined,
(json.pan != null) ? json.pan : undefined,
(json.dob != null) ? json.dob : undefined,
(json.gender != null) ? SpineData$GENDER.fromJson(json.gender) : undefined,
(json.residentialAddress != null) ? SpineData$ClAddress.fromJson(json.residentialAddress) : undefined,
(json.clientLanguage != null) ? json.clientLanguage : undefined
        );
    }



  


   
    constructor(
        public secondaryPhone: string|undefined = undefined,
public motherMaidenName: string|undefined = undefined,
public fatherName: string|undefined = undefined,
public pan: string|undefined = undefined,
public dob: string|undefined = undefined,
public gender: SpineData$GENDER|undefined = undefined,
public residentialAddress: SpineData$ClAddress|undefined = undefined,
public clientLanguage: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined,
motherMaidenName: (this.motherMaidenName != null) ? this.motherMaidenName : undefined,
fatherName: (this.fatherName != null) ? this.fatherName : undefined,
pan: (this.pan != null) ? this.pan : undefined,
dob: (this.dob != null) ? this.dob : undefined,
gender: (this.gender != null) ? SpineData$GENDER.copyMe(this.gender).toJson() : undefined,
residentialAddress: (this.residentialAddress != null) ? this.residentialAddress.toJson() : undefined,
clientLanguage: (this.clientLanguage != null) ? this.clientLanguage : undefined
        };
    }
    



}



export class SpineData$FiScheduledEntry {

   
    public static fromJsonString(jsonString: string): SpineData$FiScheduledEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiScheduledEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiScheduledEntry {
        const json: any = o ;
        return new SpineData$FiScheduledEntry(
         (json.entryId != null) ? json.entryId : '',
(json.status != null) ? SpineData$SCHEDULED_ENTRY_STATUS.fromJson(json.status) : SpineData$SCHEDULED_ENTRY_STATUS.DEFAULT_VALUE,
(json.paymentId != null) ? json.paymentId : undefined,
(json.paymentStatus != null) ? SpineData$PAYMENT_STATUS.fromJson(json.paymentStatus) : undefined,
(json.psPlanStatus != null) ? SpineData$PS_PLAN_STATUS.fromJson(json.psPlanStatus) : SpineData$PS_PLAN_STATUS.DEFAULT_VALUE,
(json.draftDate != null) ? json.draftDate : '',
(json.totalAmount != null) ? json.totalAmount : 0,
(json.statusUpdatedOn != null) ? new Date(json.statusUpdatedOn) : new Date(),
(json.linkedEMandate != null) ? SpineData$FiEMandateSummary.fromJson(json.linkedEMandate) : undefined
        );
    }



  


   
    constructor(
        public entryId: string = '',
public status: SpineData$SCHEDULED_ENTRY_STATUS = SpineData$SCHEDULED_ENTRY_STATUS.DEFAULT_VALUE,
public paymentId: string|undefined = undefined,
public paymentStatus: SpineData$PAYMENT_STATUS|undefined = undefined,
public psPlanStatus: SpineData$PS_PLAN_STATUS = SpineData$PS_PLAN_STATUS.DEFAULT_VALUE,
public draftDate: string = '',
public totalAmount: number = 0,
public statusUpdatedOn: Date = new Date(),
public linkedEMandate: SpineData$FiEMandateSummary|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          entryId: (this.entryId != null) ? this.entryId : undefined,
status: (this.status != null) ?  SpineData$SCHEDULED_ENTRY_STATUS.copyMe(this.status).toJson() : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined,
paymentStatus: (this.paymentStatus != null) ? SpineData$PAYMENT_STATUS.copyMe(this.paymentStatus).toJson() : undefined,
psPlanStatus: (this.psPlanStatus != null) ?  SpineData$PS_PLAN_STATUS.copyMe(this.psPlanStatus).toJson() : undefined,
draftDate: (this.draftDate != null) ? this.draftDate : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn.toISOString() : undefined,
linkedEMandate: (this.linkedEMandate != null) ? this.linkedEMandate.toJson() : undefined
        };
    }
    



}



export class SpineData$DocumentDetails {

   
    public static fromJsonString(jsonString: string): SpineData$DocumentDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$DocumentDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$DocumentDetails {
        const json: any = o ;
        return new SpineData$DocumentDetails(
         (json.documentId != null) ? json.documentId : '',
(json.documentType != null) ? json.documentType : undefined,
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined
        );
    }



  


   
    constructor(
        public documentId: string = '',
public documentType: string|undefined = undefined,
public documentPath: string = '',
public documentDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentId: (this.documentId != null) ? this.documentId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    



}



export class SpineData$ProgramDetails {

   
    public static fromJsonString(jsonString: string): SpineData$ProgramDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ProgramDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ProgramDetails {
        const json: any = o ;
        return new SpineData$ProgramDetails(
         (json.totalDebt != null) ? json.totalDebt : 0,
(json.repaymentAmount != null) ? json.repaymentAmount : 0,
(json.estimatedSavingAmount != null) ? json.estimatedSavingAmount : 0
        );
    }



  


   
    constructor(
        public totalDebt: number = 0,
public repaymentAmount: number = 0,
public estimatedSavingAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined,
repaymentAmount: (this.repaymentAmount != null) ? this.repaymentAmount : undefined,
estimatedSavingAmount: (this.estimatedSavingAmount != null) ? this.estimatedSavingAmount : undefined
        };
    }
    



}



export class InsightData$DaywiseCount {

   
    public static fromJsonString(jsonString: string): InsightData$DaywiseCount {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$DaywiseCount.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$DaywiseCount {
        const json: any = o ;
        return new InsightData$DaywiseCount(
         (json.count != null) ? json.count : 0,
(json.ddMM != null) ? json.ddMM : ''
        );
    }



  


   
    constructor(
        public count: number = 0,
public ddMM: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          count: (this.count != null) ? this.count : undefined,
ddMM: (this.ddMM != null) ? this.ddMM : undefined
        };
    }
    



}



export class SpineData$FiEMandate {

   
    public static fromJsonString(jsonString: string): SpineData$FiEMandate {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiEMandate.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiEMandate {
        const json: any = o ;
        return new SpineData$FiEMandate(
         (json.eMandateId != null) ? json.eMandateId : '',
(json.umrn != null) ? json.umrn : undefined,
(json.remoteEMandateId != null) ? json.remoteEMandateId : undefined,
(json.eMandateStatus != null) ? SpineData$EMANDATE_STATUS.fromJson(json.eMandateStatus) : SpineData$EMANDATE_STATUS.DEFAULT_VALUE,
(json.provider != null) ? SpineData$EMANDATE_PROVIDER.fromJson(json.provider) : SpineData$EMANDATE_PROVIDER.DEFAULT_VALUE,
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.nupayBankId != null) ? json.nupayBankId : 0,
(json.nupayBankName != null) ? json.nupayBankName : '',
(json.nupayBankCode != null) ? json.nupayBankCode : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? SpineData$ACCOUNT_TYPE.fromJson(json.accountType) : SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? SpineData$FiBankAddress.fromJson(json.bankAddress) : undefined,
(json.amount != null) ? json.amount : 0,
(json.label != null) ? json.label : '',
(json.isDefault != null) ? json.isDefault : false,
(json.createdOn != null) ? new Date(json.createdOn) : new Date(),
(json.activatedOn != null) ? new Date(json.activatedOn) : undefined,
(json.isUPIMandate != null) ? json.isUPIMandate : false,
(json.remoteEMandateDetails != null) ? json.remoteEMandateDetails : undefined
        );
    }



  


   
    constructor(
        public eMandateId: string = '',
public umrn: string|undefined = undefined,
public remoteEMandateId: string|undefined = undefined,
public eMandateStatus: SpineData$EMANDATE_STATUS = SpineData$EMANDATE_STATUS.DEFAULT_VALUE,
public provider: SpineData$EMANDATE_PROVIDER = SpineData$EMANDATE_PROVIDER.DEFAULT_VALUE,
public nupayBankMasterId: string = '',
public nupayBankId: number = 0,
public nupayBankName: string = '',
public nupayBankCode: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: SpineData$ACCOUNT_TYPE = SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
public accountHolderName: string = '',
public bankAddress: SpineData$FiBankAddress|undefined = undefined,
public amount: number = 0,
public label: string = '',
public isDefault: boolean = false,
public createdOn: Date = new Date(),
public activatedOn: Date|undefined = undefined,
public isUPIMandate: boolean = false,
public remoteEMandateDetails: object|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
umrn: (this.umrn != null) ? this.umrn : undefined,
remoteEMandateId: (this.remoteEMandateId != null) ? this.remoteEMandateId : undefined,
eMandateStatus: (this.eMandateStatus != null) ?  SpineData$EMANDATE_STATUS.copyMe(this.eMandateStatus).toJson() : undefined,
provider: (this.provider != null) ?  SpineData$EMANDATE_PROVIDER.copyMe(this.provider).toJson() : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
nupayBankName: (this.nupayBankName != null) ? this.nupayBankName : undefined,
nupayBankCode: (this.nupayBankCode != null) ? this.nupayBankCode : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ?  SpineData$ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined,
amount: (this.amount != null) ? this.amount : undefined,
label: (this.label != null) ? this.label : undefined,
isDefault: (this.isDefault != null) ? this.isDefault : undefined,
createdOn: (this.createdOn != null) ? this.createdOn.toISOString() : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn.toISOString() : undefined,
isUPIMandate: (this.isUPIMandate != null) ? this.isUPIMandate : undefined,
remoteEMandateDetails: (this.remoteEMandateDetails != null) ? this.remoteEMandateDetails : undefined
        };
    }
    



}



export class SpineData$SuspendedTaskAllocated {

   
    public static fromJsonString(jsonString: string): SpineData$SuspendedTaskAllocated {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$SuspendedTaskAllocated.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$SuspendedTaskAllocated {
        const json: any = o ;
        return new SpineData$SuspendedTaskAllocated(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? ValeyData$TaskType.fromJson(json.taskType) : ValeyData$TaskType.DEFAULT_VALUE,
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? ValeyData$TaskState.fromJson(json.taskState) : ValeyData$TaskState.DEFAULT_VALUE,
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? new Date(json.readyTime) : new Date(),
(json.allocatedTime != null) ? new Date(json.allocatedTime) : undefined,
(json.startedTime != null) ? new Date(json.startedTime) : undefined,
(json.endTime != null) ? new Date(json.endTime) : undefined,
(json.lastUserActivityTime != null) ? new Date(json.lastUserActivityTime) : undefined,
(json.taskInput != null) ? json.taskInput : {},
(json.taskOutput != null) ? json.taskOutput : {},
(json.resumeOn != null) ? new Date(json.resumeOn) : undefined,
(json.reminderId != null) ? json.reminderId : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: ValeyData$TaskType = ValeyData$TaskType.DEFAULT_VALUE,
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: ValeyData$TaskState = ValeyData$TaskState.DEFAULT_VALUE,
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: Date = new Date(),
public allocatedTime: Date|undefined = undefined,
public startedTime: Date|undefined = undefined,
public endTime: Date|undefined = undefined,
public lastUserActivityTime: Date|undefined = undefined,
public taskInput: object = {},
public taskOutput: object = {},
public resumeOn: Date|undefined = undefined,
public reminderId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ?  ValeyData$TaskType.copyMe(this.taskType).toJson() : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ?  ValeyData$TaskState.copyMe(this.taskState).toJson() : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime.toISOString() : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime.toISOString() : undefined,
startedTime: (this.startedTime != null) ? this.startedTime.toISOString() : undefined,
endTime: (this.endTime != null) ? this.endTime.toISOString() : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime.toISOString() : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined,
resumeOn: (this.resumeOn != null) ? this.resumeOn.toISOString() : undefined,
reminderId: (this.reminderId != null) ? this.reminderId : undefined
        };
    }
    



}



export class SpineData$TaskExceptionDetails {

   
    public static fromJsonString(jsonString: string): SpineData$TaskExceptionDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$TaskExceptionDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$TaskExceptionDetails {
        const json: any = o ;
        return new SpineData$TaskExceptionDetails(
         (json.exceptionSummary != null) ? json.exceptionSummary : '',
(json.exceptionDetails != null) ? json.exceptionDetails : '',
(json.exceptionTime != null) ? new Date(json.exceptionTime) : new Date()
        );
    }



  


   
    constructor(
        public exceptionSummary: string = '',
public exceptionDetails: string = '',
public exceptionTime: Date = new Date()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          exceptionSummary: (this.exceptionSummary != null) ? this.exceptionSummary : undefined,
exceptionDetails: (this.exceptionDetails != null) ? this.exceptionDetails : undefined,
exceptionTime: (this.exceptionTime != null) ? this.exceptionTime.toISOString() : undefined
        };
    }
    



}



export class BenchData$BenchTaskSummary {

   
    public static fromJsonString(jsonString: string): BenchData$BenchTaskSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchData$BenchTaskSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchData$BenchTaskSummary {
        const json: any = o ;
        return new BenchData$BenchTaskSummary(
         (json.startedCount != null) ? json.startedCount : 0,
(json.allocatedCount != null) ? json.allocatedCount : 0,
(json.completedCount != null) ? json.completedCount : 0
        );
    }



  


   
    constructor(
        public startedCount: number = 0,
public allocatedCount: number = 0,
public completedCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          startedCount: (this.startedCount != null) ? this.startedCount : undefined,
allocatedCount: (this.allocatedCount != null) ? this.allocatedCount : undefined,
completedCount: (this.completedCount != null) ? this.completedCount : undefined
        };
    }
    



}



export class SpineData$ClientSearch {

   
    public static fromJsonString(jsonString: string): SpineData$ClientSearch {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientSearch.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientSearch {
        const json: any = o ;
        return new SpineData$ClientSearch(
         (json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public fullName: string = '',
public emailId: string = '',
public mobile: string = '',
public city: string|undefined = undefined,
public state: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined
        };
    }
    



}



export class SpineData$MyClientFile {

   
    public static fromJsonString(jsonString: string): SpineData$MyClientFile {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$MyClientFile.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$MyClientFile {
        const json: any = o ;
        return new SpineData$MyClientFile(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.emailId != null) ? json.emailId : '',
(json.dateCreated != null) ? new Date(json.dateCreated) : new Date(),
(json.mobile != null) ? json.mobile : '',
(json.clientFileStatus != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.eMandateActive != null) ? json.eMandateActive : false,
(json.firstMSFPaid != null) ? json.firstMSFPaid : false,
(json.clientFileStage != null) ? SpineData$CLIENT_FILE_STAGE.fromJson(json.clientFileStage) : SpineData$CLIENT_FILE_STAGE.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientId: string = '',
public fullName: string = '',
public emailId: string = '',
public dateCreated: Date = new Date(),
public mobile: string = '',
public clientFileStatus: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public eMandateActive: boolean = false,
public firstMSFPaid: boolean = false,
public clientFileStage: SpineData$CLIENT_FILE_STAGE = SpineData$CLIENT_FILE_STAGE.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
dateCreated: (this.dateCreated != null) ? this.dateCreated.toISOString() : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
eMandateActive: (this.eMandateActive != null) ? this.eMandateActive : undefined,
firstMSFPaid: (this.firstMSFPaid != null) ? this.firstMSFPaid : undefined,
clientFileStage: (this.clientFileStage != null) ?  SpineData$CLIENT_FILE_STAGE.copyMe(this.clientFileStage).toJson() : undefined
        };
    }
    



}



export class SpineData$GompaUserDetails {

   
    public static fromJsonString(jsonString: string): SpineData$GompaUserDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$GompaUserDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$GompaUserDetails {
        const json: any = o ;
        return new SpineData$GompaUserDetails(
         (json.userId != null) ? json.userId : '',
(json.userName != null) ? json.userName : '',
(json.emailId != null) ? json.emailId : '',
(json.title != null) ? json.title : undefined,
(json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.fullName != null) ? json.fullName : '',
(json.mobile != null) ? json.mobile : undefined,
(json.active != null) ? json.active : false,
(json.leadSquaredUserId != null) ? json.leadSquaredUserId : undefined,
(json.languageList != null) ? json.languageList : [],
(json.userGroupList != null) ? (json.userGroupList as object[]).map((x) => SpineData$GompaUserUserGroupMap.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public userId: string = '',
public userName: string = '',
public emailId: string = '',
public title: string|undefined = undefined,
public firstName: string = '',
public lastName: string|undefined = undefined,
public fullName: string = '',
public mobile: string|undefined = undefined,
public active: boolean = false,
public leadSquaredUserId: string|undefined = undefined,
public languageList: string[] = [],
public userGroupList: SpineData$GompaUserUserGroupMap[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userId: (this.userId != null) ? this.userId : undefined,
userName: (this.userName != null) ? this.userName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
title: (this.title != null) ? this.title : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
active: (this.active != null) ? this.active : undefined,
leadSquaredUserId: (this.leadSquaredUserId != null) ? this.leadSquaredUserId : undefined,
languageList: (this.languageList != null) ? this.languageList : [],
userGroupList: (this.userGroupList != null) ? this.userGroupList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SpineData$FiBudgetLifeStyleExpensesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetLifeStyleExpensesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetLifeStyleExpensesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetLifeStyleExpensesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetLifeStyleExpensesDetails(
         (json.travelExpense != null) ? json.travelExpense : undefined,
(json.digitalSubscriptionsExpense != null) ? json.digitalSubscriptionsExpense : undefined,
(json.diningOutExpense != null) ? json.diningOutExpense : undefined,
(json.houseHelpExpense != null) ? json.houseHelpExpense : undefined,
(json.outingExpense != null) ? json.outingExpense : undefined
        );
    }



  


   
    constructor(
        public travelExpense: number|undefined = undefined,
public digitalSubscriptionsExpense: number|undefined = undefined,
public diningOutExpense: number|undefined = undefined,
public houseHelpExpense: number|undefined = undefined,
public outingExpense: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          travelExpense: (this.travelExpense != null) ? this.travelExpense : undefined,
digitalSubscriptionsExpense: (this.digitalSubscriptionsExpense != null) ? this.digitalSubscriptionsExpense : undefined,
diningOutExpense: (this.diningOutExpense != null) ? this.diningOutExpense : undefined,
houseHelpExpense: (this.houseHelpExpense != null) ? this.houseHelpExpense : undefined,
outingExpense: (this.outingExpense != null) ? this.outingExpense : undefined
        };
    }
    



}



export class SpineData$FiBankInfo {

   
    public static fromJsonString(jsonString: string): SpineData$FiBankInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBankInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBankInfo {
        const json: any = o ;
        return new SpineData$FiBankInfo(
         (json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.nupayBankMasterName != null) ? json.nupayBankMasterName : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? SpineData$ACCOUNT_TYPE.fromJson(json.accountType) : SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? SpineData$FiBankAddress.fromJson(json.bankAddress) : new SpineData$FiBankAddress()
        );
    }



  


   
    constructor(
        public nupayBankMasterId: string = '',
public nupayBankMasterName: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: SpineData$ACCOUNT_TYPE = SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
public accountHolderName: string = '',
public bankAddress: SpineData$FiBankAddress = new SpineData$FiBankAddress()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
nupayBankMasterName: (this.nupayBankMasterName != null) ? this.nupayBankMasterName : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ?  SpineData$ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined
        };
    }
    



}



export class InsightData$PartnerClientConversionFunnel {

   
    public static fromJsonString(jsonString: string): InsightData$PartnerClientConversionFunnel {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$PartnerClientConversionFunnel.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$PartnerClientConversionFunnel {
        const json: any = o ;
        return new InsightData$PartnerClientConversionFunnel(
         (json.leadCount != null) ? json.leadCount : 0,
(json.creditReportCount != null) ? json.creditReportCount : 0,
(json.budgetCount != null) ? json.budgetCount : 0,
(json.budgetCountConversionPercentage != null) ? json.budgetCountConversionPercentage : 0,
(json.programGenCount != null) ? json.programGenCount : 0,
(json.programGenCountConversionPercentage != null) ? json.programGenCountConversionPercentage : 0,
(json.agreementCount != null) ? json.agreementCount : 0,
(json.agreementCountConversionPercentage != null) ? json.agreementCountConversionPercentage : 0,
(json.onBoardingFeePaidCount != null) ? json.onBoardingFeePaidCount : 0,
(json.onBoardingFeePaidCountConversionPercentage != null) ? json.onBoardingFeePaidCountConversionPercentage : 0,
(json.eMandateDoneCount != null) ? json.eMandateDoneCount : 0,
(json.eMandateDoneCountConversionPercentage != null) ? json.eMandateDoneCountConversionPercentage : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public creditReportCount: number = 0,
public budgetCount: number = 0,
public budgetCountConversionPercentage: number = 0,
public programGenCount: number = 0,
public programGenCountConversionPercentage: number = 0,
public agreementCount: number = 0,
public agreementCountConversionPercentage: number = 0,
public onBoardingFeePaidCount: number = 0,
public onBoardingFeePaidCountConversionPercentage: number = 0,
public eMandateDoneCount: number = 0,
public eMandateDoneCountConversionPercentage: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
creditReportCount: (this.creditReportCount != null) ? this.creditReportCount : undefined,
budgetCount: (this.budgetCount != null) ? this.budgetCount : undefined,
budgetCountConversionPercentage: (this.budgetCountConversionPercentage != null) ? this.budgetCountConversionPercentage : undefined,
programGenCount: (this.programGenCount != null) ? this.programGenCount : undefined,
programGenCountConversionPercentage: (this.programGenCountConversionPercentage != null) ? this.programGenCountConversionPercentage : undefined,
agreementCount: (this.agreementCount != null) ? this.agreementCount : undefined,
agreementCountConversionPercentage: (this.agreementCountConversionPercentage != null) ? this.agreementCountConversionPercentage : undefined,
onBoardingFeePaidCount: (this.onBoardingFeePaidCount != null) ? this.onBoardingFeePaidCount : undefined,
onBoardingFeePaidCountConversionPercentage: (this.onBoardingFeePaidCountConversionPercentage != null) ? this.onBoardingFeePaidCountConversionPercentage : undefined,
eMandateDoneCount: (this.eMandateDoneCount != null) ? this.eMandateDoneCount : undefined,
eMandateDoneCountConversionPercentage: (this.eMandateDoneCountConversionPercentage != null) ? this.eMandateDoneCountConversionPercentage : undefined
        };
    }
    



}



export class SpineData$ClientCFBasicInfo {

   
    public static fromJsonString(jsonString: string): SpineData$ClientCFBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClientCFBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClientCFBasicInfo {
        const json: any = o ;
        return new SpineData$ClientCFBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? SpineData$CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.isFirstEMandateDone != null) ? json.isFirstEMandateDone : false,
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.wad != null) ? json.wad : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.isSpineUser != null) ? json.isSpineUser : false,
(json.programCode != null) ? SpineData$PROGRAM_CODE.fromJson(json.programCode) : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: SpineData$CLIENT_FILE_STATUS = SpineData$CLIENT_FILE_STATUS.DEFAULT_VALUE,
public isFirstMSFPaid: boolean = false,
public isFirstEMandateDone: boolean = false,
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public wad: number|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public isSpineUser: boolean = false,
public programCode: SpineData$PROGRAM_CODE|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  SpineData$CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
isFirstEMandateDone: (this.isFirstEMandateDone != null) ? this.isFirstEMandateDone : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
isSpineUser: (this.isSpineUser != null) ? this.isSpineUser : undefined,
programCode: (this.programCode != null) ? SpineData$PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined
        };
    }
    



}



export class SpineData$IneligibleUnsecuredDebt {

   
    public static fromJsonString(jsonString: string): SpineData$IneligibleUnsecuredDebt {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$IneligibleUnsecuredDebt.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$IneligibleUnsecuredDebt {
        const json: any = o ;
        return new SpineData$IneligibleUnsecuredDebt(
         (json.personalLoanDebt != null) ? json.personalLoanDebt : 0,
(json.creditCardDebt != null) ? json.creditCardDebt : 0,
(json.total != null) ? json.total : 0
        );
    }



  


   
    constructor(
        public personalLoanDebt: number = 0,
public creditCardDebt: number = 0,
public total: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          personalLoanDebt: (this.personalLoanDebt != null) ? this.personalLoanDebt : undefined,
creditCardDebt: (this.creditCardDebt != null) ? this.creditCardDebt : undefined,
total: (this.total != null) ? this.total : undefined
        };
    }
    



}



export class SpineData$Disposition {

   
    public static fromJsonString(jsonString: string): SpineData$Disposition {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$Disposition.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$Disposition {
        const json: any = o ;
        return new SpineData$Disposition(
         (json.dispositionType != null) ? json.dispositionType : '',
(json.dispositionDetails != null) ? json.dispositionDetails : undefined
        );
    }



  


   
    constructor(
        public dispositionType: string = '',
public dispositionDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          dispositionType: (this.dispositionType != null) ? this.dispositionType : undefined,
dispositionDetails: (this.dispositionDetails != null) ? this.dispositionDetails : undefined
        };
    }
    



}



export class SpineData$FiPayment {

   
    public static fromJsonString(jsonString: string): SpineData$FiPayment {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiPayment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiPayment {
        const json: any = o ;
        return new SpineData$FiPayment(
         (json.paymentId != null) ? json.paymentId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.paymentType != null) ? SpineData$PAYMENT_TYPE.fromJson(json.paymentType) : SpineData$PAYMENT_TYPE.DEFAULT_VALUE,
(json.paymentProvider != null) ? SpineData$PAYMENT_PROVIDER.fromJson(json.paymentProvider) : SpineData$PAYMENT_PROVIDER.DEFAULT_VALUE,
(json.paymentMode != null) ? SpineData$PAYMENT_MODE.fromJson(json.paymentMode) : undefined,
(json.settledTo != null) ? SpineData$SETTLED_TO.fromJson(json.settledTo) : undefined,
(json.settledToId != null) ? json.settledToId : undefined,
(json.accountNumber != null) ? json.accountNumber : undefined,
(json.accountHolderName != null) ? json.accountHolderName : undefined,
(json.accountType != null) ? SpineData$ACCOUNT_TYPE.fromJson(json.accountType) : undefined,
(json.ifscCode != null) ? json.ifscCode : undefined,
(json.eMandateId != null) ? json.eMandateId : undefined,
(json.presentedDate != null) ? json.presentedDate : undefined,
(json.totalAmount != null) ? json.totalAmount : 0,
(json.spaAmount != null) ? json.spaAmount : undefined,
(json.feeAmount != null) ? json.feeAmount : undefined,
(json.msfAmount != null) ? json.msfAmount : undefined,
(json.paymentRefNumber != null) ? json.paymentRefNumber : '',
(json.remoteTxnRefNumber != null) ? json.remoteTxnRefNumber : undefined,
(json.remoteTxnRefDetails != null) ? json.remoteTxnRefDetails : undefined,
(json.achSeqNumber != null) ? json.achSeqNumber : undefined,
(json.status != null) ? SpineData$PAYMENT_STATUS.fromJson(json.status) : SpineData$PAYMENT_STATUS.DEFAULT_VALUE,
(json.details != null) ? json.details : undefined,
(json.receivedBy != null) ? json.receivedBy : undefined,
(json.refId != null) ? json.refId : undefined,
(json.lastUpdatedTime != null) ? new Date(json.lastUpdatedTime) : undefined,
(json.selfEnrolPaymentLink != null) ? json.selfEnrolPaymentLink : undefined
        );
    }



  


   
    constructor(
        public paymentId: string = '',
public clientFileId: string = '',
public clientFileNumber: string = '',
public paymentType: SpineData$PAYMENT_TYPE = SpineData$PAYMENT_TYPE.DEFAULT_VALUE,
public paymentProvider: SpineData$PAYMENT_PROVIDER = SpineData$PAYMENT_PROVIDER.DEFAULT_VALUE,
public paymentMode: SpineData$PAYMENT_MODE|undefined = undefined,
public settledTo: SpineData$SETTLED_TO|undefined = undefined,
public settledToId: string|undefined = undefined,
public accountNumber: string|undefined = undefined,
public accountHolderName: string|undefined = undefined,
public accountType: SpineData$ACCOUNT_TYPE|undefined = undefined,
public ifscCode: string|undefined = undefined,
public eMandateId: string|undefined = undefined,
public presentedDate: string|undefined = undefined,
public totalAmount: number = 0,
public spaAmount: number|undefined = undefined,
public feeAmount: number|undefined = undefined,
public msfAmount: number|undefined = undefined,
public paymentRefNumber: string = '',
public remoteTxnRefNumber: string|undefined = undefined,
public remoteTxnRefDetails: object|undefined = undefined,
public achSeqNumber: string|undefined = undefined,
public status: SpineData$PAYMENT_STATUS = SpineData$PAYMENT_STATUS.DEFAULT_VALUE,
public details: string|undefined = undefined,
public receivedBy: string|undefined = undefined,
public refId: string|undefined = undefined,
public lastUpdatedTime: Date|undefined = undefined,
public selfEnrolPaymentLink: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
paymentType: (this.paymentType != null) ?  SpineData$PAYMENT_TYPE.copyMe(this.paymentType).toJson() : undefined,
paymentProvider: (this.paymentProvider != null) ?  SpineData$PAYMENT_PROVIDER.copyMe(this.paymentProvider).toJson() : undefined,
paymentMode: (this.paymentMode != null) ? SpineData$PAYMENT_MODE.copyMe(this.paymentMode).toJson() : undefined,
settledTo: (this.settledTo != null) ? SpineData$SETTLED_TO.copyMe(this.settledTo).toJson() : undefined,
settledToId: (this.settledToId != null) ? this.settledToId : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
accountType: (this.accountType != null) ? SpineData$ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
presentedDate: (this.presentedDate != null) ? this.presentedDate : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined,
paymentRefNumber: (this.paymentRefNumber != null) ? this.paymentRefNumber : undefined,
remoteTxnRefNumber: (this.remoteTxnRefNumber != null) ? this.remoteTxnRefNumber : undefined,
remoteTxnRefDetails: (this.remoteTxnRefDetails != null) ? this.remoteTxnRefDetails : undefined,
achSeqNumber: (this.achSeqNumber != null) ? this.achSeqNumber : undefined,
status: (this.status != null) ?  SpineData$PAYMENT_STATUS.copyMe(this.status).toJson() : undefined,
details: (this.details != null) ? this.details : undefined,
receivedBy: (this.receivedBy != null) ? this.receivedBy : undefined,
refId: (this.refId != null) ? this.refId : undefined,
lastUpdatedTime: (this.lastUpdatedTime != null) ? this.lastUpdatedTime.toISOString() : undefined,
selfEnrolPaymentLink: (this.selfEnrolPaymentLink != null) ? this.selfEnrolPaymentLink : undefined
        };
    }
    



}



export class SpineData$ClCreditor {

   
    public static fromJsonString(jsonString: string): SpineData$ClCreditor {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClCreditor.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClCreditor {
        const json: any = o ;
        return new SpineData$ClCreditor(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : undefined,
(json.debtType != null) ? json.debtType : undefined,
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.creditorSearchStr != null) ? json.creditorSearchStr : '',
(json.creditorMasterInfo != null) ? SpineData$CreditorMasterInfo.fromJson(json.creditorMasterInfo) : undefined
        );
    }



  


   
    constructor(
        public clCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string|undefined = undefined,
public debtType: string|undefined = undefined,
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public creditorSearchStr: string = '',
public creditorMasterInfo: SpineData$CreditorMasterInfo|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
creditorSearchStr: (this.creditorSearchStr != null) ? this.creditorSearchStr : undefined,
creditorMasterInfo: (this.creditorMasterInfo != null) ? this.creditorMasterInfo.toJson() : undefined
        };
    }
    



}



export class SpineData$FiDocument {

   
    public static fromJsonString(jsonString: string): SpineData$FiDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiDocument {
        const json: any = o ;
        return new SpineData$FiDocument(
         (json.fiDocumentId != null) ? json.fiDocumentId : '',
(json.documentType != null) ? json.documentType : '',
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.uploadedOn != null) ? new Date(json.uploadedOn) : new Date(),
(json.archived != null) ? json.archived : false
        );
    }



  


   
    constructor(
        public fiDocumentId: string = '',
public documentType: string = '',
public documentPath: string = '',
public documentDetails: string|undefined = undefined,
public uploadedOn: Date = new Date(),
public archived: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn.toISOString() : undefined,
archived: (this.archived != null) ? this.archived : undefined
        };
    }
    



}



export class PartnerData$FiCreditorDetails {

   
    public static fromJsonString(jsonString: string): PartnerData$FiCreditorDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$FiCreditorDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$FiCreditorDetails {
        const json: any = o ;
        return new PartnerData$FiCreditorDetails(
         (json.creditorId != null) ? json.creditorId : undefined,
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : undefined,
(json.debtType != null) ? PartnerData$DEBT_TYPE.fromJson(json.debtType) : PartnerData$DEBT_TYPE.DEFAULT_VALUE,
(json.creditType != null) ? PartnerData$CREDIT_TYPE.fromJson(json.creditType) : PartnerData$CREDIT_TYPE.DEFAULT_VALUE,
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined
        );
    }



  


   
    constructor(
        public creditorId: string|undefined = undefined,
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string|undefined = undefined,
public debtType: PartnerData$DEBT_TYPE = PartnerData$DEBT_TYPE.DEFAULT_VALUE,
public creditType: PartnerData$CREDIT_TYPE = PartnerData$CREDIT_TYPE.DEFAULT_VALUE,
public accountNumber: string = '',
public details: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          creditorId: (this.creditorId != null) ? this.creditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ?  PartnerData$DEBT_TYPE.copyMe(this.debtType).toJson() : undefined,
creditType: (this.creditType != null) ?  PartnerData$CREDIT_TYPE.copyMe(this.creditType).toJson() : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined
        };
    }
    



}



export class SpineData$FiBudgetIncomeSources {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetIncomeSources {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetIncomeSources.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetIncomeSources {
        const json: any = o ;
        return new SpineData$FiBudgetIncomeSources(
         (json.salary != null) ? json.salary : 0,
(json.businessIncome != null) ? json.businessIncome : 0,
(json.otherIncome != null) ? json.otherIncome : 0,
(json.familySupport != null) ? json.familySupport : 0
        );
    }



  


   
    constructor(
        public salary: number = 0,
public businessIncome: number = 0,
public otherIncome: number = 0,
public familySupport: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          salary: (this.salary != null) ? this.salary : undefined,
businessIncome: (this.businessIncome != null) ? this.businessIncome : undefined,
otherIncome: (this.otherIncome != null) ? this.otherIncome : undefined,
familySupport: (this.familySupport != null) ? this.familySupport : undefined
        };
    }
    



}



export class PartnerData$PartnerSummary {

   
    public static fromJsonString(jsonString: string): PartnerData$PartnerSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$PartnerSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$PartnerSummary {
        const json: any = o ;
        return new PartnerData$PartnerSummary(
         (json.partnerId != null) ? json.partnerId : '',
(json.fullname != null) ? json.fullname : '',
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.ageRange != null) ? PartnerData$AGE_RANGE.fromJson(json.ageRange) : undefined,
(json.state != null) ? json.state : undefined,
(json.status != null) ? PartnerData$PARTNER_STATUS.fromJson(json.status) : PartnerData$PARTNER_STATUS.DEFAULT_VALUE,
(json.totalEarnings != null) ? json.totalEarnings : 0,
(json.currentEarnings != null) ? json.currentEarnings : 0,
(json.onboardedFilesCount != null) ? json.onboardedFilesCount : 0,
(json.totalDebtEnrolled != null) ? json.totalDebtEnrolled : 0,
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : '',
(json.uniqueReferralLink != null) ? json.uniqueReferralLink : '',
(json.documentList != null) ? (json.documentList as object[]).map((x) => PartnerData$PartnerDocument.fromJson(x)) : [],
(json.registeredOn != null) ? new Date(json.registeredOn) : new Date(),
(json.activatedOn != null) ? new Date(json.activatedOn) : undefined,
(json.partnerType != null) ? PartnerData$PARTNER_TYPE.fromJson(json.partnerType) : PartnerData$PARTNER_TYPE.DEFAULT_VALUE,
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public partnerId: string = '',
public fullname: string = '',
public mobile: string = '',
public emailId: string = '',
public ageRange: PartnerData$AGE_RANGE|undefined = undefined,
public state: string|undefined = undefined,
public status: PartnerData$PARTNER_STATUS = PartnerData$PARTNER_STATUS.DEFAULT_VALUE,
public totalEarnings: number = 0,
public currentEarnings: number = 0,
public onboardedFilesCount: number = 0,
public totalDebtEnrolled: number = 0,
public uniqueReferralCode: string = '',
public uniqueReferralLink: string = '',
public documentList: PartnerData$PartnerDocument[] = [],
public registeredOn: Date = new Date(),
public activatedOn: Date|undefined = undefined,
public partnerType: PartnerData$PARTNER_TYPE = PartnerData$PARTNER_TYPE.DEFAULT_VALUE,
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
fullname: (this.fullname != null) ? this.fullname : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
ageRange: (this.ageRange != null) ? PartnerData$AGE_RANGE.copyMe(this.ageRange).toJson() : undefined,
state: (this.state != null) ? this.state : undefined,
status: (this.status != null) ?  PartnerData$PARTNER_STATUS.copyMe(this.status).toJson() : undefined,
totalEarnings: (this.totalEarnings != null) ? this.totalEarnings : undefined,
currentEarnings: (this.currentEarnings != null) ? this.currentEarnings : undefined,
onboardedFilesCount: (this.onboardedFilesCount != null) ? this.onboardedFilesCount : undefined,
totalDebtEnrolled: (this.totalDebtEnrolled != null) ? this.totalDebtEnrolled : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined,
uniqueReferralLink: (this.uniqueReferralLink != null) ? this.uniqueReferralLink : undefined,
documentList: (this.documentList != null) ? this.documentList.map((x) => x.toJson()) : [],
registeredOn: (this.registeredOn != null) ? this.registeredOn.toISOString() : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn.toISOString() : undefined,
partnerType: (this.partnerType != null) ?  PartnerData$PARTNER_TYPE.copyMe(this.partnerType).toJson() : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class SpineData$FiBudgetIncomeSourcesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetIncomeSourcesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetIncomeSourcesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetIncomeSourcesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetIncomeSourcesDetails(
         (json.salary != null) ? json.salary : undefined,
(json.businessIncome != null) ? json.businessIncome : undefined,
(json.otherIncome != null) ? json.otherIncome : undefined,
(json.familySupport != null) ? json.familySupport : undefined
        );
    }



  


   
    constructor(
        public salary: number|undefined = undefined,
public businessIncome: number|undefined = undefined,
public otherIncome: number|undefined = undefined,
public familySupport: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          salary: (this.salary != null) ? this.salary : undefined,
businessIncome: (this.businessIncome != null) ? this.businessIncome : undefined,
otherIncome: (this.otherIncome != null) ? this.otherIncome : undefined,
familySupport: (this.familySupport != null) ? this.familySupport : undefined
        };
    }
    



}



export class SpineData$FiBudgetDebtRepaymentsDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetDebtRepaymentsDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetDebtRepaymentsDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetDebtRepaymentsDetails {
        const json: any = o ;
        return new SpineData$FiBudgetDebtRepaymentsDetails(
         (json.autoLoan != null) ? json.autoLoan : undefined,
(json.housingLoan != null) ? json.housingLoan : undefined,
(json.loansAgainstSecurity != null) ? json.loansAgainstSecurity : undefined,
(json.collateralizedBussinessLoan != null) ? json.collateralizedBussinessLoan : undefined
        );
    }



  


   
    constructor(
        public autoLoan: number|undefined = undefined,
public housingLoan: number|undefined = undefined,
public loansAgainstSecurity: number|undefined = undefined,
public collateralizedBussinessLoan: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          autoLoan: (this.autoLoan != null) ? this.autoLoan : undefined,
housingLoan: (this.housingLoan != null) ? this.housingLoan : undefined,
loansAgainstSecurity: (this.loansAgainstSecurity != null) ? this.loansAgainstSecurity : undefined,
collateralizedBussinessLoan: (this.collateralizedBussinessLoan != null) ? this.collateralizedBussinessLoan : undefined
        };
    }
    



}



export class InsightData$DailyFunnel {

   
    public static fromJsonString(jsonString: string): InsightData$DailyFunnel {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$DailyFunnel.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$DailyFunnel {
        const json: any = o ;
        return new InsightData$DailyFunnel(
         (json.leadCount != null) ? json.leadCount : 0,
(json.conversionCount != null) ? json.conversionCount : 0,
(json.ddMM != null) ? json.ddMM : '',
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public conversionCount: number = 0,
public ddMM: string = '',
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
conversionCount: (this.conversionCount != null) ? this.conversionCount : undefined,
ddMM: (this.ddMM != null) ? this.ddMM : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    



}



export class SpineData$FiPSEntry {

   
    public static fromJsonString(jsonString: string): SpineData$FiPSEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiPSEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiPSEntry {
        const json: any = o ;
        return new SpineData$FiPSEntry(
         (json.psEntryId != null) ? json.psEntryId : '',
(json.status != null) ? SpineData$PS_ENTRY_STATUS.fromJson(json.status) : SpineData$PS_ENTRY_STATUS.DEFAULT_VALUE,
(json.paymentId != null) ? json.paymentId : undefined,
(json.paymentStatus != null) ? SpineData$PAYMENT_STATUS.fromJson(json.paymentStatus) : undefined,
(json.draftDate != null) ? json.draftDate : '',
(json.spaAmount != null) ? json.spaAmount : 0,
(json.feeAmount != null) ? json.feeAmount : 0,
(json.totalAmount != null) ? json.totalAmount : 0,
(json.statusUpdatedOn != null) ? new Date(json.statusUpdatedOn) : new Date(),
(json.linkedEMandate != null) ? SpineData$FiEMandateSummary.fromJson(json.linkedEMandate) : undefined
        );
    }



  


   
    constructor(
        public psEntryId: string = '',
public status: SpineData$PS_ENTRY_STATUS = SpineData$PS_ENTRY_STATUS.DEFAULT_VALUE,
public paymentId: string|undefined = undefined,
public paymentStatus: SpineData$PAYMENT_STATUS|undefined = undefined,
public draftDate: string = '',
public spaAmount: number = 0,
public feeAmount: number = 0,
public totalAmount: number = 0,
public statusUpdatedOn: Date = new Date(),
public linkedEMandate: SpineData$FiEMandateSummary|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined,
status: (this.status != null) ?  SpineData$PS_ENTRY_STATUS.copyMe(this.status).toJson() : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined,
paymentStatus: (this.paymentStatus != null) ? SpineData$PAYMENT_STATUS.copyMe(this.paymentStatus).toJson() : undefined,
draftDate: (this.draftDate != null) ? this.draftDate : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn.toISOString() : undefined,
linkedEMandate: (this.linkedEMandate != null) ? this.linkedEMandate.toJson() : undefined
        };
    }
    



}



export class SpineData$FiPaymentCalculator {

   
    public static fromJsonString(jsonString: string): SpineData$FiPaymentCalculator {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiPaymentCalculator.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiPaymentCalculator {
        const json: any = o ;
        return new SpineData$FiPaymentCalculator(
         (json.ppCode != null) ? json.ppCode : '',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 0,
(json.settlementFeePercentage != null) ? json.settlementFeePercentage : undefined,
(json.firstDraftDate != null) ? json.firstDraftDate : '',
(json.feeCode != null) ? json.feeCode : '',
(json.feeFirstDraftDate != null) ? json.feeFirstDraftDate : '',
(json.msfDraftDay != null) ? json.msfDraftDay : undefined,
(json.selectedDraftDay != null) ? json.selectedDraftDay : 0,
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.spaDraftDay != null) ? json.spaDraftDay : undefined,
(json.spaDraftAmount != null) ? json.spaDraftAmount : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : undefined,
(json.repaymentAmount != null) ? json.repaymentAmount : undefined
        );
    }



  


   
    constructor(
        public ppCode: string = '',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 0,
public settlementFeePercentage: number|undefined = undefined,
public firstDraftDate: string = '',
public feeCode: string = '',
public feeFirstDraftDate: string = '',
public msfDraftDay: number|undefined = undefined,
public selectedDraftDay: number = 0,
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined,
public msfDraftAmount: number = 0,
public spaDraftDay: number|undefined = undefined,
public spaDraftAmount: number|undefined = undefined,
public totalMonthlyObligation: number|undefined = undefined,
public repaymentAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          ppCode: (this.ppCode != null) ? this.ppCode : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
settlementFeePercentage: (this.settlementFeePercentage != null) ? this.settlementFeePercentage : undefined,
firstDraftDate: (this.firstDraftDate != null) ? this.firstDraftDate : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
feeFirstDraftDate: (this.feeFirstDraftDate != null) ? this.feeFirstDraftDate : undefined,
msfDraftDay: (this.msfDraftDay != null) ? this.msfDraftDay : undefined,
selectedDraftDay: (this.selectedDraftDay != null) ? this.selectedDraftDay : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
spaDraftDay: (this.spaDraftDay != null) ? this.spaDraftDay : undefined,
spaDraftAmount: (this.spaDraftAmount != null) ? this.spaDraftAmount : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
repaymentAmount: (this.repaymentAmount != null) ? this.repaymentAmount : undefined
        };
    }
    



}



export class PartnerData$MSFPayment {

   
    public static fromJsonString(jsonString: string): PartnerData$MSFPayment {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$MSFPayment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$MSFPayment {
        const json: any = o ;
        return new PartnerData$MSFPayment(
         (json.draftDate != null) ? json.draftDate : '',
(json.amount != null) ? json.amount : 0,
(json.status != null) ? json.status : '',
(json.statusUpdatedOn != null) ? new Date(json.statusUpdatedOn) : undefined
        );
    }



  


   
    constructor(
        public draftDate: string = '',
public amount: number = 0,
public status: string = '',
public statusUpdatedOn: Date|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          draftDate: (this.draftDate != null) ? this.draftDate : undefined,
amount: (this.amount != null) ? this.amount : undefined,
status: (this.status != null) ? this.status : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn.toISOString() : undefined
        };
    }
    



}



export class SpineData$FiBudgetInfo {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetInfo {
        const json: any = o ;
        return new SpineData$FiBudgetInfo(
         (json.fullName != null) ? json.fullName : '',
(json.gender != null) ? SpineData$GENDER.fromJson(json.gender) : undefined,
(json.dob != null) ? json.dob : undefined,
(json.age != null) ? json.age : undefined,
(json.hardshipReason != null) ? json.hardshipReason : undefined,
(json.hardshipReasonCodeList != null) ? json.hardshipReasonCodeList : [],
(json.incomeSources != null) ? SpineData$FiBudgetIncomeSources.fromJson(json.incomeSources) : undefined,
(json.debtRepayments != null) ? SpineData$FiBudgetDebtRepayments.fromJson(json.debtRepayments) : undefined,
(json.livingExpenses != null) ? SpineData$FiBudgetLivingExpenses.fromJson(json.livingExpenses) : undefined,
(json.lifeStyleExpenses != null) ? SpineData$FiBudgetLifeStyleExpenses.fromJson(json.lifeStyleExpenses) : undefined,
(json.dependentExpenses != null) ? SpineData$FiBudgetDependentExpenses.fromJson(json.dependentExpenses) : undefined,
(json.incidentalExpenses != null) ? SpineData$FiBudgetIncidentalExpenses.fromJson(json.incidentalExpenses) : undefined,
(json.miscellaneousExpenses != null) ? SpineData$FiBudgetMiscellaneousExpenses.fromJson(json.miscellaneousExpenses) : undefined,
(json.ineligibleUnsecuredDebt != null) ? SpineData$FiBudgetIneligibleUnsecuredDebts.fromJson(json.ineligibleUnsecuredDebt) : new SpineData$FiBudgetIneligibleUnsecuredDebts(),
(json.totalIncome != null) ? json.totalIncome : 0,
(json.totalDebtRepayments != null) ? json.totalDebtRepayments : 0,
(json.totalMonthlyExpense != null) ? json.totalMonthlyExpense : 0,
(json.availableIncome != null) ? json.availableIncome : 0,
(json.proposedDSPayment != null) ? json.proposedDSPayment : 0,
(json.stdiPercentage != null) ? json.stdiPercentage : 0,
(json.hasDependents != null) ? json.hasDependents : undefined,
(json.hasPets != null) ? json.hasPets : undefined,
(json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.hasKids != null) ? json.hasKids : undefined,
(json.rentedHouse != null) ? json.rentedHouse : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.employeeCompanyName != null) ? json.employeeCompanyName : undefined
        );
    }



  


   
    constructor(
        public fullName: string = '',
public gender: SpineData$GENDER|undefined = undefined,
public dob: string|undefined = undefined,
public age: number|undefined = undefined,
public hardshipReason: string|undefined = undefined,
public hardshipReasonCodeList: string[] = [],
public incomeSources: SpineData$FiBudgetIncomeSources|undefined = undefined,
public debtRepayments: SpineData$FiBudgetDebtRepayments|undefined = undefined,
public livingExpenses: SpineData$FiBudgetLivingExpenses|undefined = undefined,
public lifeStyleExpenses: SpineData$FiBudgetLifeStyleExpenses|undefined = undefined,
public dependentExpenses: SpineData$FiBudgetDependentExpenses|undefined = undefined,
public incidentalExpenses: SpineData$FiBudgetIncidentalExpenses|undefined = undefined,
public miscellaneousExpenses: SpineData$FiBudgetMiscellaneousExpenses|undefined = undefined,
public ineligibleUnsecuredDebt: SpineData$FiBudgetIneligibleUnsecuredDebts = new SpineData$FiBudgetIneligibleUnsecuredDebts(),
public totalIncome: number = 0,
public totalDebtRepayments: number = 0,
public totalMonthlyExpense: number = 0,
public availableIncome: number = 0,
public proposedDSPayment: number = 0,
public stdiPercentage: number = 0,
public hasDependents: boolean|undefined = undefined,
public hasPets: boolean|undefined = undefined,
public maritalStatus: string|undefined = undefined,
public hasKids: boolean|undefined = undefined,
public rentedHouse: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public employeeCompanyName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fullName: (this.fullName != null) ? this.fullName : undefined,
gender: (this.gender != null) ? SpineData$GENDER.copyMe(this.gender).toJson() : undefined,
dob: (this.dob != null) ? this.dob : undefined,
age: (this.age != null) ? this.age : undefined,
hardshipReason: (this.hardshipReason != null) ? this.hardshipReason : undefined,
hardshipReasonCodeList: (this.hardshipReasonCodeList != null) ? this.hardshipReasonCodeList : [],
incomeSources: (this.incomeSources != null) ? this.incomeSources.toJson() : undefined,
debtRepayments: (this.debtRepayments != null) ? this.debtRepayments.toJson() : undefined,
livingExpenses: (this.livingExpenses != null) ? this.livingExpenses.toJson() : undefined,
lifeStyleExpenses: (this.lifeStyleExpenses != null) ? this.lifeStyleExpenses.toJson() : undefined,
dependentExpenses: (this.dependentExpenses != null) ? this.dependentExpenses.toJson() : undefined,
incidentalExpenses: (this.incidentalExpenses != null) ? this.incidentalExpenses.toJson() : undefined,
miscellaneousExpenses: (this.miscellaneousExpenses != null) ? this.miscellaneousExpenses.toJson() : undefined,
ineligibleUnsecuredDebt: (this.ineligibleUnsecuredDebt != null) ? this.ineligibleUnsecuredDebt.toJson() : undefined,
totalIncome: (this.totalIncome != null) ? this.totalIncome : undefined,
totalDebtRepayments: (this.totalDebtRepayments != null) ? this.totalDebtRepayments : undefined,
totalMonthlyExpense: (this.totalMonthlyExpense != null) ? this.totalMonthlyExpense : undefined,
availableIncome: (this.availableIncome != null) ? this.availableIncome : undefined,
proposedDSPayment: (this.proposedDSPayment != null) ? this.proposedDSPayment : undefined,
stdiPercentage: (this.stdiPercentage != null) ? this.stdiPercentage : undefined,
hasDependents: (this.hasDependents != null) ? this.hasDependents : undefined,
hasPets: (this.hasPets != null) ? this.hasPets : undefined,
maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
hasKids: (this.hasKids != null) ? this.hasKids : undefined,
rentedHouse: (this.rentedHouse != null) ? this.rentedHouse : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
employeeCompanyName: (this.employeeCompanyName != null) ? this.employeeCompanyName : undefined
        };
    }
    



}



export class PartnerData$OnboardedClientFileDetails {

   
    public static fromJsonString(jsonString: string): PartnerData$OnboardedClientFileDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$OnboardedClientFileDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$OnboardedClientFileDetails {
        const json: any = o ;
        return new PartnerData$OnboardedClientFileDetails(
         (json.clientFileDetails != null) ? SpineData$ClientFileBasicInfo.fromJson(json.clientFileDetails) : new SpineData$ClientFileBasicInfo(),
(json.psPlan != null) ? SpineData$FiPaymentCalculator.fromJson(json.psPlan) : undefined,
(json.msfPaymentList != null) ? (json.msfPaymentList as object[]).map((x) => PartnerData$MSFPayment.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientFileDetails: SpineData$ClientFileBasicInfo = new SpineData$ClientFileBasicInfo(),
public psPlan: SpineData$FiPaymentCalculator|undefined = undefined,
public msfPaymentList: PartnerData$MSFPayment[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileDetails: (this.clientFileDetails != null) ? this.clientFileDetails.toJson() : undefined,
psPlan: (this.psPlan != null) ? this.psPlan.toJson() : undefined,
msfPaymentList: (this.msfPaymentList != null) ? this.msfPaymentList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SpineData$ClAddress {

   
    public static fromJsonString(jsonString: string): SpineData$ClAddress {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$ClAddress.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$ClAddress {
        const json: any = o ;
        return new SpineData$ClAddress(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class SpineData$SPASummary {

   
    public static fromJsonString(jsonString: string): SpineData$SPASummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$SPASummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$SPASummary {
        const json: any = o ;
        return new SpineData$SPASummary(
         (json.spaAmount != null) ? json.spaAmount : 0,
(json.spaDay != null) ? json.spaDay : 0,
(json.upcomingSPAScheduledEntry != null) ? SpineData$FiScheduledEntry.fromJson(json.upcomingSPAScheduledEntry) : new SpineData$FiScheduledEntry(),
(json.pastSPAScheduledEntryList != null) ? (json.pastSPAScheduledEntryList as object[]).map((x) => SpineData$FiScheduledEntry.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public spaAmount: number = 0,
public spaDay: number = 0,
public upcomingSPAScheduledEntry: SpineData$FiScheduledEntry = new SpineData$FiScheduledEntry(),
public pastSPAScheduledEntryList: SpineData$FiScheduledEntry[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
spaDay: (this.spaDay != null) ? this.spaDay : undefined,
upcomingSPAScheduledEntry: (this.upcomingSPAScheduledEntry != null) ? this.upcomingSPAScheduledEntry.toJson() : undefined,
pastSPAScheduledEntryList: (this.pastSPAScheduledEntryList != null) ? this.pastSPAScheduledEntryList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SpineData$FiBudgetLifeStyleExpenses {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetLifeStyleExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetLifeStyleExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetLifeStyleExpenses {
        const json: any = o ;
        return new SpineData$FiBudgetLifeStyleExpenses(
         (json.travelExpense != null) ? json.travelExpense : 0,
(json.digitalSubscriptionsExpense != null) ? json.digitalSubscriptionsExpense : 0,
(json.diningOutExpense != null) ? json.diningOutExpense : 0,
(json.houseHelpExpense != null) ? json.houseHelpExpense : 0,
(json.outingExpense != null) ? json.outingExpense : 0
        );
    }



  


   
    constructor(
        public travelExpense: number = 0,
public digitalSubscriptionsExpense: number = 0,
public diningOutExpense: number = 0,
public houseHelpExpense: number = 0,
public outingExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          travelExpense: (this.travelExpense != null) ? this.travelExpense : undefined,
digitalSubscriptionsExpense: (this.digitalSubscriptionsExpense != null) ? this.digitalSubscriptionsExpense : undefined,
diningOutExpense: (this.diningOutExpense != null) ? this.diningOutExpense : undefined,
houseHelpExpense: (this.houseHelpExpense != null) ? this.houseHelpExpense : undefined,
outingExpense: (this.outingExpense != null) ? this.outingExpense : undefined
        };
    }
    



}



export class SpineData$FiBudgetDependentExpensesDetails {

   
    public static fromJsonString(jsonString: string): SpineData$FiBudgetDependentExpensesDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$FiBudgetDependentExpensesDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$FiBudgetDependentExpensesDetails {
        const json: any = o ;
        return new SpineData$FiBudgetDependentExpensesDetails(
         (json.medicalExpense != null) ? json.medicalExpense : undefined,
(json.essentialCareExpense != null) ? json.essentialCareExpense : undefined,
(json.schoolFeeExpense != null) ? json.schoolFeeExpense : undefined,
(json.schoolBusExpense != null) ? json.schoolBusExpense : undefined,
(json.booksAndProjectsExpense != null) ? json.booksAndProjectsExpense : undefined,
(json.nursingAttendExpense != null) ? json.nursingAttendExpense : undefined,
(json.petCareExpense != null) ? json.petCareExpense : undefined
        );
    }



  


   
    constructor(
        public medicalExpense: number|undefined = undefined,
public essentialCareExpense: number|undefined = undefined,
public schoolFeeExpense: number|undefined = undefined,
public schoolBusExpense: number|undefined = undefined,
public booksAndProjectsExpense: number|undefined = undefined,
public nursingAttendExpense: number|undefined = undefined,
public petCareExpense: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          medicalExpense: (this.medicalExpense != null) ? this.medicalExpense : undefined,
essentialCareExpense: (this.essentialCareExpense != null) ? this.essentialCareExpense : undefined,
schoolFeeExpense: (this.schoolFeeExpense != null) ? this.schoolFeeExpense : undefined,
schoolBusExpense: (this.schoolBusExpense != null) ? this.schoolBusExpense : undefined,
booksAndProjectsExpense: (this.booksAndProjectsExpense != null) ? this.booksAndProjectsExpense : undefined,
nursingAttendExpense: (this.nursingAttendExpense != null) ? this.nursingAttendExpense : undefined,
petCareExpense: (this.petCareExpense != null) ? this.petCareExpense : undefined
        };
    }
    



}



export class PartnerData$PartnerClientDetails {

   
    public static fromJsonString(jsonString: string): PartnerData$PartnerClientDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerData$PartnerClientDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerData$PartnerClientDetails {
        const json: any = o ;
        return new PartnerData$PartnerClientDetails(
         (json.partnerClientId != null) ? json.partnerClientId : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.fullName != null) ? json.fullName : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.partnerId != null) ? json.partnerId : '',
(json.partnerFullName != null) ? json.partnerFullName : '',
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : '',
(json.mobileVerified != null) ? json.mobileVerified : false,
(json.programCode != null) ? SpineData$PROGRAM_CODE.fromJson(json.programCode) : SpineData$PROGRAM_CODE.DEFAULT_VALUE,
(json.note != null) ? json.note : undefined,
(json.registeredOn != null) ? new Date(json.registeredOn) : new Date()
        );
    }



  


   
    constructor(
        public partnerClientId: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public fullName: string = '',
public emailId: string = '',
public mobile: string = '',
public partnerId: string = '',
public partnerFullName: string = '',
public uniqueReferralCode: string = '',
public mobileVerified: boolean = false,
public programCode: SpineData$PROGRAM_CODE = SpineData$PROGRAM_CODE.DEFAULT_VALUE,
public note: string|undefined = undefined,
public registeredOn: Date = new Date()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerClientId: (this.partnerClientId != null) ? this.partnerClientId : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
partnerId: (this.partnerId != null) ? this.partnerId : undefined,
partnerFullName: (this.partnerFullName != null) ? this.partnerFullName : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined,
mobileVerified: (this.mobileVerified != null) ? this.mobileVerified : undefined,
programCode: (this.programCode != null) ?  SpineData$PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined,
note: (this.note != null) ? this.note : undefined,
registeredOn: (this.registeredOn != null) ? this.registeredOn.toISOString() : undefined
        };
    }
    



}



export class InsightData$DailySalesData {

   
    public static fromJsonString(jsonString: string): InsightData$DailySalesData {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightData$DailySalesData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightData$DailySalesData {
        const json: any = o ;
        return new InsightData$DailySalesData(
         (json.saleDate != null) ? json.saleDate : '',
(json.aoCount != null) ? json.aoCount : 0,
(json.aomCount != null) ? json.aomCount : 0,
(json.aompCount != null) ? json.aompCount : 0,
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public saleDate: string = '',
public aoCount: number = 0,
public aomCount: number = 0,
public aompCount: number = 0,
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          saleDate: (this.saleDate != null) ? this.saleDate : undefined,
aoCount: (this.aoCount != null) ? this.aoCount : undefined,
aomCount: (this.aomCount != null) ? this.aomCount : undefined,
aompCount: (this.aompCount != null) ? this.aompCount : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class SpineData$EnrollmentSummary {

   
    public static fromJsonString(jsonString: string): SpineData$EnrollmentSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$EnrollmentSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$EnrollmentSummary {
        const json: any = o ;
        return new SpineData$EnrollmentSummary(
         (json.isCreditorDone != null) ? json.isCreditorDone : false,
(json.isBudgetDone != null) ? json.isBudgetDone : false,
(json.isPaymentPlanCreated != null) ? json.isPaymentPlanCreated : false,
(json.isAggrementSigned != null) ? json.isAggrementSigned : undefined,
(json.initiateNewEMandate != null) ? json.initiateNewEMandate : false,
(json.isEmandateInitiated != null) ? json.isEmandateInitiated : false,
(json.isEmandateDone != null) ? json.isEmandateDone : false,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.exceptionTakenOnHardship != null) ? json.exceptionTakenOnHardship : false,
(json.isCreditReportPulled != null) ? json.isCreditReportPulled : false,
(json.isCreateClientVANeeded != null) ? json.isCreateClientVANeeded : false
        );
    }



  


   
    constructor(
        public isCreditorDone: boolean = false,
public isBudgetDone: boolean = false,
public isPaymentPlanCreated: boolean = false,
public isAggrementSigned: boolean|undefined = undefined,
public initiateNewEMandate: boolean = false,
public isEmandateInitiated: boolean = false,
public isEmandateDone: boolean = false,
public isFirstMSFPaid: boolean = false,
public exceptionTakenOnHardship: boolean = false,
public isCreditReportPulled: boolean = false,
public isCreateClientVANeeded: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          isCreditorDone: (this.isCreditorDone != null) ? this.isCreditorDone : undefined,
isBudgetDone: (this.isBudgetDone != null) ? this.isBudgetDone : undefined,
isPaymentPlanCreated: (this.isPaymentPlanCreated != null) ? this.isPaymentPlanCreated : undefined,
isAggrementSigned: (this.isAggrementSigned != null) ? this.isAggrementSigned : undefined,
initiateNewEMandate: (this.initiateNewEMandate != null) ? this.initiateNewEMandate : undefined,
isEmandateInitiated: (this.isEmandateInitiated != null) ? this.isEmandateInitiated : undefined,
isEmandateDone: (this.isEmandateDone != null) ? this.isEmandateDone : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
exceptionTakenOnHardship: (this.exceptionTakenOnHardship != null) ? this.exceptionTakenOnHardship : undefined,
isCreditReportPulled: (this.isCreditReportPulled != null) ? this.isCreditReportPulled : undefined,
isCreateClientVANeeded: (this.isCreateClientVANeeded != null) ? this.isCreateClientVANeeded : undefined
        };
    }
    



}



export class SpineData$MSFSummary {

   
    public static fromJsonString(jsonString: string): SpineData$MSFSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return SpineData$MSFSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SpineData$MSFSummary {
        const json: any = o ;
        return new SpineData$MSFSummary(
         (json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.msfDay != null) ? json.msfDay : 0,
(json.upcomingMSFScheduledEntry != null) ? SpineData$FiScheduledEntry.fromJson(json.upcomingMSFScheduledEntry) : new SpineData$FiScheduledEntry(),
(json.pastMSFScheduledEntryList != null) ? (json.pastMSFScheduledEntryList as object[]).map((x) => SpineData$FiScheduledEntry.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public msfDraftAmount: number = 0,
public msfDay: number = 0,
public upcomingMSFScheduledEntry: SpineData$FiScheduledEntry = new SpineData$FiScheduledEntry(),
public pastMSFScheduledEntryList: SpineData$FiScheduledEntry[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
msfDay: (this.msfDay != null) ? this.msfDay : undefined,
upcomingMSFScheduledEntry: (this.upcomingMSFScheduledEntry != null) ? this.upcomingMSFScheduledEntry.toJson() : undefined,
pastMSFScheduledEntryList: (this.pastMSFScheduledEntryList != null) ? this.pastMSFScheduledEntryList.map((x) => x.toJson()) : []
        };
    }
    



}




  

  export namespace PartnerWebWriter {

    

      export class RequestOnboarding$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebWriter.RequestOnboarding$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.RequestOnboarding$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.RequestOnboarding$Input {
        const json: any = o ;
        return new PartnerWebWriter.RequestOnboarding$Input(
         (json.partnerId != null) ? json.partnerId : ''
        );
    }


          
    constructor(
        public partnerId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined
        };
    }
    


      }

     

      


      export class UploadCertificate$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebWriter.UploadCertificate$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.UploadCertificate$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.UploadCertificate$Input {
        const json: any = o ;
        return new PartnerWebWriter.UploadCertificate$Input(
         (json.partnerId != null) ? json.partnerId : '',
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined
        );
    }


          
    constructor(
        public partnerId: string = '',
public documentPath: string = '',
public documentDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    


      }

     

      


      export class AddPartnerClient$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebWriter.AddPartnerClient$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.AddPartnerClient$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.AddPartnerClient$Input {
        const json: any = o ;
        return new PartnerWebWriter.AddPartnerClient$Input(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.leadSource != null) ? json.leadSource : undefined,
(json.note != null) ? json.note : undefined,
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : '',
(json.missedEmiInLast3Months != null) ? json.missedEmiInLast3Months : undefined,
(json.fppClientRemoteId != null) ? json.fppClientRemoteId : undefined,
(json.creditorList != null) ? (json.creditorList as object[]).map((x) => PartnerData$FiCreditorDetails.fromJson(x)) : [],
(json.clientDetails != null) ? PartnerData$ClientDetails.fromJson(json.clientDetails) : undefined,
(json.clientAddress != null) ? PartnerData$ClientAddress.fromJson(json.clientAddress) : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.partnerClientToken != null) ? json.partnerClientToken : undefined
        );
    }


          
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = '',
public leadSource: string|undefined = undefined,
public note: string|undefined = undefined,
public uniqueReferralCode: string = '',
public missedEmiInLast3Months: boolean|undefined = undefined,
public fppClientRemoteId: string|undefined = undefined,
public creditorList: PartnerData$FiCreditorDetails[] = [],
public clientDetails: PartnerData$ClientDetails|undefined = undefined,
public clientAddress: PartnerData$ClientAddress|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public partnerClientToken: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined,
note: (this.note != null) ? this.note : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined,
missedEmiInLast3Months: (this.missedEmiInLast3Months != null) ? this.missedEmiInLast3Months : undefined,
fppClientRemoteId: (this.fppClientRemoteId != null) ? this.fppClientRemoteId : undefined,
creditorList: (this.creditorList != null) ? this.creditorList.map((x) => x.toJson()) : [],
clientDetails: (this.clientDetails != null) ? this.clientDetails.toJson() : undefined,
clientAddress: (this.clientAddress != null) ? this.clientAddress.toJson() : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
partnerClientToken: (this.partnerClientToken != null) ? this.partnerClientToken : undefined
        };
    }
    


      }

     
      export class AddPartnerClient$Output {
           
    public static fromJsonString(jsonString: string): PartnerWebWriter.AddPartnerClient$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.AddPartnerClient$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.AddPartnerClient$Output {
        const json: any = o ;
        return new PartnerWebWriter.AddPartnerClient$Output(
         (json.partnerClientId != null) ? json.partnerClientId : ''
        );
    }


           
    constructor(
        public partnerClientId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerClientId: (this.partnerClientId != null) ? this.partnerClientId : undefined
        };
    }
    


      }
      

      


      export class SendOTP$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebWriter.SendOTP$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.SendOTP$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.SendOTP$Input {
        const json: any = o ;
        return new PartnerWebWriter.SendOTP$Input(
         (json.mobile != null) ? json.mobile : '',
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : ''
        );
    }


          
    constructor(
        public mobile: string = '',
public uniqueReferralCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined
        };
    }
    


      }

     

      


      export class ValidateAndRegisterPartnerClientFile$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input {
        const json: any = o ;
        return new PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input(
         (json.partnerClientId != null) ? json.partnerClientId : '',
(json.otp != null) ? json.otp : ''
        );
    }


          
    constructor(
        public partnerClientId: string = '',
public otp: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerClientId: (this.partnerClientId != null) ? this.partnerClientId : undefined,
otp: (this.otp != null) ? this.otp : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace ClientFileWebWriter {

    

      export class AttachDocument$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.AttachDocument$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.AttachDocument$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.AttachDocument$Input {
        const json: any = o ;
        return new ClientFileWebWriter.AttachDocument$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.documentType != null) ? json.documentType : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.documentPath != null) ? json.documentPath : '',
(json.signedOn != null) ? new Date(json.signedOn) : undefined,
(json.ipAddr != null) ? json.ipAddr : undefined,
(json.fiCreditorId != null) ? json.fiCreditorId : undefined
        );
    }


          
    constructor(
        public clientFileId: string = '',
public documentType: string = '',
public documentDetails: string|undefined = undefined,
public documentPath: string = '',
public signedOn: Date|undefined = undefined,
public ipAddr: string|undefined = undefined,
public fiCreditorId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
signedOn: (this.signedOn != null) ? this.signedOn.toISOString() : undefined,
ipAddr: (this.ipAddr != null) ? this.ipAddr : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    


      }

     
      export class AttachDocument$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.AttachDocument$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.AttachDocument$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.AttachDocument$Output {
        const json: any = o ;
        return new ClientFileWebWriter.AttachDocument$Output(
         (json.fiDocumentId != null) ? json.fiDocumentId : ''
        );
    }


           
    constructor(
        public fiDocumentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined
        };
    }
    


      }
      

      


      export class DetachDocument$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.DetachDocument$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.DetachDocument$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.DetachDocument$Input {
        const json: any = o ;
        return new ClientFileWebWriter.DetachDocument$Input(
         (json.fiDocumentId != null) ? json.fiDocumentId : ''
        );
    }


          
    constructor(
        public fiDocumentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined
        };
    }
    


      }

     

      


      export class ParseCreditReport$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.ParseCreditReport$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.ParseCreditReport$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.ParseCreditReport$Input {
        const json: any = o ;
        return new ClientFileWebWriter.ParseCreditReport$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditBureau != null) ? json.creditBureau : '',
(json.password != null) ? json.password : undefined,
(json.filename != null) ? json.filename : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public creditBureau: string = '',
public password: string|undefined = undefined,
public filename: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
password: (this.password != null) ? this.password : undefined,
filename: (this.filename != null) ? this.filename : undefined
        };
    }
    


      }

     
      export class ParseCreditReport$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.ParseCreditReport$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.ParseCreditReport$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.ParseCreditReport$Output {
        const json: any = o ;
        return new ClientFileWebWriter.ParseCreditReport$Output(
         (json.clCreditorCreditReportInfo != null) ? SpineData$ClCreditorCreditReportInfo.fromJson(json.clCreditorCreditReportInfo) : new SpineData$ClCreditorCreditReportInfo(),
(json.personalInfo != null) ? SpineData$CreditReportPersonalInfo.fromJson(json.personalInfo) : new SpineData$CreditReportPersonalInfo()
        );
    }


           
    constructor(
        public clCreditorCreditReportInfo: SpineData$ClCreditorCreditReportInfo = new SpineData$ClCreditorCreditReportInfo(),
public personalInfo: SpineData$CreditReportPersonalInfo = new SpineData$CreditReportPersonalInfo()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorCreditReportInfo: (this.clCreditorCreditReportInfo != null) ? this.clCreditorCreditReportInfo.toJson() : undefined,
personalInfo: (this.personalInfo != null) ? this.personalInfo.toJson() : undefined
        };
    }
    


      }
      

      


      export class UploadDocument$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UploadDocument$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UploadDocument$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UploadDocument$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UploadDocument$Input(
         (json.fileId != null) ? json.fileId : '',
(json.docType != null) ? json.docType : '',
(json.fileDoc != null) ? json.fileDoc : undefined
        );
    }


          
    constructor(
        public fileId: string = '',
public docType: string = '',
public fileDoc: File|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fileId: (this.fileId != null) ? this.fileId : undefined,
docType: (this.docType != null) ? this.docType : undefined,
fileDoc: (this.fileDoc != null) ? this.fileDoc : undefined
        };
    }
    
    public toFormData(): FormData {
      const fd = new FormData();
      if (this.fileId != null) {fd.append('fileId', this.fileId.toString()); }
if (this.docType != null) {fd.append('docType', this.docType.toString()); }
if (this.fileDoc != null) {fd.append('fileDoc', this.fileDoc, this.fileDoc.name); }
      return fd;
    }
    


      }

     

      


      export class AddFiCreditor$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.AddFiCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.AddFiCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.AddFiCreditor$Input {
        const json: any = o ;
        return new ClientFileWebWriter.AddFiCreditor$Input(
         (json.clientId != null) ? json.clientId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined
        );
    }


          
    constructor(
        public clientId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined
        };
    }
    


      }

     
      export class AddFiCreditor$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.AddFiCreditor$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.AddFiCreditor$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.AddFiCreditor$Output {
        const json: any = o ;
        return new ClientFileWebWriter.AddFiCreditor$Output(
         (json.fiCreditorId != null) ? json.fiCreditorId : ''
        );
    }


           
    constructor(
        public fiCreditorId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    


      }
      

      


      export class IncludeInProgram$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.IncludeInProgram$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.IncludeInProgram$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.IncludeInProgram$Input {
        const json: any = o ;
        return new ClientFileWebWriter.IncludeInProgram$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clCreditorId != null) ? json.clCreditorId : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public clCreditorId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined
        };
    }
    


      }

     

      


      export class ExcludeFromProgram$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.ExcludeFromProgram$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.ExcludeFromProgram$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.ExcludeFromProgram$Input {
        const json: any = o ;
        return new ClientFileWebWriter.ExcludeFromProgram$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.fiCreditorId != null) ? json.fiCreditorId : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public fiCreditorId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    


      }

     

      


      export class UpdateFiCreditor$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateFiCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateFiCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateFiCreditor$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateFiCreditor$Input(
         (json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined
        );
    }


          
    constructor(
        public fiCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined
        };
    }
    


      }

     

      


      export class UpdateIncludeClCreditor$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateIncludeClCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateIncludeClCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateIncludeClCreditor$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateIncludeClCreditor$Input(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }


          
    constructor(
        public clCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    


      }

     

      


      export class UpdateCreditInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateCreditInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateCreditInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateCreditInfo$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateCreditInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditScore != null) ? json.creditScore : 0,
(json.creditBureau != null) ? json.creditBureau : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public creditScore: number = 0,
public creditBureau: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined
        };
    }
    


      }

     

      


      export class UpdateBudgetInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateBudgetInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateBudgetInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateBudgetInfo$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateBudgetInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.hardshipReason != null) ? json.hardshipReason : '',
(json.hardshipReasonCodeList != null) ? json.hardshipReasonCodeList : [],
(json.incomeSources != null) ? SpineData$FiBudgetIncomeSourcesDetails.fromJson(json.incomeSources) : new SpineData$FiBudgetIncomeSourcesDetails(),
(json.debtRepayments != null) ? SpineData$FiBudgetDebtRepaymentsDetails.fromJson(json.debtRepayments) : new SpineData$FiBudgetDebtRepaymentsDetails(),
(json.livingExpenses != null) ? SpineData$FiBudgetLivingExpensesDetails.fromJson(json.livingExpenses) : new SpineData$FiBudgetLivingExpensesDetails(),
(json.lifeStyleExpenses != null) ? SpineData$FiBudgetLifeStyleExpensesDetails.fromJson(json.lifeStyleExpenses) : new SpineData$FiBudgetLifeStyleExpensesDetails(),
(json.dependentExpenses != null) ? SpineData$FiBudgetDependentExpensesDetails.fromJson(json.dependentExpenses) : new SpineData$FiBudgetDependentExpensesDetails(),
(json.incidentalExpenses != null) ? SpineData$FiBudgetIncidentalExpensesDetails.fromJson(json.incidentalExpenses) : new SpineData$FiBudgetIncidentalExpensesDetails(),
(json.miscellaneousExpenses != null) ? SpineData$FiBudgetMiscellaneousExpensesDetails.fromJson(json.miscellaneousExpenses) : new SpineData$FiBudgetMiscellaneousExpensesDetails(),
(json.hasDependents != null) ? json.hasDependents : undefined,
(json.hasPets != null) ? json.hasPets : undefined,
(json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.hasKids != null) ? json.hasKids : undefined,
(json.rentedHouse != null) ? json.rentedHouse : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.employeeCompanyName != null) ? json.employeeCompanyName : undefined
        );
    }


          
    constructor(
        public clientFileId: string = '',
public hardshipReason: string = '',
public hardshipReasonCodeList: string[] = [],
public incomeSources: SpineData$FiBudgetIncomeSourcesDetails = new SpineData$FiBudgetIncomeSourcesDetails(),
public debtRepayments: SpineData$FiBudgetDebtRepaymentsDetails = new SpineData$FiBudgetDebtRepaymentsDetails(),
public livingExpenses: SpineData$FiBudgetLivingExpensesDetails = new SpineData$FiBudgetLivingExpensesDetails(),
public lifeStyleExpenses: SpineData$FiBudgetLifeStyleExpensesDetails = new SpineData$FiBudgetLifeStyleExpensesDetails(),
public dependentExpenses: SpineData$FiBudgetDependentExpensesDetails = new SpineData$FiBudgetDependentExpensesDetails(),
public incidentalExpenses: SpineData$FiBudgetIncidentalExpensesDetails = new SpineData$FiBudgetIncidentalExpensesDetails(),
public miscellaneousExpenses: SpineData$FiBudgetMiscellaneousExpensesDetails = new SpineData$FiBudgetMiscellaneousExpensesDetails(),
public hasDependents: boolean|undefined = undefined,
public hasPets: boolean|undefined = undefined,
public maritalStatus: string|undefined = undefined,
public hasKids: boolean|undefined = undefined,
public rentedHouse: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public employeeCompanyName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
hardshipReason: (this.hardshipReason != null) ? this.hardshipReason : undefined,
hardshipReasonCodeList: (this.hardshipReasonCodeList != null) ? this.hardshipReasonCodeList : [],
incomeSources: (this.incomeSources != null) ? this.incomeSources.toJson() : undefined,
debtRepayments: (this.debtRepayments != null) ? this.debtRepayments.toJson() : undefined,
livingExpenses: (this.livingExpenses != null) ? this.livingExpenses.toJson() : undefined,
lifeStyleExpenses: (this.lifeStyleExpenses != null) ? this.lifeStyleExpenses.toJson() : undefined,
dependentExpenses: (this.dependentExpenses != null) ? this.dependentExpenses.toJson() : undefined,
incidentalExpenses: (this.incidentalExpenses != null) ? this.incidentalExpenses.toJson() : undefined,
miscellaneousExpenses: (this.miscellaneousExpenses != null) ? this.miscellaneousExpenses.toJson() : undefined,
hasDependents: (this.hasDependents != null) ? this.hasDependents : undefined,
hasPets: (this.hasPets != null) ? this.hasPets : undefined,
maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
hasKids: (this.hasKids != null) ? this.hasKids : undefined,
rentedHouse: (this.rentedHouse != null) ? this.rentedHouse : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
employeeCompanyName: (this.employeeCompanyName != null) ? this.employeeCompanyName : undefined
        };
    }
    


      }

     

      


      export class UpdateBankInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateBankInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateBankInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateBankInfo$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateBankInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? SpineData$ACCOUNT_TYPE.fromJson(json.accountType) : SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? SpineData$FiBankAddress.fromJson(json.bankAddress) : new SpineData$FiBankAddress(),
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: SpineData$ACCOUNT_TYPE = SpineData$ACCOUNT_TYPE.DEFAULT_VALUE,
public accountHolderName: string = '',
public bankAddress: SpineData$FiBankAddress = new SpineData$FiBankAddress(),
public nupayBankMasterId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ?  SpineData$ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined
        };
    }
    


      }

     

      


      export class GenerateAgreement$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.GenerateAgreement$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.GenerateAgreement$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.GenerateAgreement$Input {
        const json: any = o ;
        return new ClientFileWebWriter.GenerateAgreement$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     
      export class GenerateAgreement$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.GenerateAgreement$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.GenerateAgreement$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.GenerateAgreement$Output {
        const json: any = o ;
        return new ClientFileWebWriter.GenerateAgreement$Output(
         (json.ssaToken != null) ? json.ssaToken : ''
        );
    }


           
    constructor(
        public ssaToken: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          ssaToken: (this.ssaToken != null) ? this.ssaToken : undefined
        };
    }
    


      }
      

      


      export class InitiateEMandate$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.InitiateEMandate$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.InitiateEMandate$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.InitiateEMandate$Input {
        const json: any = o ;
        return new ClientFileWebWriter.InitiateEMandate$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientBankInfo != null) ? SpineData$EMandateBankInfo.fromJson(json.clientBankInfo) : new SpineData$EMandateBankInfo(),
(json.collectionAmount != null) ? json.collectionAmount : undefined,
(json.draftDay != null) ? json.draftDay : undefined,
(json.authMode != null) ? SpineData$NACH_AUTH_MODE.fromJson(json.authMode) : undefined,
(json.byForce != null) ? json.byForce : undefined
        );
    }


          
    constructor(
        public clientFileId: string = '',
public clientBankInfo: SpineData$EMandateBankInfo = new SpineData$EMandateBankInfo(),
public collectionAmount: number|undefined = undefined,
public draftDay: number|undefined = undefined,
public authMode: SpineData$NACH_AUTH_MODE|undefined = undefined,
public byForce: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientBankInfo: (this.clientBankInfo != null) ? this.clientBankInfo.toJson() : undefined,
collectionAmount: (this.collectionAmount != null) ? this.collectionAmount : undefined,
draftDay: (this.draftDay != null) ? this.draftDay : undefined,
authMode: (this.authMode != null) ? SpineData$NACH_AUTH_MODE.copyMe(this.authMode).toJson() : undefined,
byForce: (this.byForce != null) ? this.byForce : undefined
        };
    }
    


      }

     
      export class InitiateEMandate$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.InitiateEMandate$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.InitiateEMandate$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.InitiateEMandate$Output {
        const json: any = o ;
        return new ClientFileWebWriter.InitiateEMandate$Output(
         (json.eMandateId != null) ? json.eMandateId : ''
        );
    }


           
    constructor(
        public eMandateId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined
        };
    }
    


      }
      

      


      export class CheckAndUpdateEMandate$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.CheckAndUpdateEMandate$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.CheckAndUpdateEMandate$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.CheckAndUpdateEMandate$Input {
        const json: any = o ;
        return new ClientFileWebWriter.CheckAndUpdateEMandate$Input(
         (json.eMandateId != null) ? json.eMandateId : ''
        );
    }


          
    constructor(
        public eMandateId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined
        };
    }
    


      }

     
      export class CheckAndUpdateEMandate$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebWriter.CheckAndUpdateEMandate$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.CheckAndUpdateEMandate$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.CheckAndUpdateEMandate$Output {
        const json: any = o ;
        return new ClientFileWebWriter.CheckAndUpdateEMandate$Output(
         (json.status != null) ? SpineData$EMANDATE_STATUS.fromJson(json.status) : SpineData$EMANDATE_STATUS.DEFAULT_VALUE
        );
    }


           
    constructor(
        public status: SpineData$EMANDATE_STATUS = SpineData$EMANDATE_STATUS.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          status: (this.status != null) ?  SpineData$EMANDATE_STATUS.copyMe(this.status).toJson() : undefined
        };
    }
    


      }
      

      


      export class SendEmandate$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.SendEmandate$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.SendEmandate$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.SendEmandate$Input {
        const json: any = o ;
        return new ClientFileWebWriter.SendEmandate$Input(
         (json.fiEMandateId != null) ? json.fiEMandateId : '',
(json.byEmail != null) ? json.byEmail : false,
(json.byWhatsapp != null) ? json.byWhatsapp : false,
(json.bySMS != null) ? json.bySMS : false
        );
    }


          
    constructor(
        public fiEMandateId: string = '',
public byEmail: boolean = false,
public byWhatsapp: boolean = false,
public bySMS: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiEMandateId: (this.fiEMandateId != null) ? this.fiEMandateId : undefined,
byEmail: (this.byEmail != null) ? this.byEmail : undefined,
byWhatsapp: (this.byWhatsapp != null) ? this.byWhatsapp : undefined,
bySMS: (this.bySMS != null) ? this.bySMS : undefined
        };
    }
    


      }

     

      


      export class MarkEMandateAsDefault$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.MarkEMandateAsDefault$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.MarkEMandateAsDefault$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.MarkEMandateAsDefault$Input {
        const json: any = o ;
        return new ClientFileWebWriter.MarkEMandateAsDefault$Input(
         (json.fiEMandateId != null) ? json.fiEMandateId : ''
        );
    }


          
    constructor(
        public fiEMandateId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiEMandateId: (this.fiEMandateId != null) ? this.fiEMandateId : undefined
        };
    }
    


      }

     

      


      export class UpdateExceptionTakenList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebWriter.UpdateExceptionTakenList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebWriter.UpdateExceptionTakenList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebWriter.UpdateExceptionTakenList$Input {
        const json: any = o ;
        return new ClientFileWebWriter.UpdateExceptionTakenList$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.exceptionTakenList != null) ? (json.exceptionTakenList as string[]).map((x) => SpineData$EXCEPTION_TAKEN.fromJson(x)) : [],
(json.exceptionApprovedBy != null) ? json.exceptionApprovedBy : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public exceptionTakenList: SpineData$EXCEPTION_TAKEN[] = [],
public exceptionApprovedBy: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
exceptionTakenList: (this.exceptionTakenList != null) ? this.exceptionTakenList.map((x) => SpineData$EXCEPTION_TAKEN.copyMe(x).toJson()) : [],
exceptionApprovedBy: (this.exceptionApprovedBy != null) ? this.exceptionApprovedBy : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace DocumentUploadWebWriter {

    

      export class AttachDocument$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadWebWriter.AttachDocument$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebWriter.AttachDocument$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebWriter.AttachDocument$Input {
        const json: any = o ;
        return new DocumentUploadWebWriter.AttachDocument$Input(
         (json.documentRefType != null) ? SpineData$DOCUMENT_REF_TYPE.fromJson(json.documentRefType) : SpineData$DOCUMENT_REF_TYPE.DEFAULT_VALUE,
(json.documentRefId != null) ? json.documentRefId : '',
(json.documentType != null) ? json.documentType : undefined,
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.documentPath != null) ? json.documentPath : ''
        );
    }


          
    constructor(
        public documentRefType: SpineData$DOCUMENT_REF_TYPE = SpineData$DOCUMENT_REF_TYPE.DEFAULT_VALUE,
public documentRefId: string = '',
public documentType: string|undefined = undefined,
public documentDetails: string|undefined = undefined,
public documentPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentRefType: (this.documentRefType != null) ?  SpineData$DOCUMENT_REF_TYPE.copyMe(this.documentRefType).toJson() : undefined,
documentRefId: (this.documentRefId != null) ? this.documentRefId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined
        };
    }
    


      }

     
      export class AttachDocument$Output {
           
    public static fromJsonString(jsonString: string): DocumentUploadWebWriter.AttachDocument$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebWriter.AttachDocument$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebWriter.AttachDocument$Output {
        const json: any = o ;
        return new DocumentUploadWebWriter.AttachDocument$Output(
         (json.documentId != null) ? json.documentId : ''
        );
    }


           
    constructor(
        public documentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentId: (this.documentId != null) ? this.documentId : undefined
        };
    }
    


      }
      

      


      export class DetachDocument$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadWebWriter.DetachDocument$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebWriter.DetachDocument$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebWriter.DetachDocument$Input {
        const json: any = o ;
        return new DocumentUploadWebWriter.DetachDocument$Input(
         (json.documentId != null) ? json.documentId : ''
        );
    }


          
    constructor(
        public documentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentId: (this.documentId != null) ? this.documentId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace PaymentWebWriter {

    

      export class CheckAndUpdatePaymentStatus$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebWriter.CheckAndUpdatePaymentStatus$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebWriter.CheckAndUpdatePaymentStatus$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebWriter.CheckAndUpdatePaymentStatus$Input {
        const json: any = o ;
        return new PaymentWebWriter.CheckAndUpdatePaymentStatus$Input(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     
      export class CheckAndUpdatePaymentStatus$Output {
           
    public static fromJsonString(jsonString: string): PaymentWebWriter.CheckAndUpdatePaymentStatus$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebWriter.CheckAndUpdatePaymentStatus$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebWriter.CheckAndUpdatePaymentStatus$Output {
        const json: any = o ;
        return new PaymentWebWriter.CheckAndUpdatePaymentStatus$Output(
         (json.paymentStatus != null) ? SpineData$PAYMENT_STATUS.fromJson(json.paymentStatus) : SpineData$PAYMENT_STATUS.DEFAULT_VALUE,
(json.isFirstMSF != null) ? json.isFirstMSF : false
        );
    }


           
    constructor(
        public paymentStatus: SpineData$PAYMENT_STATUS = SpineData$PAYMENT_STATUS.DEFAULT_VALUE,
public isFirstMSF: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentStatus: (this.paymentStatus != null) ?  SpineData$PAYMENT_STATUS.copyMe(this.paymentStatus).toJson() : undefined,
isFirstMSF: (this.isFirstMSF != null) ? this.isFirstMSF : undefined
        };
    }
    


      }
      

      


      export class RequestFundSplit$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebWriter.RequestFundSplit$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebWriter.RequestFundSplit$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebWriter.RequestFundSplit$Input {
        const json: any = o ;
        return new PaymentWebWriter.RequestFundSplit$Input(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     

      


      export class UpdateFundSplitStatus$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebWriter.UpdateFundSplitStatus$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebWriter.UpdateFundSplitStatus$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebWriter.UpdateFundSplitStatus$Input {
        const json: any = o ;
        return new PaymentWebWriter.UpdateFundSplitStatus$Input(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     

      


      export class CancelPayment$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebWriter.CancelPayment$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebWriter.CancelPayment$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebWriter.CancelPayment$Input {
        const json: any = o ;
        return new PaymentWebWriter.CancelPayment$Input(
         (json.paymentId != null) ? json.paymentId : '',
(json.reason != null) ? json.reason : undefined
        );
    }


          
    constructor(
        public paymentId: string = '',
public reason: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined,
reason: (this.reason != null) ? this.reason : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace MisReportsWebReader {

    

      export class GetDailySalesData$Input {
          
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetDailySalesData$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetDailySalesData$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetDailySalesData$Input {
        const json: any = o ;
        return new MisReportsWebReader.GetDailySalesData$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }

     
      export class GetDailySalesData$Output {
           
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetDailySalesData$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetDailySalesData$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetDailySalesData$Output {
        const json: any = o ;
        return new MisReportsWebReader.GetDailySalesData$Output(
         (json.dailySalesDataList != null) ? (json.dailySalesDataList as object[]).map((x) => InsightData$DailySalesData.fromJson(x)) : []
        );
    }


           
    constructor(
        public dailySalesDataList: InsightData$DailySalesData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          dailySalesDataList: (this.dailySalesDataList != null) ? this.dailySalesDataList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetSaleCountDayWiseList$Input {
          
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetSaleCountDayWiseList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetSaleCountDayWiseList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetSaleCountDayWiseList$Input {
        const json: any = o ;
        return new MisReportsWebReader.GetSaleCountDayWiseList$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }

     
      export class GetSaleCountDayWiseList$Output {
           
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetSaleCountDayWiseList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetSaleCountDayWiseList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetSaleCountDayWiseList$Output {
        const json: any = o ;
        return new MisReportsWebReader.GetSaleCountDayWiseList$Output(
         (json.daywiseCountList != null) ? (json.daywiseCountList as object[]).map((x) => InsightData$DaywiseCount.fromJson(x)) : []
        );
    }


           
    constructor(
        public daywiseCountList: InsightData$DaywiseCount[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          daywiseCountList: (this.daywiseCountList != null) ? this.daywiseCountList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetMandateCountDayWiseList$Input {
          
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetMandateCountDayWiseList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetMandateCountDayWiseList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetMandateCountDayWiseList$Input {
        const json: any = o ;
        return new MisReportsWebReader.GetMandateCountDayWiseList$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }

     
      export class GetMandateCountDayWiseList$Output {
           
    public static fromJsonString(jsonString: string): MisReportsWebReader.GetMandateCountDayWiseList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MisReportsWebReader.GetMandateCountDayWiseList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MisReportsWebReader.GetMandateCountDayWiseList$Output {
        const json: any = o ;
        return new MisReportsWebReader.GetMandateCountDayWiseList$Output(
         (json.daywiseCountList != null) ? (json.daywiseCountList as object[]).map((x) => InsightData$DaywiseCount.fromJson(x)) : []
        );
    }


           
    constructor(
        public daywiseCountList: InsightData$DaywiseCount[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          daywiseCountList: (this.daywiseCountList != null) ? this.daywiseCountList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


  }

  



  export namespace ClientWebWriter {

    

      export class RegisterClient$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.RegisterClient$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.RegisterClient$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.RegisterClient$Input {
        const json: any = o ;
        return new ClientWebWriter.RegisterClient$Input(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : "INH"
        );
    }


          
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string = "INH"
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined
        };
    }
    


      }

     
      export class RegisterClient$Output {
           
    public static fromJsonString(jsonString: string): ClientWebWriter.RegisterClient$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.RegisterClient$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.RegisterClient$Output {
        const json: any = o ;
        return new ClientWebWriter.RegisterClient$Output(
         (json.clientId != null) ? json.clientId : ''
        );
    }


           
    constructor(
        public clientId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined
        };
    }
    


      }
      

      


      export class UpdateContactInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.UpdateContactInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.UpdateContactInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.UpdateContactInfo$Input {
        const json: any = o ;
        return new ClientWebWriter.UpdateContactInfo$Input(
         (json.clientId != null) ? json.clientId : '',
(json.newEmailId != null) ? json.newEmailId : undefined,
(json.newMobile != null) ? json.newMobile : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }


          
    constructor(
        public clientId: string = '',
public newEmailId: string|undefined = undefined,
public newMobile: string|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
newEmailId: (this.newEmailId != null) ? this.newEmailId : undefined,
newMobile: (this.newMobile != null) ? this.newMobile : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    


      }

     

      


      export class UpdateClientDetails$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.UpdateClientDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.UpdateClientDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.UpdateClientDetails$Input {
        const json: any = o ;
        return new ClientWebWriter.UpdateClientDetails$Input(
         (json.clientId != null) ? json.clientId : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.newEmailId != null) ? json.newEmailId : undefined,
(json.newMobile != null) ? json.newMobile : undefined,
(json.dob != null) ? json.dob : undefined,
(json.secondaryPhone != null) ? json.secondaryPhone : undefined
        );
    }


          
    constructor(
        public clientId: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public newEmailId: string|undefined = undefined,
public newMobile: string|undefined = undefined,
public dob: string|undefined = undefined,
public secondaryPhone: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
newEmailId: (this.newEmailId != null) ? this.newEmailId : undefined,
newMobile: (this.newMobile != null) ? this.newMobile : undefined,
dob: (this.dob != null) ? this.dob : undefined,
secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined
        };
    }
    


      }

     

      


      export class UpdateClPersonalInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.UpdateClPersonalInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.UpdateClPersonalInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.UpdateClPersonalInfo$Input {
        const json: any = o ;
        return new ClientWebWriter.UpdateClPersonalInfo$Input(
         (json.clientId != null) ? json.clientId : '',
(json.secondaryPhone != null) ? json.secondaryPhone : '',
(json.motherMaidenName != null) ? json.motherMaidenName : '',
(json.fatherName != null) ? json.fatherName : '',
(json.pan != null) ? json.pan : '',
(json.dob != null) ? json.dob : '',
(json.gender != null) ? SpineData$GENDER.fromJson(json.gender) : SpineData$GENDER.DEFAULT_VALUE,
(json.residentialAddress != null) ? SpineData$ClAddressDetails.fromJson(json.residentialAddress) : new SpineData$ClAddressDetails(),
(json.clientLanguage != null) ? json.clientLanguage : undefined
        );
    }


          
    constructor(
        public clientId: string = '',
public secondaryPhone: string = '',
public motherMaidenName: string = '',
public fatherName: string = '',
public pan: string = '',
public dob: string = '',
public gender: SpineData$GENDER = SpineData$GENDER.DEFAULT_VALUE,
public residentialAddress: SpineData$ClAddressDetails = new SpineData$ClAddressDetails(),
public clientLanguage: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined,
motherMaidenName: (this.motherMaidenName != null) ? this.motherMaidenName : undefined,
fatherName: (this.fatherName != null) ? this.fatherName : undefined,
pan: (this.pan != null) ? this.pan : undefined,
dob: (this.dob != null) ? this.dob : undefined,
gender: (this.gender != null) ?  SpineData$GENDER.copyMe(this.gender).toJson() : undefined,
residentialAddress: (this.residentialAddress != null) ? this.residentialAddress.toJson() : undefined,
clientLanguage: (this.clientLanguage != null) ? this.clientLanguage : undefined
        };
    }
    


      }

     

      


      export class RemoveClCreditor$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.RemoveClCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.RemoveClCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.RemoveClCreditor$Input {
        const json: any = o ;
        return new ClientWebWriter.RemoveClCreditor$Input(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.reason != null) ? json.reason : undefined
        );
    }


          
    constructor(
        public clCreditorId: string = '',
public reason: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
reason: (this.reason != null) ? this.reason : undefined
        };
    }
    


      }

     

      


      export class UpdateClCreditor$Input {
          
    public static fromJsonString(jsonString: string): ClientWebWriter.UpdateClCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebWriter.UpdateClCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebWriter.UpdateClCreditor$Input {
        const json: any = o ;
        return new ClientWebWriter.UpdateClCreditor$Input(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientId != null) ? json.clientId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined
        );
    }


          
    constructor(
        public clCreditorId: string = '',
public clientId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientId: (this.clientId != null) ? this.clientId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace PartnerWebReader {

    

      export class FindPartnerDetails$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.FindPartnerDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.FindPartnerDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.FindPartnerDetails$Input {
        const json: any = o ;
        return new PartnerWebReader.FindPartnerDetails$Input(
         (json.partnerId != null) ? json.partnerId : ''
        );
    }


          
    constructor(
        public partnerId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined
        };
    }
    


      }

     

      


      export class GetReferralOrgCode$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetReferralOrgCode$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetReferralOrgCode$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetReferralOrgCode$Input {
        const json: any = o ;
        return new PartnerWebReader.GetReferralOrgCode$Input(
         (json.uniqueReferralCode != null) ? json.uniqueReferralCode : ''
        );
    }


          
    constructor(
        public uniqueReferralCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined
        };
    }
    


      }

     
      export class GetReferralOrgCode$Output {
           
    public static fromJsonString(jsonString: string): PartnerWebReader.GetReferralOrgCode$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetReferralOrgCode$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetReferralOrgCode$Output {
        const json: any = o ;
        return new PartnerWebReader.GetReferralOrgCode$Output(
         (json.orgCode != null) ? json.orgCode : ''
        );
    }


           
    constructor(
        public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }
      

      


      export class GetOnboardedFileList$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetOnboardedFileList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetOnboardedFileList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetOnboardedFileList$Input {
        const json: any = o ;
        return new PartnerWebReader.GetOnboardedFileList$Input(
         (json.partnerId != null) ? json.partnerId : '',
(json.fromDate != null) ? json.fromDate : undefined,
(json.toDate != null) ? json.toDate : undefined
        );
    }


          
    constructor(
        public partnerId: string = '',
public fromDate: string|undefined = undefined,
public toDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class GetUnverifiedFileList$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetUnverifiedFileList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetUnverifiedFileList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetUnverifiedFileList$Input {
        const json: any = o ;
        return new PartnerWebReader.GetUnverifiedFileList$Input(
         (json.partnerId != null) ? json.partnerId : ''
        );
    }


          
    constructor(
        public partnerId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined
        };
    }
    


      }

     

      


      export class GetPartnerCertificate$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetPartnerCertificate$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetPartnerCertificate$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetPartnerCertificate$Input {
        const json: any = o ;
        return new PartnerWebReader.GetPartnerCertificate$Input(
         (json.partnerId != null) ? json.partnerId : ''
        );
    }


          
    constructor(
        public partnerId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined
        };
    }
    


      }

     

      


      export class GetOnboardedClientDetails$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetOnboardedClientDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetOnboardedClientDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetOnboardedClientDetails$Input {
        const json: any = o ;
        return new PartnerWebReader.GetOnboardedClientDetails$Input(
         (json.onboardedClientFileId != null) ? json.onboardedClientFileId : ''
        );
    }


          
    constructor(
        public onboardedClientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          onboardedClientFileId: (this.onboardedClientFileId != null) ? this.onboardedClientFileId : undefined
        };
    }
    


      }

     

      


      export class GetNotEnrolledPartnerClientList$Input {
          
    public static fromJsonString(jsonString: string): PartnerWebReader.GetNotEnrolledPartnerClientList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetNotEnrolledPartnerClientList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetNotEnrolledPartnerClientList$Input {
        const json: any = o ;
        return new PartnerWebReader.GetNotEnrolledPartnerClientList$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public offset: number = 0,
public count: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined
        };
    }
    


      }

     
      export class GetNotEnrolledPartnerClientList$Output {
           
    public static fromJsonString(jsonString: string): PartnerWebReader.GetNotEnrolledPartnerClientList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerWebReader.GetNotEnrolledPartnerClientList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerWebReader.GetNotEnrolledPartnerClientList$Output {
        const json: any = o ;
        return new PartnerWebReader.GetNotEnrolledPartnerClientList$Output(
         (json.totalCount != null) ? json.totalCount : 0,
(json.notEnrolledPartnerClientList != null) ? (json.notEnrolledPartnerClientList as object[]).map((x) => PartnerData$NotEnrolledPartnerClient.fromJson(x)) : []
        );
    }


           
    constructor(
        public totalCount: number = 0,
public notEnrolledPartnerClientList: PartnerData$NotEnrolledPartnerClient[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
notEnrolledPartnerClientList: (this.notEnrolledPartnerClientList != null) ? this.notEnrolledPartnerClientList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


  }

  



  export namespace FiCreditorWebReader {

    

      export class GetFiCreditorInfo$Input {
          
    public static fromJsonString(jsonString: string): FiCreditorWebReader.GetFiCreditorInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiCreditorWebReader.GetFiCreditorInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiCreditorWebReader.GetFiCreditorInfo$Input {
        const json: any = o ;
        return new FiCreditorWebReader.GetFiCreditorInfo$Input(
         (json.fiCreditorId != null) ? json.fiCreditorId : ''
        );
    }


          
    constructor(
        public fiCreditorId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    


      }

     

      


      export class GetFiCreditorSifDocumentList$Input {
          
    public static fromJsonString(jsonString: string): FiCreditorWebReader.GetFiCreditorSifDocumentList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiCreditorWebReader.GetFiCreditorSifDocumentList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiCreditorWebReader.GetFiCreditorSifDocumentList$Input {
        const json: any = o ;
        return new FiCreditorWebReader.GetFiCreditorSifDocumentList$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.fiCreditorId != null) ? json.fiCreditorId : ''
        );
    }


          
    constructor(
        public clientFileId: string = '',
public fiCreditorId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace AmendmentWebWriter {

    

      export class UpdateIncludeClCreditor$Input {
          
    public static fromJsonString(jsonString: string): AmendmentWebWriter.UpdateIncludeClCreditor$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return AmendmentWebWriter.UpdateIncludeClCreditor$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AmendmentWebWriter.UpdateIncludeClCreditor$Input {
        const json: any = o ;
        return new AmendmentWebWriter.UpdateIncludeClCreditor$Input(
         (json.amendmentToken != null) ? json.amendmentToken : '',
(json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }


          
    constructor(
        public amendmentToken: string = '',
public clCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          amendmentToken: (this.amendmentToken != null) ? this.amendmentToken : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace DspFlowWebWriter {

    

      export class RegisterAndEnroll$Input {
          
    public static fromJsonString(jsonString: string): DspFlowWebWriter.RegisterAndEnroll$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DspFlowWebWriter.RegisterAndEnroll$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspFlowWebWriter.RegisterAndEnroll$Input {
        const json: any = o ;
        return new DspFlowWebWriter.RegisterAndEnroll$Input(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : "KFS",
(json.mode != null) ? json.mode : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }


          
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string = "KFS",
public mode: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined,
mode: (this.mode != null) ? this.mode : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }

     
      export class RegisterAndEnroll$Output {
           
    public static fromJsonString(jsonString: string): DspFlowWebWriter.RegisterAndEnroll$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DspFlowWebWriter.RegisterAndEnroll$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspFlowWebWriter.RegisterAndEnroll$Output {
        const json: any = o ;
        return new DspFlowWebWriter.RegisterAndEnroll$Output(
         (json.clientFileNumber != null) ? json.clientFileNumber : ''
        );
    }


           
    constructor(
        public clientFileNumber: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace GompaWebReader {

    

      export class GetUserDetails$Input {
          
    public static fromJsonString(jsonString: string): GompaWebReader.GetUserDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebReader.GetUserDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebReader.GetUserDetails$Input {
        const json: any = o ;
        return new GompaWebReader.GetUserDetails$Input(
         (json.userName != null) ? json.userName : ''
        );
    }


          
    constructor(
        public userName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined
        };
    }
    


      }

     

      


      export class GetUserOrgCode$Input {
          
    public static fromJsonString(jsonString: string): GompaWebReader.GetUserOrgCode$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebReader.GetUserOrgCode$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebReader.GetUserOrgCode$Input {
        const json: any = o ;
        return new GompaWebReader.GetUserOrgCode$Input(
         (json.userName != null) ? json.userName : ''
        );
    }


          
    constructor(
        public userName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined
        };
    }
    


      }

     
      export class GetUserOrgCode$Output {
           
    public static fromJsonString(jsonString: string): GompaWebReader.GetUserOrgCode$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebReader.GetUserOrgCode$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebReader.GetUserOrgCode$Output {
        const json: any = o ;
        return new GompaWebReader.GetUserOrgCode$Output(
         (json.orgCode != null) ? json.orgCode : ''
        );
    }


           
    constructor(
        public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace GompaWebWriter {

    

      export class Login$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.Login$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.Login$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.Login$Input {
        const json: any = o ;
        return new GompaWebWriter.Login$Input(
         (json.userName != null) ? json.userName : '',
(json.password != null) ? json.password : ''
        );
    }


          
    constructor(
        public userName: string = '',
public password: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
password: (this.password != null) ? this.password : undefined
        };
    }
    


      }

     

      


      export class GetRolesForAppId$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.GetRolesForAppId$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.GetRolesForAppId$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.GetRolesForAppId$Input {
        const json: any = o ;
        return new GompaWebWriter.GetRolesForAppId$Input(
         (json.appId != null) ? json.appId : ''
        );
    }


          
    constructor(
        public appId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          appId: (this.appId != null) ? this.appId : undefined
        };
    }
    


      }

     
      export class GetRolesForAppId$Output {
           
    public static fromJsonString(jsonString: string): GompaWebWriter.GetRolesForAppId$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.GetRolesForAppId$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.GetRolesForAppId$Output {
        const json: any = o ;
        return new GompaWebWriter.GetRolesForAppId$Output(
         (json.userName != null) ? json.userName : '',
(json.roleList != null) ? json.roleList : []
        );
    }


           
    constructor(
        public userName: string = '',
public roleList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
roleList: (this.roleList != null) ? this.roleList : []
        };
    }
    


      }
      

      


      export class LoginToSpine$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.LoginToSpine$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.LoginToSpine$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.LoginToSpine$Input {
        const json: any = o ;
        return new GompaWebWriter.LoginToSpine$Input(
         (json.userName != null) ? json.userName : '',
(json.password != null) ? json.password : '',
(json.appId != null) ? json.appId : ''
        );
    }


          
    constructor(
        public userName: string = '',
public password: string = '',
public appId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
password: (this.password != null) ? this.password : undefined,
appId: (this.appId != null) ? this.appId : undefined
        };
    }
    


      }

     

      


      export class SendOTP$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.SendOTP$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.SendOTP$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.SendOTP$Input {
        const json: any = o ;
        return new GompaWebWriter.SendOTP$Input(
         (json.mobile != null) ? json.mobile : ''
        );
    }


          
    constructor(
        public mobile: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined
        };
    }
    


      }

     

      


      export class ValidateOTP$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.ValidateOTP$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.ValidateOTP$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.ValidateOTP$Input {
        const json: any = o ;
        return new GompaWebWriter.ValidateOTP$Input(
         (json.mobile != null) ? json.mobile : '',
(json.otp != null) ? json.otp : ''
        );
    }


          
    constructor(
        public mobile: string = '',
public otp: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
otp: (this.otp != null) ? this.otp : undefined
        };
    }
    


      }

     

      


      export class ResetPassword$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.ResetPassword$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.ResetPassword$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.ResetPassword$Input {
        const json: any = o ;
        return new GompaWebWriter.ResetPassword$Input(
         (json.mobile != null) ? json.mobile : '',
(json.newPassword != null) ? json.newPassword : ''
        );
    }


          
    constructor(
        public mobile: string = '',
public newPassword: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
newPassword: (this.newPassword != null) ? this.newPassword : undefined
        };
    }
    


      }

     

      


      export class SignUp$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.SignUp$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.SignUp$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.SignUp$Input {
        const json: any = o ;
        return new GompaWebWriter.SignUp$Input(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.password != null) ? json.password : '',
(json.ageRange != null) ? PartnerData$AGE_RANGE.fromJson(json.ageRange) : undefined,
(json.state != null) ? json.state : undefined
        );
    }


          
    constructor(
        public firstName: string = '',
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = '',
public password: string = '',
public ageRange: PartnerData$AGE_RANGE|undefined = undefined,
public state: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
password: (this.password != null) ? this.password : undefined,
ageRange: (this.ageRange != null) ? PartnerData$AGE_RANGE.copyMe(this.ageRange).toJson() : undefined,
state: (this.state != null) ? this.state : undefined
        };
    }
    


      }

     

      


      export class ChangeDetails$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.ChangeDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.ChangeDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.ChangeDetails$Input {
        const json: any = o ;
        return new GompaWebWriter.ChangeDetails$Input(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : ''
        );
    }


          
    constructor(
        public firstName: string = '',
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined
        };
    }
    


      }

     

      


      export class GetLeadSquareId$Input {
          
    public static fromJsonString(jsonString: string): GompaWebWriter.GetLeadSquareId$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.GetLeadSquareId$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.GetLeadSquareId$Input {
        const json: any = o ;
        return new GompaWebWriter.GetLeadSquareId$Input(
         (json.emailId != null) ? json.emailId : ''
        );
    }


          
    constructor(
        public emailId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          emailId: (this.emailId != null) ? this.emailId : undefined
        };
    }
    


      }

     
      export class GetLeadSquareId$Output {
           
    public static fromJsonString(jsonString: string): GompaWebWriter.GetLeadSquareId$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return GompaWebWriter.GetLeadSquareId$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GompaWebWriter.GetLeadSquareId$Output {
        const json: any = o ;
        return new GompaWebWriter.GetLeadSquareId$Output(
         (json.leadSquareId != null) ? json.leadSquareId : ''
        );
    }


           
    constructor(
        public leadSquareId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadSquareId: (this.leadSquareId != null) ? this.leadSquareId : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace PaymentScheduleEntryWebWriter {

    

      export class AddPSEntry$Input {
          
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.AddPSEntry$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.AddPSEntry$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.AddPSEntry$Input {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.AddPSEntry$Input(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.paymentProvider != null) ? SpineData$PAYMENT_PROVIDER.fromJson(json.paymentProvider) : SpineData$PAYMENT_PROVIDER.DEFAULT_VALUE,
(json.paymentMode != null) ? SpineData$PAYMENT_MODE.fromJson(json.paymentMode) : undefined,
(json.eMandateId != null) ? json.eMandateId : undefined,
(json.draftDate != null) ? json.draftDate : '',
(json.spaAmount != null) ? json.spaAmount : 0,
(json.feeAmount != null) ? json.feeAmount : 0,
(json.freedPointsAmount != null) ? json.freedPointsAmount : undefined
        );
    }


          
    constructor(
        public psPlanId: string = '',
public paymentProvider: SpineData$PAYMENT_PROVIDER = SpineData$PAYMENT_PROVIDER.DEFAULT_VALUE,
public paymentMode: SpineData$PAYMENT_MODE|undefined = undefined,
public eMandateId: string|undefined = undefined,
public draftDate: string = '',
public spaAmount: number = 0,
public feeAmount: number = 0,
public freedPointsAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
paymentProvider: (this.paymentProvider != null) ?  SpineData$PAYMENT_PROVIDER.copyMe(this.paymentProvider).toJson() : undefined,
paymentMode: (this.paymentMode != null) ? SpineData$PAYMENT_MODE.copyMe(this.paymentMode).toJson() : undefined,
eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
draftDate: (this.draftDate != null) ? this.draftDate : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
freedPointsAmount: (this.freedPointsAmount != null) ? this.freedPointsAmount : undefined
        };
    }
    


      }

     
      export class AddPSEntry$Output {
           
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.AddPSEntry$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.AddPSEntry$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.AddPSEntry$Output {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.AddPSEntry$Output(
         (json.psEntryId != null) ? json.psEntryId : ''
        );
    }


           
    constructor(
        public psEntryId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined
        };
    }
    


      }
      

      


      export class PresentPSEntry$Input {
          
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.PresentPSEntry$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.PresentPSEntry$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.PresentPSEntry$Input {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.PresentPSEntry$Input(
         (json.psEntryId != null) ? json.psEntryId : ''
        );
    }


          
    constructor(
        public psEntryId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined
        };
    }
    


      }

     

      


      export class RemovePSEntryList$Input {
          
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.RemovePSEntryList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.RemovePSEntryList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.RemovePSEntryList$Input {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.RemovePSEntryList$Input(
         (json.psEntryIdList != null) ? json.psEntryIdList : []
        );
    }


          
    constructor(
        public psEntryIdList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryIdList: (this.psEntryIdList != null) ? this.psEntryIdList : []
        };
    }
    


      }

     

      


      export class ModifyAmountWithFixedTenure$Input {
          
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input(
         (json.psEntryIdList != null) ? json.psEntryIdList : [],
(json.spaAmount != null) ? json.spaAmount : 0,
(json.feeAmount != null) ? json.feeAmount : 0,
(json.freedPointsAmount != null) ? json.freedPointsAmount : undefined
        );
    }


          
    constructor(
        public psEntryIdList: string[] = [],
public spaAmount: number = 0,
public feeAmount: number = 0,
public freedPointsAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryIdList: (this.psEntryIdList != null) ? this.psEntryIdList : [],
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
freedPointsAmount: (this.freedPointsAmount != null) ? this.freedPointsAmount : undefined
        };
    }
    


      }

     

      


      export class Skip$Input {
          
    public static fromJsonString(jsonString: string): PaymentScheduleEntryWebWriter.Skip$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentScheduleEntryWebWriter.Skip$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentScheduleEntryWebWriter.Skip$Input {
        const json: any = o ;
        return new PaymentScheduleEntryWebWriter.Skip$Input(
         (json.psEntryId != null) ? json.psEntryId : ''
        );
    }


          
    constructor(
        public psEntryId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace DocumentUploadP2PWebReader {

    

      export class GetPresignedURLForUpload$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input {
        const json: any = o ;
        return new DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input(
         (json.partnerId != null) ? json.partnerId : '',
(json.fileName != null) ? json.fileName : ''
        );
    }


          
    constructor(
        public partnerId: string = '',
public fileName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    


      }

     
      export class GetPresignedURLForUpload$Output {
           
    public static fromJsonString(jsonString: string): DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output {
        const json: any = o ;
        return new DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output(
         (json.url != null) ? json.url : '',
(json.docUploadedPath != null) ? json.docUploadedPath : ''
        );
    }


           
    constructor(
        public url: string = '',
public docUploadedPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          url: (this.url != null) ? this.url : undefined,
docUploadedPath: (this.docUploadedPath != null) ? this.docUploadedPath : undefined
        };
    }
    


      }
      

      


      export class GetUploadedUrl$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadP2PWebReader.GetUploadedUrl$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadP2PWebReader.GetUploadedUrl$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadP2PWebReader.GetUploadedUrl$Input {
        const json: any = o ;
        return new DocumentUploadP2PWebReader.GetUploadedUrl$Input(
         (json.key != null) ? json.key : ''
        );
    }


          
    constructor(
        public key: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined
        };
    }
    


      }

     
      export class GetUploadedUrl$Output {
           
    public static fromJsonString(jsonString: string): DocumentUploadP2PWebReader.GetUploadedUrl$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadP2PWebReader.GetUploadedUrl$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadP2PWebReader.GetUploadedUrl$Output {
        const json: any = o ;
        return new DocumentUploadP2PWebReader.GetUploadedUrl$Output(
         (json.uploadedUrl != null) ? json.uploadedUrl : ''
        );
    }


           
    constructor(
        public uploadedUrl: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          uploadedUrl: (this.uploadedUrl != null) ? this.uploadedUrl : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace FiNoteWebReader {

    

      export class GetFiNoteList$Input {
          
    public static fromJsonString(jsonString: string): FiNoteWebReader.GetFiNoteList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebReader.GetFiNoteList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebReader.GetFiNoteList$Input {
        const json: any = o ;
        return new FiNoteWebReader.GetFiNoteList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace ClientWebReader {

    

      export class GetClientBasicInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientWebReader.GetClientBasicInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebReader.GetClientBasicInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebReader.GetClientBasicInfo$Input {
        const json: any = o ;
        return new ClientWebReader.GetClientBasicInfo$Input(
         (json.clientId != null) ? json.clientId : ''
        );
    }


          
    constructor(
        public clientId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined
        };
    }
    


      }

     

      


      export class GetClientFileBasicInfoList$Input {
          
    public static fromJsonString(jsonString: string): ClientWebReader.GetClientFileBasicInfoList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebReader.GetClientFileBasicInfoList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebReader.GetClientFileBasicInfoList$Input {
        const json: any = o ;
        return new ClientWebReader.GetClientFileBasicInfoList$Input(
         (json.clientId != null) ? json.clientId : ''
        );
    }


          
    constructor(
        public clientId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined
        };
    }
    


      }

     

      


      export class GetClCreditorList$Input {
          
    public static fromJsonString(jsonString: string): ClientWebReader.GetClCreditorList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebReader.GetClCreditorList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebReader.GetClCreditorList$Input {
        const json: any = o ;
        return new ClientWebReader.GetClCreditorList$Input(
         (json.clientId != null) ? json.clientId : ''
        );
    }


          
    constructor(
        public clientId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined
        };
    }
    


      }

     

      


      export class SearchClient$Input {
          
    public static fromJsonString(jsonString: string): ClientWebReader.SearchClient$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientWebReader.SearchClient$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientWebReader.SearchClient$Input {
        const json: any = o ;
        return new ClientWebReader.SearchClient$Input(
         (json.nameContains != null) ? json.nameContains : undefined,
(json.emailId != null) ? json.emailId : undefined,
(json.mobileContains != null) ? json.mobileContains : undefined
        );
    }


          
    constructor(
        public nameContains: string|undefined = undefined,
public emailId: string|undefined = undefined,
public mobileContains: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nameContains: (this.nameContains != null) ? this.nameContains : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobileContains: (this.mobileContains != null) ? this.mobileContains : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace PaymentSchedulePlanWebReader {

    

      export class GetAccountBalanceList$Input {
          
    public static fromJsonString(jsonString: string): PaymentSchedulePlanWebReader.GetAccountBalanceList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentSchedulePlanWebReader.GetAccountBalanceList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentSchedulePlanWebReader.GetAccountBalanceList$Input {
        const json: any = o ;
        return new PaymentSchedulePlanWebReader.GetAccountBalanceList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     
      export class GetAccountBalanceList$Output {
           
    public static fromJsonString(jsonString: string): PaymentSchedulePlanWebReader.GetAccountBalanceList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentSchedulePlanWebReader.GetAccountBalanceList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentSchedulePlanWebReader.GetAccountBalanceList$Output {
        const json: any = o ;
        return new PaymentSchedulePlanWebReader.GetAccountBalanceList$Output(
         (json.misAccountBalanceEntryList != null) ? (json.misAccountBalanceEntryList as object[]).map((x) => SpineData$MISAccountBalanceEntry.fromJson(x)) : [],
(json.totalRecordCount != null) ? json.totalRecordCount : 0
        );
    }


           
    constructor(
        public misAccountBalanceEntryList: SpineData$MISAccountBalanceEntry[] = [],
public totalRecordCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          misAccountBalanceEntryList: (this.misAccountBalanceEntryList != null) ? this.misAccountBalanceEntryList.map((x) => x.toJson()) : [],
totalRecordCount: (this.totalRecordCount != null) ? this.totalRecordCount : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace PaymentSchedulePlanWebWriter {

    

      export class DraftPSPlanForPM$Input {
          
    public static fromJsonString(jsonString: string): PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input {
        const json: any = o ;
        return new PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.outstanding != null) ? json.outstanding : undefined,
(json.tenor != null) ? json.tenor : undefined,
(json.settlementPercentage != null) ? json.settlementPercentage : undefined,
(json.settlementFee != null) ? json.settlementFee : undefined,
(json.spaFirstDraftDate != null) ? json.spaFirstDraftDate : undefined,
(json.msfFirstDraftDate != null) ? json.msfFirstDraftDate : undefined
        );
    }


          
    constructor(
        public clientFileId: string = '',
public outstanding: number|undefined = undefined,
public tenor: number|undefined = undefined,
public settlementPercentage: number|undefined = undefined,
public settlementFee: number|undefined = undefined,
public spaFirstDraftDate: string|undefined = undefined,
public msfFirstDraftDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
settlementFee: (this.settlementFee != null) ? this.settlementFee : undefined,
spaFirstDraftDate: (this.spaFirstDraftDate != null) ? this.spaFirstDraftDate : undefined,
msfFirstDraftDate: (this.msfFirstDraftDate != null) ? this.msfFirstDraftDate : undefined
        };
    }
    


      }

     
      export class DraftPSPlanForPM$Output {
           
    public static fromJsonString(jsonString: string): PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output {
        const json: any = o ;
        return new PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output(
         (json.psPlanId != null) ? json.psPlanId : ''
        );
    }


           
    constructor(
        public psPlanId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined
        };
    }
    


      }
      

      


      export class RecalculatePSPlanForPM$Input {
          
    public static fromJsonString(jsonString: string): PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input {
        const json: any = o ;
        return new PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 0,
(json.settlementFee != null) ? json.settlementFee : undefined,
(json.spaFirstDraftDate != null) ? json.spaFirstDraftDate : '',
(json.msfFirstDraftDate != null) ? json.msfFirstDraftDate : undefined
        );
    }


          
    constructor(
        public psPlanId: string = '',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 0,
public settlementFee: number|undefined = undefined,
public spaFirstDraftDate: string = '',
public msfFirstDraftDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
settlementFee: (this.settlementFee != null) ? this.settlementFee : undefined,
spaFirstDraftDate: (this.spaFirstDraftDate != null) ? this.spaFirstDraftDate : undefined,
msfFirstDraftDate: (this.msfFirstDraftDate != null) ? this.msfFirstDraftDate : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace UserWebWriter {

    

      export class ChangePassword$Input {
          
    public static fromJsonString(jsonString: string): UserWebWriter.ChangePassword$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return UserWebWriter.ChangePassword$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UserWebWriter.ChangePassword$Input {
        const json: any = o ;
        return new UserWebWriter.ChangePassword$Input(
         (json.userName != null) ? json.userName : '',
(json.oldPassword != null) ? json.oldPassword : '',
(json.newPassword != null) ? json.newPassword : ''
        );
    }


          
    constructor(
        public userName: string = '',
public oldPassword: string = '',
public newPassword: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
oldPassword: (this.oldPassword != null) ? this.oldPassword : undefined,
newPassword: (this.newPassword != null) ? this.newPassword : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace MyClientFileWebWriter {

    

      export class RegisterAndAddClientFileToMyQ$Input {
          
    public static fromJsonString(jsonString: string): MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input {
        const json: any = o ;
        return new MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : "INH",
(json.fppUrc != null) ? json.fppUrc : undefined,
(json.programCode != null) ? SpineData$PROGRAM_CODE.fromJson(json.programCode) : SpineData$PROGRAM_CODE.DEFAULT_VALUE,
(json.mode != null) ? json.mode : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }


          
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string = "INH",
public fppUrc: string|undefined = undefined,
public programCode: SpineData$PROGRAM_CODE = SpineData$PROGRAM_CODE.DEFAULT_VALUE,
public mode: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined,
fppUrc: (this.fppUrc != null) ? this.fppUrc : undefined,
programCode: (this.programCode != null) ?  SpineData$PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined,
mode: (this.mode != null) ? this.mode : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    


      }

     
      export class RegisterAndAddClientFileToMyQ$Output {
           
    public static fromJsonString(jsonString: string): MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output {
        const json: any = o ;
        return new MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output(
         (json.clientFileNumber != null) ? json.clientFileNumber : ''
        );
    }


           
    constructor(
        public clientFileNumber: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace PartnerMasterWebReader {

    

      export class GetStateList$Input {
          
    public static fromJsonString(jsonString: string): PartnerMasterWebReader.GetStateList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerMasterWebReader.GetStateList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerMasterWebReader.GetStateList$Input {
        const json: any = o ;
        return new PartnerMasterWebReader.GetStateList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     
      export class GetStateList$Output {
           
    public static fromJsonString(jsonString: string): PartnerMasterWebReader.GetStateList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerMasterWebReader.GetStateList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerMasterWebReader.GetStateList$Output {
        const json: any = o ;
        return new PartnerMasterWebReader.GetStateList$Output(
         (json.stateNameList != null) ? json.stateNameList : []
        );
    }


           
    constructor(
        public stateNameList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          stateNameList: (this.stateNameList != null) ? this.stateNameList : []
        };
    }
    


      }
      

      


  }

  



  export namespace FiNoteWebWriter {

    

      export class AddNote$Input {
          
    public static fromJsonString(jsonString: string): FiNoteWebWriter.AddNote$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebWriter.AddNote$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebWriter.AddNote$Input {
        const json: any = o ;
        return new FiNoteWebWriter.AddNote$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.noteMessage != null) ? json.noteMessage : '',
(json.noteTagList != null) ? (json.noteTagList as object[]).map((x) => SpineData$FiNoteTag.fromJson(x)) : []
        );
    }


          
    constructor(
        public clientFileId: string = '',
public noteMessage: string = '',
public noteTagList: SpineData$FiNoteTag[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
noteMessage: (this.noteMessage != null) ? this.noteMessage : undefined,
noteTagList: (this.noteTagList != null) ? this.noteTagList.map((x) => x.toJson()) : []
        };
    }
    


      }

     
      export class AddNote$Output {
           
    public static fromJsonString(jsonString: string): FiNoteWebWriter.AddNote$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebWriter.AddNote$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebWriter.AddNote$Output {
        const json: any = o ;
        return new FiNoteWebWriter.AddNote$Output(
         (json.noteId != null) ? json.noteId : ''
        );
    }


           
    constructor(
        public noteId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined
        };
    }
    


      }
      

      


      export class TagNote$Input {
          
    public static fromJsonString(jsonString: string): FiNoteWebWriter.TagNote$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebWriter.TagNote$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebWriter.TagNote$Input {
        const json: any = o ;
        return new FiNoteWebWriter.TagNote$Input(
         (json.noteId != null) ? json.noteId : '',
(json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }


          
    constructor(
        public noteId: string = '',
public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    


      }

     

      


      export class UnTagNote$Input {
          
    public static fromJsonString(jsonString: string): FiNoteWebWriter.UnTagNote$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebWriter.UnTagNote$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebWriter.UnTagNote$Input {
        const json: any = o ;
        return new FiNoteWebWriter.UnTagNote$Input(
         (json.noteId != null) ? json.noteId : '',
(json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }


          
    constructor(
        public noteId: string = '',
public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    


      }

     

      


      export class RemoveNote$Input {
          
    public static fromJsonString(jsonString: string): FiNoteWebWriter.RemoveNote$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteWebWriter.RemoveNote$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteWebWriter.RemoveNote$Input {
        const json: any = o ;
        return new FiNoteWebWriter.RemoveNote$Input(
         (json.noteId != null) ? json.noteId : ''
        );
    }


          
    constructor(
        public noteId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace ClientFileWebReader {

    

      export class GetFiPresignedURLForCreditReportTempUpload$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input(
         (json.fileName != null) ? json.fileName : ''
        );
    }


          
    constructor(
        public fileName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    


      }

     
      export class GetFiPresignedURLForCreditReportTempUpload$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output {
        const json: any = o ;
        return new ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output(
         (json.url != null) ? json.url : '',
(json.docUploadedPath != null) ? json.docUploadedPath : ''
        );
    }


           
    constructor(
        public url: string = '',
public docUploadedPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          url: (this.url != null) ? this.url : undefined,
docUploadedPath: (this.docUploadedPath != null) ? this.docUploadedPath : undefined
        };
    }
    


      }
      

      


      export class GetFiPresignedURLForUpload$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFiPresignedURLForUpload$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFiPresignedURLForUpload$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFiPresignedURLForUpload$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetFiPresignedURLForUpload$Input(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.fileName != null) ? json.fileName : ''
        );
    }


          
    constructor(
        public clientFileNumber: string = '',
public fileName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    


      }

     
      export class GetFiPresignedURLForUpload$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFiPresignedURLForUpload$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFiPresignedURLForUpload$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFiPresignedURLForUpload$Output {
        const json: any = o ;
        return new ClientFileWebReader.GetFiPresignedURLForUpload$Output(
         (json.url != null) ? json.url : '',
(json.docUploadedPath != null) ? json.docUploadedPath : ''
        );
    }


           
    constructor(
        public url: string = '',
public docUploadedPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          url: (this.url != null) ? this.url : undefined,
docUploadedPath: (this.docUploadedPath != null) ? this.docUploadedPath : undefined
        };
    }
    


      }
      

      


      export class GetFileUrl$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFileUrl$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFileUrl$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFileUrl$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetFileUrl$Input(
         (json.key != null) ? json.key : ''
        );
    }


          
    constructor(
        public key: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined
        };
    }
    


      }

     
      export class GetFileUrl$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetFileUrl$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetFileUrl$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetFileUrl$Output {
        const json: any = o ;
        return new ClientFileWebReader.GetFileUrl$Output(
         (json.url != null) ? json.url : ''
        );
    }


           
    constructor(
        public url: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          url: (this.url != null) ? this.url : undefined
        };
    }
    


      }
      

      


      export class GetEMandateList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetEMandateList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetEMandateList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetEMandateList$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetEMandateList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetClientFileBasicInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetClientFileBasicInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetClientFileBasicInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetClientFileBasicInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetClientFileBasicInfo$Input(
         (json.clientFileNumber != null) ? json.clientFileNumber : ''
        );
    }


          
    constructor(
        public clientFileNumber: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined
        };
    }
    


      }

     

      


      export class GetCFBasicInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetCFBasicInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetCFBasicInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetCFBasicInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetCFBasicInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetMyClientFileList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetMyClientFileList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetMyClientFileList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetMyClientFileList$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetMyClientFileList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class FindDocumentList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindDocumentList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindDocumentList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindDocumentList$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindDocumentList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetClientFileSummary$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetClientFileSummary$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetClientFileSummary$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetClientFileSummary$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetClientFileSummary$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetClientFileEnrollmentSummary$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetClientFileEnrollmentSummary$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetClientFileEnrollmentSummary$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetClientFileEnrollmentSummary$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetClientFileEnrollmentSummary$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class FindCreditorInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindCreditorInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindCreditorInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindCreditorInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindCreditorInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class FindBudgetInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindBudgetInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindBudgetInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindBudgetInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindBudgetInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class FindPaymentPlan$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindPaymentPlan$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindPaymentPlan$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindPaymentPlan$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindPaymentPlan$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class FindFiBankInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindFiBankInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindFiBankInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindFiBankInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindFiBankInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetAllSignAgreementList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetAllSignAgreementList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetAllSignAgreementList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetAllSignAgreementList$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetAllSignAgreementList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetEMandateDetails$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetEMandateDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetEMandateDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetEMandateDetails$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetEMandateDetails$Input(
         (json.fiMandateId != null) ? json.fiMandateId : ''
        );
    }


          
    constructor(
        public fiMandateId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiMandateId: (this.fiMandateId != null) ? this.fiMandateId : undefined
        };
    }
    


      }

     

      


      export class GetAllEMandateList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetAllEMandateList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetAllEMandateList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetAllEMandateList$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetAllEMandateList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetExceptionTakenList$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetExceptionTakenList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetExceptionTakenList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetExceptionTakenList$Input {
        const json: any = o ;
        return new ClientFileWebReader.GetExceptionTakenList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     
      export class GetExceptionTakenList$Output {
           
    public static fromJsonString(jsonString: string): ClientFileWebReader.GetExceptionTakenList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.GetExceptionTakenList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.GetExceptionTakenList$Output {
        const json: any = o ;
        return new ClientFileWebReader.GetExceptionTakenList$Output(
         (json.exceptionTakenList != null) ? (json.exceptionTakenList as string[]).map((x) => SpineData$EXCEPTION_TAKEN.fromJson(x)) : [],
(json.exceptionApprovedBy != null) ? json.exceptionApprovedBy : undefined
        );
    }


           
    constructor(
        public exceptionTakenList: SpineData$EXCEPTION_TAKEN[] = [],
public exceptionApprovedBy: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          exceptionTakenList: (this.exceptionTakenList != null) ? this.exceptionTakenList.map((x) => SpineData$EXCEPTION_TAKEN.copyMe(x).toJson()) : [],
exceptionApprovedBy: (this.exceptionApprovedBy != null) ? this.exceptionApprovedBy : undefined
        };
    }
    


      }
      

      


      export class FindClPersonalInfo$Input {
          
    public static fromJsonString(jsonString: string): ClientFileWebReader.FindClPersonalInfo$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileWebReader.FindClPersonalInfo$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileWebReader.FindClPersonalInfo$Input {
        const json: any = o ;
        return new ClientFileWebReader.FindClPersonalInfo$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace PaymentWebReader {

    

      export class GetCashfreePaymentStatus$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebReader.GetCashfreePaymentStatus$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetCashfreePaymentStatus$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetCashfreePaymentStatus$Input {
        const json: any = o ;
        return new PaymentWebReader.GetCashfreePaymentStatus$Input(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     
      export class GetCashfreePaymentStatus$Output {
           
    public static fromJsonString(jsonString: string): PaymentWebReader.GetCashfreePaymentStatus$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetCashfreePaymentStatus$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetCashfreePaymentStatus$Output {
        const json: any = o ;
        return new PaymentWebReader.GetCashfreePaymentStatus$Output(
         (json.status != null) ? json.status : ''
        );
    }


           
    constructor(
        public status: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          status: (this.status != null) ? this.status : undefined
        };
    }
    


      }
      

      


      export class GetFiPaymentList$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebReader.GetFiPaymentList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetFiPaymentList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetFiPaymentList$Input {
        const json: any = o ;
        return new PaymentWebReader.GetFiPaymentList$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class GetQrCodeStatusListForClient$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebReader.GetQrCodeStatusListForClient$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetQrCodeStatusListForClient$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetQrCodeStatusListForClient$Input {
        const json: any = o ;
        return new PaymentWebReader.GetQrCodeStatusListForClient$Input(
         (json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     
      export class GetQrCodeStatusListForClient$Output {
           
    public static fromJsonString(jsonString: string): PaymentWebReader.GetQrCodeStatusListForClient$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetQrCodeStatusListForClient$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetQrCodeStatusListForClient$Output {
        const json: any = o ;
        return new PaymentWebReader.GetQrCodeStatusListForClient$Output(
         (json.qrCodeStatusList != null) ? (json.qrCodeStatusList as object[]).map((x) => SpineData$QrCodeStatus.fromJson(x)) : []
        );
    }


           
    constructor(
        public qrCodeStatusList: SpineData$QrCodeStatus[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          qrCodeStatusList: (this.qrCodeStatusList != null) ? this.qrCodeStatusList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetPaymentTransactionList$Input {
          
    public static fromJsonString(jsonString: string): PaymentWebReader.GetPaymentTransactionList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentWebReader.GetPaymentTransactionList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentWebReader.GetPaymentTransactionList$Input {
        const json: any = o ;
        return new PaymentWebReader.GetPaymentTransactionList$Input(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace TaskWebReader {

    

      export class GetToBePulledTaskList$Input {
          
    public static fromJsonString(jsonString: string): TaskWebReader.GetToBePulledTaskList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebReader.GetToBePulledTaskList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebReader.GetToBePulledTaskList$Input {
        const json: any = o ;
        return new TaskWebReader.GetToBePulledTaskList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetTaskListByCid$Input {
          
    public static fromJsonString(jsonString: string): TaskWebReader.GetTaskListByCid$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebReader.GetTaskListByCid$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebReader.GetTaskListByCid$Input {
        const json: any = o ;
        return new TaskWebReader.GetTaskListByCid$Input(
         (json.cid != null) ? json.cid : ''
        );
    }


          
    constructor(
        public cid: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          cid: (this.cid != null) ? this.cid : undefined
        };
    }
    


      }

     

      


      export class GetActiveTaskListAllocated$Input {
          
    public static fromJsonString(jsonString: string): TaskWebReader.GetActiveTaskListAllocated$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebReader.GetActiveTaskListAllocated$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebReader.GetActiveTaskListAllocated$Input {
        const json: any = o ;
        return new TaskWebReader.GetActiveTaskListAllocated$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetSuspendedTaskListAllocated$Input {
          
    public static fromJsonString(jsonString: string): TaskWebReader.GetSuspendedTaskListAllocated$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebReader.GetSuspendedTaskListAllocated$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebReader.GetSuspendedTaskListAllocated$Input {
        const json: any = o ;
        return new TaskWebReader.GetSuspendedTaskListAllocated$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetExecutiveTaskDetails$Input {
          
    public static fromJsonString(jsonString: string): TaskWebReader.GetExecutiveTaskDetails$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebReader.GetExecutiveTaskDetails$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebReader.GetExecutiveTaskDetails$Input {
        const json: any = o ;
        return new TaskWebReader.GetExecutiveTaskDetails$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace MasterWebReader {

    

      export class GetStateList$Input {
          
    public static fromJsonString(jsonString: string): MasterWebReader.GetStateList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MasterWebReader.GetStateList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MasterWebReader.GetStateList$Input {
        const json: any = o ;
        return new MasterWebReader.GetStateList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     
      export class GetStateList$Output {
           
    public static fromJsonString(jsonString: string): MasterWebReader.GetStateList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MasterWebReader.GetStateList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MasterWebReader.GetStateList$Output {
        const json: any = o ;
        return new MasterWebReader.GetStateList$Output(
         (json.stateNameList != null) ? json.stateNameList : []
        );
    }


           
    constructor(
        public stateNameList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          stateNameList: (this.stateNameList != null) ? this.stateNameList : []
        };
    }
    


      }
      

      


      export class GetNupayBankMasterList$Input {
          
    public static fromJsonString(jsonString: string): MasterWebReader.GetNupayBankMasterList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MasterWebReader.GetNupayBankMasterList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MasterWebReader.GetNupayBankMasterList$Input {
        const json: any = o ;
        return new MasterWebReader.GetNupayBankMasterList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     
      export class GetNupayBankMasterList$Output {
           
    public static fromJsonString(jsonString: string): MasterWebReader.GetNupayBankMasterList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MasterWebReader.GetNupayBankMasterList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MasterWebReader.GetNupayBankMasterList$Output {
        const json: any = o ;
        return new MasterWebReader.GetNupayBankMasterList$Output(
         (json.nupayBankMasterList != null) ? (json.nupayBankMasterList as object[]).map((x) => SpineData$NupayBankMaster.fromJson(x)) : []
        );
    }


           
    constructor(
        public nupayBankMasterList: SpineData$NupayBankMaster[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankMasterList: (this.nupayBankMasterList != null) ? this.nupayBankMasterList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetClientLanguageList$Input {
          
    public static fromJsonString(jsonString: string): MasterWebReader.GetClientLanguageList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MasterWebReader.GetClientLanguageList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MasterWebReader.GetClientLanguageList$Input {
        const json: any = o ;
        return new MasterWebReader.GetClientLanguageList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


  }

  



  export namespace BenchWebReader {

    

      export class GetBenchTaskSummary$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.GetBenchTaskSummary$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.GetBenchTaskSummary$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.GetBenchTaskSummary$Input {
        const json: any = o ;
        return new BenchWebReader.GetBenchTaskSummary$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetAllocatedTaskList$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.GetAllocatedTaskList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.GetAllocatedTaskList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.GetAllocatedTaskList$Input {
        const json: any = o ;
        return new BenchWebReader.GetAllocatedTaskList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetStartedTaskList$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.GetStartedTaskList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.GetStartedTaskList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.GetStartedTaskList$Input {
        const json: any = o ;
        return new BenchWebReader.GetStartedTaskList$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetCompletedTaskList$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.GetCompletedTaskList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.GetCompletedTaskList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.GetCompletedTaskList$Input {
        const json: any = o ;
        return new BenchWebReader.GetCompletedTaskList$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class ExitCheckWithOutput$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.ExitCheckWithOutput$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.ExitCheckWithOutput$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.ExitCheckWithOutput$Input {
        const json: any = o ;
        return new BenchWebReader.ExitCheckWithOutput$Input(
         (json.taskId != null) ? json.taskId : '',
(json.outputData != null) ? json.outputData : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public outputData: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
outputData: (this.outputData != null) ? this.outputData : undefined
        };
    }
    


      }

     
      export class ExitCheckWithOutput$Output {
           
    public static fromJsonString(jsonString: string): BenchWebReader.ExitCheckWithOutput$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.ExitCheckWithOutput$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.ExitCheckWithOutput$Output {
        const json: any = o ;
        return new BenchWebReader.ExitCheckWithOutput$Output(
         (json.key != null) ? json.key : '',
(json.value != null) ? json.value : ''
        );
    }


           
    constructor(
        public key: string = '',
public value: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined,
value: (this.value != null) ? this.value : undefined
        };
    }
    


      }
      

      


      export class GetTaskCommentList$Input {
          
    public static fromJsonString(jsonString: string): BenchWebReader.GetTaskCommentList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchWebReader.GetTaskCommentList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchWebReader.GetTaskCommentList$Input {
        const json: any = o ;
        return new BenchWebReader.GetTaskCommentList$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace MSFScheduleEntryWebWriter {

    

      export class DraftAndPresentFirstMSFThroughCashfree$Input {
          
    public static fromJsonString(jsonString: string): MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input {
        const json: any = o ;
        return new MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.discountCode != null) ? json.discountCode : undefined
        );
    }


          
    constructor(
        public clientFileId: string = '',
public discountCode: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
discountCode: (this.discountCode != null) ? this.discountCode : undefined
        };
    }
    


      }

     
      export class DraftAndPresentFirstMSFThroughCashfree$Output {
           
    public static fromJsonString(jsonString: string): MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output {
        const json: any = o ;
        return new MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output(
         (json.paymentId != null) ? json.paymentId : ''
        );
    }


           
    constructor(
        public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }
      

      


  }

  



  export namespace DspTaskWebWriter {

    

      export class ReceiveFirstMSFPayment$Input {
          
    public static fromJsonString(jsonString: string): DspTaskWebWriter.ReceiveFirstMSFPayment$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DspTaskWebWriter.ReceiveFirstMSFPayment$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspTaskWebWriter.ReceiveFirstMSFPayment$Input {
        const json: any = o ;
        return new DspTaskWebWriter.ReceiveFirstMSFPayment$Input(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public clientFileId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined
        };
    }
    


      }

     

      


      export class UpdateFirstMSFPaymentStatus$Input {
          
    public static fromJsonString(jsonString: string): DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input {
        const json: any = o ;
        return new DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     

      


      export class UpdateMsfPaymentStatus$Input {
          
    public static fromJsonString(jsonString: string): DspTaskWebWriter.UpdateMsfPaymentStatus$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DspTaskWebWriter.UpdateMsfPaymentStatus$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspTaskWebWriter.UpdateMsfPaymentStatus$Input {
        const json: any = o ;
        return new DspTaskWebWriter.UpdateMsfPaymentStatus$Input(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.paymentId != null) ? json.paymentId : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    


      }

     

      


      export class ReceiveMSFPayment$Input {
          
    public static fromJsonString(jsonString: string): DspTaskWebWriter.ReceiveMSFPayment$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DspTaskWebWriter.ReceiveMSFPayment$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DspTaskWebWriter.ReceiveMSFPayment$Input {
        const json: any = o ;
        return new DspTaskWebWriter.ReceiveMSFPayment$Input(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.msfAmount != null) ? json.msfAmount : 0
        );
    }


          
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public msfAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace PartnerAnalyticsWebReader {

    

      export class GetPartnerClientList$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientList$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientList$Input(
         (json.fromDate != null) ? json.fromDate : undefined,
(json.toDate != null) ? json.toDate : undefined,
(json.clientFileStatusList != null) ? (json.clientFileStatusList as string[]).map((x) => SpineData$CLIENT_FILE_STATUS.fromJson(x)) : [],
(json.email != null) ? json.email : undefined,
(json.mobile != null) ? json.mobile : undefined,
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0
        );
    }


          
    constructor(
        public fromDate: string|undefined = undefined,
public toDate: string|undefined = undefined,
public clientFileStatusList: SpineData$CLIENT_FILE_STATUS[] = [],
public email: string|undefined = undefined,
public mobile: string|undefined = undefined,
public offset: number = 0,
public count: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
clientFileStatusList: (this.clientFileStatusList != null) ? this.clientFileStatusList.map((x) => SpineData$CLIENT_FILE_STATUS.copyMe(x).toJson()) : [],
email: (this.email != null) ? this.email : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined
        };
    }
    


      }

     
      export class GetPartnerClientList$Output {
           
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientList$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientList$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientList$Output {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientList$Output(
         (json.totalCount != null) ? json.totalCount : 0,
(json.partnerClientDataList != null) ? (json.partnerClientDataList as object[]).map((x) => InsightData$PartnerClientData.fromJson(x)) : []
        );
    }


           
    constructor(
        public totalCount: number = 0,
public partnerClientDataList: InsightData$PartnerClientData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
partnerClientDataList: (this.partnerClientDataList != null) ? this.partnerClientDataList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetCollectionReport$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetCollectionReport$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetCollectionReport$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetCollectionReport$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetCollectionReport$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     
      export class GetCollectionReport$Output {
           
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetCollectionReport$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetCollectionReport$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetCollectionReport$Output {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetCollectionReport$Output(
         (json.collectionDataList != null) ? (json.collectionDataList as object[]).map((x) => InsightData$CollectionData.fromJson(x)) : []
        );
    }


           
    constructor(
        public collectionDataList: InsightData$CollectionData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          collectionDataList: (this.collectionDataList != null) ? this.collectionDataList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetPartnerClientListForRange$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.filterBy != null) ? InsightData$CF_OM_FILTER.fromJson(json.filterBy) : undefined,
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public filterBy: InsightData$CF_OM_FILTER|undefined = undefined,
public offset: number = 0,
public count: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
filterBy: (this.filterBy != null) ? InsightData$CF_OM_FILTER.copyMe(this.filterBy).toJson() : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined
        };
    }
    


      }

     
      export class GetPartnerClientListForRange$Output {
           
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output(
         (json.totalCount != null) ? json.totalCount : 0,
(json.PartnerClientList != null) ? (json.PartnerClientList as object[]).map((x) => InsightData$PartnerClientData.fromJson(x)) : []
        );
    }


           
    constructor(
        public totalCount: number = 0,
public PartnerClientList: InsightData$PartnerClientData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
PartnerClientList: (this.PartnerClientList != null) ? this.PartnerClientList.map((x) => x.toJson()) : []
        };
    }
    


      }
      

      


      export class GetPartnerClientCount$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientCount$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientCount$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientCount$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientCount$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     
      export class GetPartnerClientCount$Output {
           
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientCount$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientCount$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientCount$Output {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientCount$Output(
         (json.totalLeadCount != null) ? json.totalLeadCount : 0,
(json.footfallCount != null) ? json.footfallCount : 0,
(json.onBoardedCount != null) ? json.onBoardedCount : 0,
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }


           
    constructor(
        public totalLeadCount: number = 0,
public footfallCount: number = 0,
public onBoardedCount: number = 0,
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalLeadCount: (this.totalLeadCount != null) ? this.totalLeadCount : undefined,
footfallCount: (this.footfallCount != null) ? this.footfallCount : undefined,
onBoardedCount: (this.onBoardedCount != null) ? this.onBoardedCount : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    


      }
      

      


      export class FindParticularPartnerClient$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.FindParticularPartnerClient$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.FindParticularPartnerClient$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.FindParticularPartnerClient$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.FindParticularPartnerClient$Input(
         (json.mobile != null) ? json.mobile : undefined,
(json.clientFileNumber != null) ? json.clientFileNumber : undefined
        );
    }


          
    constructor(
        public mobile: string|undefined = undefined,
public clientFileNumber: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined
        };
    }
    


      }

     

      


      export class GetPartnerClientFunnelForFpp$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class GetPartnerClientDemography$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientDemography$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientDemography$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientDemography$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientDemography$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


      export class GetPartnerClientFunnel$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class GetDailyFunnel$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetDailyFunnel$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetDailyFunnel$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetDailyFunnel$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetDailyFunnel$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class GetMonthlyFunnel$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetMonthlyFunnel$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetMonthlyFunnel$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetMonthlyFunnel$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetMonthlyFunnel$Input(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }


          
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    


      }

     

      


      export class GetPartnerConversionSummary$Input {
          
    public static fromJsonString(jsonString: string): PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input {
        const json: any = o ;
        return new PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input(
         
        );
    }


          
    constructor(
        
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          
        };
    }
    


      }

     

      


  }

  



  export namespace TaskWebWriter {

    

      export class Assign$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Assign$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Assign$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Assign$Input {
        const json: any = o ;
        return new TaskWebWriter.Assign$Input(
         (json.taskId != null) ? json.taskId : '',
(json.toUserName != null) ? json.toUserName : '',
(json.whoDidItUserName != null) ? json.whoDidItUserName : undefined
        );
    }


          
    constructor(
        public taskId: string = '',
public toUserName: string = '',
public whoDidItUserName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
toUserName: (this.toUserName != null) ? this.toUserName : undefined,
whoDidItUserName: (this.whoDidItUserName != null) ? this.whoDidItUserName : undefined
        };
    }
    


      }

     

      


      export class Reassign$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Reassign$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Reassign$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Reassign$Input {
        const json: any = o ;
        return new TaskWebWriter.Reassign$Input(
         (json.taskId != null) ? json.taskId : '',
(json.toUserName != null) ? json.toUserName : '',
(json.whoDidItUserName != null) ? json.whoDidItUserName : undefined
        );
    }


          
    constructor(
        public taskId: string = '',
public toUserName: string = '',
public whoDidItUserName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
toUserName: (this.toUserName != null) ? this.toUserName : undefined,
whoDidItUserName: (this.whoDidItUserName != null) ? this.whoDidItUserName : undefined
        };
    }
    


      }

     

      


      export class PullTask$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.PullTask$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.PullTask$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.PullTask$Input {
        const json: any = o ;
        return new TaskWebWriter.PullTask$Input(
         (json.taskId != null) ? json.taskId : '',
(json.userName != null) ? json.userName : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public userName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
userName: (this.userName != null) ? this.userName : undefined
        };
    }
    


      }

     

      


      export class Start$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Start$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Start$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Start$Input {
        const json: any = o ;
        return new TaskWebWriter.Start$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class Suspend$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Suspend$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Suspend$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Suspend$Input {
        const json: any = o ;
        return new TaskWebWriter.Suspend$Input(
         (json.taskId != null) ? json.taskId : '',
(json.resumeOn != null) ? new Date(json.resumeOn) : undefined,
(json.reason != null) ? json.reason : undefined,
(json.disposition != null) ? SpineData$Disposition.fromJson(json.disposition) : undefined
        );
    }


          
    constructor(
        public taskId: string = '',
public resumeOn: Date|undefined = undefined,
public reason: string|undefined = undefined,
public disposition: SpineData$Disposition|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
resumeOn: (this.resumeOn != null) ? this.resumeOn.toISOString() : undefined,
reason: (this.reason != null) ? this.reason : undefined,
disposition: (this.disposition != null) ? this.disposition.toJson() : undefined
        };
    }
    


      }

     

      


      export class Resume$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Resume$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Resume$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Resume$Input {
        const json: any = o ;
        return new TaskWebWriter.Resume$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class PullStartAndMerge$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.PullStartAndMerge$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.PullStartAndMerge$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.PullStartAndMerge$Input {
        const json: any = o ;
        return new TaskWebWriter.PullStartAndMerge$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class SaveAndComplete$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.SaveAndComplete$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.SaveAndComplete$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.SaveAndComplete$Input {
        const json: any = o ;
        return new TaskWebWriter.SaveAndComplete$Input(
         (json.taskId != null) ? json.taskId : '',
(json.outputJson != null) ? json.outputJson : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public outputJson: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
outputJson: (this.outputJson != null) ? this.outputJson : undefined
        };
    }
    


      }

     

      


      export class Complete$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Complete$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Complete$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Complete$Input {
        const json: any = o ;
        return new TaskWebWriter.Complete$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class Save$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Save$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Save$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Save$Input {
        const json: any = o ;
        return new TaskWebWriter.Save$Input(
         (json.taskId != null) ? json.taskId : '',
(json.dataJson != null) ? json.dataJson : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public dataJson: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
dataJson: (this.dataJson != null) ? this.dataJson : undefined
        };
    }
    


      }

     

      


      export class Rescue$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Rescue$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Rescue$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Rescue$Input {
        const json: any = o ;
        return new TaskWebWriter.Rescue$Input(
         (json.taskId != null) ? json.taskId : '',
(json.outputJson != null) ? json.outputJson : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public outputJson: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
outputJson: (this.outputJson != null) ? this.outputJson : undefined
        };
    }
    


      }

     

      


      export class Retry$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Retry$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Retry$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Retry$Input {
        const json: any = o ;
        return new TaskWebWriter.Retry$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class CancelFlow$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.CancelFlow$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.CancelFlow$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.CancelFlow$Input {
        const json: any = o ;
        return new TaskWebWriter.CancelFlow$Input(
         (json.taskId != null) ? json.taskId : '',
(json.reason != null) ? json.reason : undefined
        );
    }


          
    constructor(
        public taskId: string = '',
public reason: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
reason: (this.reason != null) ? this.reason : undefined
        };
    }
    


      }

     

      


      export class CancelTask$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.CancelTask$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.CancelTask$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.CancelTask$Input {
        const json: any = o ;
        return new TaskWebWriter.CancelTask$Input(
         (json.taskId != null) ? json.taskId : '',
(json.reason != null) ? json.reason : undefined
        );
    }


          
    constructor(
        public taskId: string = '',
public reason: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
reason: (this.reason != null) ? this.reason : undefined
        };
    }
    


      }

     

      


      export class Proceed$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.Proceed$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.Proceed$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.Proceed$Input {
        const json: any = o ;
        return new TaskWebWriter.Proceed$Input(
         (json.taskId != null) ? json.taskId : ''
        );
    }


          
    constructor(
        public taskId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined
        };
    }
    


      }

     

      


      export class AddComment$Input {
          
    public static fromJsonString(jsonString: string): TaskWebWriter.AddComment$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskWebWriter.AddComment$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskWebWriter.AddComment$Input {
        const json: any = o ;
        return new TaskWebWriter.AddComment$Input(
         (json.taskId != null) ? json.taskId : '',
(json.comment != null) ? json.comment : ''
        );
    }


          
    constructor(
        public taskId: string = '',
public comment: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
comment: (this.comment != null) ? this.comment : undefined
        };
    }
    


      }

     

      


  }

  



  export namespace DocumentUploadWebReader {

    

      export class GetPresignedURLForUpload$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadWebReader.GetPresignedURLForUpload$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebReader.GetPresignedURLForUpload$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebReader.GetPresignedURLForUpload$Input {
        const json: any = o ;
        return new DocumentUploadWebReader.GetPresignedURLForUpload$Input(
         (json.documentRefType != null) ? SpineData$DOCUMENT_REF_TYPE.fromJson(json.documentRefType) : SpineData$DOCUMENT_REF_TYPE.DEFAULT_VALUE,
(json.documentRefId != null) ? json.documentRefId : '',
(json.fileName != null) ? json.fileName : ''
        );
    }


          
    constructor(
        public documentRefType: SpineData$DOCUMENT_REF_TYPE = SpineData$DOCUMENT_REF_TYPE.DEFAULT_VALUE,
public documentRefId: string = '',
public fileName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentRefType: (this.documentRefType != null) ?  SpineData$DOCUMENT_REF_TYPE.copyMe(this.documentRefType).toJson() : undefined,
documentRefId: (this.documentRefId != null) ? this.documentRefId : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    


      }

     
      export class GetPresignedURLForUpload$Output {
           
    public static fromJsonString(jsonString: string): DocumentUploadWebReader.GetPresignedURLForUpload$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebReader.GetPresignedURLForUpload$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebReader.GetPresignedURLForUpload$Output {
        const json: any = o ;
        return new DocumentUploadWebReader.GetPresignedURLForUpload$Output(
         (json.url != null) ? json.url : '',
(json.docUploadedPath != null) ? json.docUploadedPath : ''
        );
    }


           
    constructor(
        public url: string = '',
public docUploadedPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          url: (this.url != null) ? this.url : undefined,
docUploadedPath: (this.docUploadedPath != null) ? this.docUploadedPath : undefined
        };
    }
    


      }
      

      


      export class GetUploadedUrl$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadWebReader.GetUploadedUrl$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebReader.GetUploadedUrl$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebReader.GetUploadedUrl$Input {
        const json: any = o ;
        return new DocumentUploadWebReader.GetUploadedUrl$Input(
         (json.key != null) ? json.key : ''
        );
    }


          
    constructor(
        public key: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined
        };
    }
    


      }

     
      export class GetUploadedUrl$Output {
           
    public static fromJsonString(jsonString: string): DocumentUploadWebReader.GetUploadedUrl$Output {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebReader.GetUploadedUrl$Output.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebReader.GetUploadedUrl$Output {
        const json: any = o ;
        return new DocumentUploadWebReader.GetUploadedUrl$Output(
         (json.uploadedUrl != null) ? json.uploadedUrl : ''
        );
    }


           
    constructor(
        public uploadedUrl: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          uploadedUrl: (this.uploadedUrl != null) ? this.uploadedUrl : undefined
        };
    }
    


      }
      

      


      export class GetDocumentList$Input {
          
    public static fromJsonString(jsonString: string): DocumentUploadWebReader.GetDocumentList$Input {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentUploadWebReader.GetDocumentList$Input.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentUploadWebReader.GetDocumentList$Input {
        const json: any = o ;
        return new DocumentUploadWebReader.GetDocumentList$Input(
         (json.documentRefId != null) ? json.documentRefId : ''
        );
    }


          
    constructor(
        public documentRefId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentRefId: (this.documentRefId != null) ? this.documentRefId : undefined
        };
    }
    


      }

     

      


  }

  


